import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import $ from 'jquery'

class WeeklyHistPredData extends Component {

    componentDidMount() {
        this.weeklyHistPredData(this.props.weeklyHistPredData)
    }

    weeklyHistPredData = data => {
        // Get the data
        const render = data => {
            console.log(data)
            // if (error) throw error;
            
            // parse the date / time
            var parseTime = d3.timeParse("%d-%m-%Y");
            var array1 = data[0]["history"];
            var array2 = data[0]["prediction"];
            var data1 = array1.concat(array2)
            console.log(array1)
            
            
            // format the data
            array1.forEach(function(d) {
            d.date = parseTime(d.ds);
            d.open = 0;
            d.close = 0;
            d.actual = 0;
            d.y = +d.y;
            });
            array2.forEach(function(d) {
            d.date = parseTime(d.ds);
            d.open = +d.yhat_lower;
            d.close = +d.yhat_upper;
            d.actual = +d.yhat;
            d.y = 0;
            });
            console.log(data)
            
            var cwidth = $(".cashflowForecast").width();   
            var cheight = $(".cashflowForecast").height();
            // set the dimensions and margins of the graph
            var margin = {top: 50, right: 50, bottom: 60, left: 80},
            width = cwidth - margin.left - margin.right,
            height = cheight - margin.top - margin.bottom;
            
            
            
            // set the ranges
            var x = d3.scaleTime().range([0, width]);
            var y = d3.scaleLinear().range([height, 0]);
            
            var area_data = d3.area()
                    .x(function(d){ return x(d.date)})
                    .y0(function(d){ return y(d.open)})
                    .y1(function(d){ return y(d.close)})
            
            var valueline3 = d3.line()
            .x(function(d) { return x(d.date); })
            .y(function(d) { return y(d.actual); });
            
            var valueline4 = d3.line()
            .x(function(d) { return x(d.date); })
            .y(function(d) { return y(d.y); });
            
            var svg1 = d3.select("#weeklyHistPredData").append("svg")
            .attr("width",cwidth)
            .attr("height",cheight)
            
            // svg1.append("rect")
            //     .attr("width",  width + margin.left + margin.right)
            //     .attr("height", height + margin.top + margin.bottom)
            //     .attr("fill", "none")
            //     .attr("stroke", "black")
        
            var svg = svg1.append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");            
            
            // Scale the range of the data
            x.domain(d3.extent(data1, function(d) { return d.date; }));
            y.domain([d3.min(data1, function(d) {
            return Math.min(d.close, d.open, d.actual, d.y); }), d3.max(data1, function(d) {
            return Math.max(d.close, d.open, d.actual, d.y); })]);
            
            
            // Add the X Axis
            var xaxis = svg.append("g")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x).tickFormat(d3.timeFormat("%b-%y")))
            ;
            
            xaxis.selectAll(".domain, .tick line").remove()
            
            // Add the Y Axis
            var yaxis = svg.append("g")
            .attr("class", "yaxis")
            .call(d3.axisLeft(y).tickSize(-width));
            
            
            yaxis.selectAll(".domain").remove()
            
        
            svg.append("path")
            .data([array2])
            .attr("class", "area")
            .attr("d", area_data)
            
            
            svg.append("path")
            .data([array2])
            .attr("class", "line")
            .style("stroke", "#FFA07A")
            .attr("d", valueline3);
            
            
            svg.selectAll("circle")
                .data(array2)
                .enter()
                .append("circle")
                .attr("r", "2.5px")
                .attr("cx", function(d) { return x(d.date)})
                .attr("cy", function(d) {return y(d.actual)})
                .attr("stroke", "#FFA07A")
                .attr("fill", "white")
            
            var his = svg.append("path")
            .data([array1])
            .attr("class", "line")
            //.style("stroke", "red")
            .attr("d", valueline4);
            
            svg.selectAll(".circle")
            .data(array1)
            .enter()
            .append("circle")
            .attr("r", 2.5)
            .attr("cx", function(d){return x(d.date)})
            .attr("cy", function(d){return y(d.y)})
            .attr("stroke", "steelblue")
            .attr("fill", "white");
    
        }
        render(data)
    }
    
    render() {
        return (
            <div className="weeklyHistPredData" id="weeklyHistPredData"></div>
        );
    }
}

WeeklyHistPredData.propTypes = {
    weeklyHistPredData: PropTypes.array.isRequired
};

export default WeeklyHistPredData;