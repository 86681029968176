import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cashflowTimeSeriesData } from '../../../../redux/actions/cashFlowTimeSeriesActions';
import isEmpty from '../../../../validation/is-empty';
import RadialChart from './RadialChart';
import RequestLoadingData from '../../../Loading/RequestLoadingData';
import PieChart from './PieChart';
import BarChart from './BarChart';
import * as d3 from 'd3';
import KPICashflowTimeSeries from './KPICashflowTimeSeries';

class CashflowTimeSeries extends Component {

    state = {
        timeSeries: null,
        gotResult: false,
        cumulativeData: null,
        years: null,
        selectYear: null,
        initialData: null,
        type: 'weekly',
        barData: null,
        savedQuarter: null,
        hideBarChart: false,
        selectingQuarter: ''
    }

    componentDidMount() {
        this.props.cashflowTimeSeriesData()
    }

    componentWillReceiveProps(nextProps) {
        const { cashflowTimeSeriesDetails } = nextProps.cashFlowTimeSeries;
        if(cashflowTimeSeriesDetails !== null){
            const timeSeries = cashflowTimeSeriesDetails;
            console.log("time",timeSeries)
            console.log(timeSeries)
            const years = Object.keys(timeSeries)
            let initialData = {}
            initialData[years[0]] = cashflowTimeSeriesDetails[years[0]]
            console.log(initialData)
            const selectedYear = years[0]
            // console.log(timeSeries)
            // this.setState( (prevState) => ({
            //     timeSeries: timeSeries,
            //     cumulativeData: timeSeries,
            //     initialData,
            //     years,
            //     selectedYear,
            //     gotResult: true,
            // }))
            this.setState({
                timeSeries: timeSeries,
                cumulativeData: timeSeries,
                initialData,
                years,
                selectedYear,
                gotResult: true,
            }, () => {
                this.generateBarChart({
                    data: {
                        quarter: 1
                    }
                })
            })
        }
    }

    onChangeDropdown = (e) => {
        const { value } = e.target
        const { timeSeries } = this.state

        const initialData = {}
        initialData[value] = timeSeries[value]
        const selectedYear = value
        this.setState({
            initialData,
            selectedYear,
            hideBarChart: true
        }, () => {
            this.generateBarChart({
                data: {
                    quarter: 1
                }
            })
        })
    }

    generateBarChart = (d) => {
        const { selectedYear, type, initialData } = this.state
        let { savedQuarter } = this.state
        let { quarter } = d.data
        let data = initialData[selectedYear]['weekly']
        const quarterly = initialData[selectedYear]["quarterly"]
        const offset =13
        if(savedQuarter && savedQuarter === quarter) quarter = savedQuarter
        else {
            savedQuarter = quarter
        }

        const barData = data.slice((offset * (quarter - 1)), offset * quarter)
                            .filter( item => item)

        console.log("klesh",barData)

        this.setState({
            type: 'weekly',
            barData,
            savedQuarter,
            hideBarChart: false,
            selectingQuarter: quarter
        })
    }

    onRadioButtonChange = (e) => {
        const { value } = e.target
        const { selectedYear, type, initialData, savedQuarter:quarter } = this.state
        let offset = 13
        if(value === 'monthly'){
            offset = 3
        }
        console.log("Initial::::",initialData)
        let data = initialData[selectedYear][value]
        console.log("check", selectedYear, value)
        if(value === 'monthly') {
            data = data.map( item => {
                // var parse = d3.timeParse("%b")
                // item.month = parse(item.month)
                if(typeof item.month !== 'object'){
                    const format = d3.timeParse("%b-%Y")
                    item.month = format(item.month);
                }    
                console.log(item)
                return item
            })
            data = data.sort( (a, b) => a.month - b.month)
            data = data.map( d => {
                // console.log(va)
                d.month = d3.timeFormat("%b-%Y")(d.month)
                console.log(d)
                return d
            })
        }
        
        let barData = null
        barData = data.slice((offset * (quarter - 1)), offset * quarter)
                    .filter(item => item)
        
        this.setState({
            type: value,
            barData
        })
    }

    render() {
        const { loading, cashflowTimeSeriesDetails } = this.props.cashFlowTimeSeries;
        
        let cashflowTimeSeries;
        if(cashflowTimeSeriesDetails !== null && this.state.gotResult) {
            if(isEmpty(cashflowTimeSeriesDetails) === true) {
                cashflowTimeSeries = (
                    <div>
                        <p className="lead text-muted">Sorry</p>
                        <p>Something went wrong please try again after some time</p>
                    </div>
                )
            } else {
                const { timeSeries, years } = this.state

                const getSelectBox = () => {
                    return years.map((item, idx) => (
                        <option key={idx}> {item} </option>
                    ))
                }
                cashflowTimeSeries = (
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                                <div className="card timeSeries-card">
                                    {
                                        this.state.timeSeries && 
                                        <RadialChart radialChart={this.state.timeSeries} />
                                    }
                                </div>
                            </div>
                            <KPICashflowTimeSeries timeSeries={this.state.timeSeries} />
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card timeSeries-card It-chart">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="timeseries-select">Select the Year 
                                            {
                                                timeSeries &&
                                                <select onChange={(e) => this.onChangeDropdown(e)}>
                                                    {getSelectBox()}
                                                </select>
                                            }
                                            </div>
                                            <div className="text-center">
                                                <div className="timeseries-title">Quarterly Sales ({this.state.selectedYear})</div>
                                                {
                                                    this.state.initialData &&
                                                    <PieChart 
                                                        pieChart={this.state.initialData}
                                                        year={this.state.selectedYear}
                                                        generateBarChart={this.generateBarChart}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            {
                                                this.state.barData && !this.state.hideBarChart &&
                                                    <div>
                                                        <input 
                                                            type="radio" 
                                                            name="month_week_wise" 
                                                            value="monthly" 
                                                            checked={this.state.type === 'monthly' }
                                                            onChange= { (e) => this.onRadioButtonChange(e)}/> Monthly 
                                                        <input 
                                                            type="radio" 
                                                            name="month_week_wise" 
                                                            value="weekly"
                                                            checked={this.state.type === 'weekly' } 
                                                            onChange= { (e) => this.onRadioButtonChange(e)}/> Weekly
                                                    </div>
                                            }
                                            <div className="text-center">
                                                {
                                                    this.state.type === 'weekly' ?
                                                        <div className="timeseries-title">Weekwise Sales Q{this.state.selectingQuarter} ({this.state.selectedYear})</div>
                                                    : this.state.type === 'monthly' ?
                                                        <div className="timeseries-title">Monthly Sales Q{this.state.selectingQuarter} ({this.state.selectedYear})</div>
                                                    :
                                                        null
                                                }
                                                {
                                                    this.state.barData &&
                                                    <BarChart 
                                                        barChartData={this.state.barData} 
                                                        filter={this.state.type}
                                                        hide={this.state.hideBarChart}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        } else {
            cashflowTimeSeries = <RequestLoadingData />
        }
        return (
            <div>
                { cashflowTimeSeries }
            </div>
        );
    }
}

CashflowTimeSeries.propTypes = {
    cashflowTimeSeriesData: PropTypes.func.isRequired,
    cashFlowTimeSeries: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    cashFlowTimeSeries: state.cashFlowTimeSeries
})

export default connect(mapStateToProps, { cashflowTimeSeriesData })(CashflowTimeSeries);