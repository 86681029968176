import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './redux/actions/authActions'

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';

import RequestLoadingData from './components/Loading/RequestLoadingData';

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";

import 'antd/dist/antd.css';
import 'react-circular-progressbar/dist/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './index.scss';
import App from './App';

// check for token
if(localStorage.AnalyticalAppToken) {
    const token = JSON.parse(localStorage.getItem('AnalyticalAppToken'));
    // Set auth token header auth
    setAuthToken(token.auth_token);
    // Decode token and get user info and exp
    const decoded = jwt_decode(token.auth_token);
    // Set user and is Authenticated
    store.dispatch(setCurrentUser(token.auth_token));

    // check for expired token
    const currentTime = Date.now() / 1000;
    if(decoded.exp < currentTime) {
        // Logout User
        store.dispatch(logoutUser());
        // TODO: Clear current Profile

        // Redirect to login
        window.location.href = '/'
    }
}

ReactDOM.render(
<Provider store={ store }>
    <Router>
        <PersistGate loading={<RequestLoadingData />} persistor={persistor} >
            <App />
        </PersistGate>
    </Router>
</Provider>
, document.getElementById('root'));
