import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as d3 from 'd3';
import { top10ItemsBasedOnSales } from '../../../redux/actions/insights360Actions';

class DonutChart extends Component {

    componentDidMount() {
        this.donutChart(this.props.donutChart)
    }

    donutChart = (data) => {
        var Width = 600;
        var Height = 380; 

        const render = data => {

            data.forEach(function(d){
                d.letter = d.Item_name;
                d.frequency = d.totalsales; 
            });

            var svg = d3.select("#donutChart")
                .append("svg")
                .attr("width", Width)
                .attr("height", Height)

            var margin = {
                top: 10,
                right: 30,
                bottom: 20,
                left: 30
            };
        
            var width = svg.attr("width") - margin.left - margin.right;
            var height = svg.attr("height") - margin.top - margin.bottom;
            var radius = d3.min([width/2, height/2]);
        
            var color = d3.scaleOrdinal(d3.schemeCategory20)

            var g = svg.append("g").attr("transform", "translate(" +( Width / 2-120)+ "," + (Height / 2) + ")");
        
            var pie = d3.pie().sort(null)
            .value(function(d) {
                return d.frequency;
            });
        
            var piedata = pie(data);
        
            var arc = d3.arc()
            .innerRadius(radius - 10)
            .outerRadius(radius - 60)
            ;
                    
            var farc = d3.arc()
            .innerRadius(radius)
            .outerRadius(radius - 60)
        
            var path=g.selectAll(".arc")
                        .data(piedata)
                        .enter()
                        .append("g")
                        .attr("class","arc")
                        .on('mouseover',mouseover)
                        .on('mouseout',mouseout)
                        
                        path.append("path")
      
            .attr('fill', function(d){return color(d.data.totalsales)})
            .attr("stroke", "white")
            .attr("stroke-width","3px")
            .attr("d", arc)
            ;

            function mouseover() {
                d3.select(this).select("path").transition()
                    .duration(500)
                    .attr("d", farc)
                    path.append("svg:title")
                        .text(function (d) {
                            var t = "Total Sales";
                            return t +" :"+ d.data.totalsales 
              });
                                
                }

            function mouseout() {
            d3.select(this).select("path").transition()
                .duration(500)
                .attr("d", arc);
            }

            //legends
            var legend = svg.selectAll(".legend")
            .data(data).enter()
            .append("g")
            .attr("class","legend")
            .attr("transform", "translate(" + (width/2+0.2*width) + "," + (height/2-0.3*height) + ")");
               
           
             legend.append("rect")
               .attr("x", 0) 
               .attr("y", function(d, i) { return 20 * i+10; })
               .attr("width", 15)
               .attr("height", 15)
                  .style("fill", function(d) { return color(d.Item_name)});
             
          
              legend.append("text")
                .attr("x", 25) 
                .attr("text-anchor", "start")
                .attr("dy", "1em") 
                .attr("class","ltext")
                .attr("y", function(d, i) { return 20 * i+10; })
                .text(function(d) {return d.Item_name;})
               .attr("font-size", "12px"); 
            
                
              legend.append("text")
               .attr("x",31) 
               .attr("dy", "-.2em")
               .attr("y",-10)
              .style("font-family","monospace")
               .text(" Item Names")
                .style("font-size", "15px")
                .style("fill","purple"); 
            
        }
        render(data)
    }

    componentWillUnmount(){
        d3.select("svg").remove();
    }

    render() {
        return (
            <div id="donutChart"></div>
        );
    }
}

DonutChart.propTypes = {
    top10ItemsBasedOnSales: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    insights360: PropTypes.object.isRequired,
    donutChart:PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    insights360: state.insights360
})

export default connect(mapStateToProps, { top10ItemsBasedOnSales })(DonutChart);