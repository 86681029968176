import React, { Component } from 'react';
import Kpi360 from './Kpi360';
import InsightsGraphs from './InsightsGraphs';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class Insights360 extends Component {
    render() {
        return (
            <div>
                <Kpi360 />
                <InsightsGraphs />
            </div>
        );
    }
}

Insights360.propTypes = {
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(Insights360);