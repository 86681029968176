import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class Register extends Component {
  
  constructor(){
    super();
    this.state = {
      register: {
        value: '',
        valid: false
      }
    }
  }

  changeHandler = e => {
    this.setState({ 
        [e.target.name]: { 
            value: e.target.value, 
            valid: !!e.target.value 
        } 
    });
  }

  onSubmit = e => {
    e.preventDefault();
    this.props.history.push(`/register?email=${this.state.register.value}`)
  }

  render() {
    return (
      <div className="try-free text-center">
        <form className="try-for-free" onSubmit={this.onSubmit} autoComplete="off">
            <input 
              type="text" 
              className="form-control register_inputTag" 
              name="register" 
              placeholder="Name@company.com"
              onChange={this.changeHandler}
            />
            <button className="btn aap-btn aap-warning-btn" type="submit">Try for free</button>
        </form>
      </div>
    );
  }
}

export default withRouter(Register);