import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { MDBInput } from 'mdbreact';

const TextFieldGroup = ({
    name,
    icon,
    value,
    label,
    success,
    error,
    type,
    onKeyUp,
    onChange,
    disabled
}) => {
    return (
        <MDBInput
            label={label}
            className={classnames('', {
                'is-invalid': error
            },
            {
                'is-valid': success
            })}
            icon={icon}
            type={type}
            name={name}
            onChange={onChange}
            onKeyUp={onKeyUp}
            value={value}
            disabled={disabled}
        >
            {error && (
                <div className="invalid-feedback">{error}</div>
            )}
            {success && (
                <div className="valid-feedback">{success}</div>
            )}
        </MDBInput>
    );
};

TextFieldGroup.propTypes = {
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    error: PropTypes.string,
    success: PropTypes.string,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onKeyUp: PropTypes.func,
    disabled: PropTypes.string
}

TextFieldGroup.defaultProps = {
    type: 'text'
}

export default TextFieldGroup;