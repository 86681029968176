import axios from 'axios';
import { ANALYTICS_URL, CLIENT_ID, ACCESS_TOKEN, GET_ERRORS, ITEM_ANALYSIS_LOADING, GET_TOP_20ITEMS_BASED_ON_SALES, 
        GET_ITEMWISE_KPI, GET_MONTHLY_SALES_OF_ITEM, GET_MONTHLY_PROFIT_OF_ITEM, CLEAR_CURRENT_ITEM_ANALYSIS } from './types';
import { logoutUser } from '../actions/authActions';

/*--- Top 20Items Based On Sales ---*/
export const getTop20ItemsBasedOnSales = () => dispatch => {
    dispatch(getItemAnalysisDataLoading());
    axios.get(`${ANALYTICS_URL}/retail/Top20ItemsBasedOnSales/${CLIENT_ID()}`, ACCESS_TOKEN())
        .then(res => {
            dispatch({
                type: GET_TOP_20ITEMS_BASED_ON_SALES,
                payload : res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
            if(err.response && err.response.status === 401){
                dispatch(logoutUser());
            }
        })
}

/*--- Itemwise Kpi ---*/
export const getItemwiseKpi = (itemData) => dispatch => {
    dispatch(getItemAnalysisDataLoading());
    axios.post(`${ANALYTICS_URL}/retail/getItemwiseKpi/${CLIENT_ID()}`, itemData, ACCESS_TOKEN())
        .then(res => {
            dispatch({
                type: GET_ITEMWISE_KPI,
                payload : res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
            if(err.response && err.response.status === 401){
                dispatch(logoutUser());
            }
        })
}

/*--- Montly Sales Of Item ---*/
export const getMontlySalesOfItem = (itemData) => dispatch => {
    dispatch(getItemAnalysisDataLoading());
    axios.post(`${ANALYTICS_URL}/retail/getMontlySalesOfItem/${CLIENT_ID()}`, itemData, ACCESS_TOKEN())
        .then(res => {
            dispatch({
                type: GET_MONTHLY_SALES_OF_ITEM,
                payload : res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
            if(err.response && err.response.status === 401){
                dispatch(logoutUser());
            }
        })
}

/*--- Montly Profit Of Item ---*/
export const getMontlyProfitOfItem = (itemData) => dispatch => {
    dispatch(getItemAnalysisDataLoading());
    axios.post(`${ANALYTICS_URL}/retail/getMontlyProfitOfItem/${CLIENT_ID()}`, itemData, ACCESS_TOKEN())
        .then(res => {
            dispatch({
                type: GET_MONTHLY_PROFIT_OF_ITEM,
                payload : res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
            if(err.response && err.response.status === 401){
                dispatch(logoutUser());
            }
        })
}

/*--- Item Analysis Data Loading ---*/
export const getItemAnalysisDataLoading = () => {
    return {
        type: ITEM_ANALYSIS_LOADING
    }
}

/*--- Clear Item Analysis ---*/
export const clearCurrentItemAnalysis = () => {
    return {
        type: CLEAR_CURRENT_ITEM_ANALYSIS
    }
}