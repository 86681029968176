import { DOMAIN_MODULES_LOADING, DOMAIN_LIST } from '../actions/types';

const initialState = {
    loading: false,
    domainList : null
}

export default function(state = initialState, action){
    switch(action.type) {
        case DOMAIN_MODULES_LOADING:
            return {
                ...state,
                loading: true
            }
        case DOMAIN_LIST:
            return {
                ...state,
                domainList : action.payload,
                loading: false
            }
        default:
            return state;
    }
}