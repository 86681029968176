import isEmpty from '../../../validation/is-empty';
import Validator from 'validator';

export default function validateRegisterInput(data, checked) {
    let errors = {};

    data.fname.value = !isEmpty(data.fname.value) ? data.fname.value : '';
    data.lname.value = !isEmpty(data.lname.value) ? data.lname.value : '';
    data.email.value = !isEmpty(data.email.value) ? data.email.value : '';
    data.password.value = !isEmpty(data.password.value) ? data.password.value : '';
    data.confirmPassword = !isEmpty(data.confirmPassword) ? data.confirmPassword : '';

    if(Validator.isEmpty(data.fname.value)) {
        errors.fname = 'Firstname field is required';
    }

    if(Validator.isEmpty(data.lname.value)) {
        errors.lname = 'Lastname field is required';
    }

    if(!Validator.isEmail(data.email.value)) {
        errors.email = 'Email is Invalid';
    }

    if(Validator.isEmpty(data.email.value)) {
        errors.email = 'Email field is required';
    }

    if(Validator.isEmpty(data.password.value)) {
        errors.password = 'Password field is required';
    }

    if(Validator.isEmpty(data.confirmPassword.value)) {
        errors.confirmPassword = 'Confirm Password eror';
    }

    if(!Validator.equals(data.password.value, data.confirmPassword.value)) {
        errors.password2 = 'Password must match';
    }

    if(!checked) {
        errors.checked = 'Select the Checkbox';
    }

    return {
        errors,
        isValid: isEmpty(errors)
    }
}
