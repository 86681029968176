import axios from 'axios';
import { ANALYTICS_URL, CLIENT_ID, ACCESS_TOKEN, GET_ERRORS, MARKET_BASKET_LOADING, GET_MARKET_BASKET_DATA, 
        POST_MARKET_BASKET_DATA, CLEAR_CURRENT_MARKET_BASKET} from './types';

/*--- Get Market Basket ---*/
export const getMarketBasket = () => dispatch => {
    dispatch(getTimeSeriesPredictionLoading());
    axios.get(`${ANALYTICS_URL}/retail/MarketBasket/${CLIENT_ID()}`, ACCESS_TOKEN())
        .then(res => {
            dispatch({
                type: GET_MARKET_BASKET_DATA,
                payload : res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        })
}

/*--- Post Market Basket ---*/
export const postMarketBasket = (itemValue) => dispatch => {
    dispatch(getTimeSeriesPredictionLoading());
    axios.post(`${ANALYTICS_URL}/retail/MarketBasket/${CLIENT_ID()}`, itemValue, ACCESS_TOKEN())
        .then(res => {
            dispatch({
                type: POST_MARKET_BASKET_DATA,
                payload : res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        })
}

/*--- Market Basket Loading ---*/
export const getTimeSeriesPredictionLoading = () => {
    return {
        type: MARKET_BASKET_LOADING
    }
}

/*--- Clear Market Basket ---*/
export const clearCurrentMarketBasket = () => {
    return {
        type: CLEAR_CURRENT_MARKET_BASKET
    }
}