import axios from 'axios';
import { ANALYTICS_URL, ACCESS_TOKEN, CLIENT_ID, CASHFLOW_TIMESERIES_LOADING, CASHFLOW_TIMESERIES_DETAILS, CLEAR_CURRENT_CASHFLOW_TIMESERIES } from './types';
import { logoutUser } from './authActions';

/*--- Cashflow TimeSeries Data ---*/
export const cashflowTimeSeriesData = () => dispatch => {
    dispatch(getCashflowTimeSeriesLoding());
    axios.get(`${ANALYTICS_URL}/cash_flow/interactive_time_sereis/${CLIENT_ID()}`, ACCESS_TOKEN())
        .then(res => {
            dispatch({
                type: CASHFLOW_TIMESERIES_DETAILS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: CASHFLOW_TIMESERIES_DETAILS,
                payload: {}
            })
            if(err.response && err.response.status === 401){
                dispatch(logoutUser());
            }
        })
}

/*--- Loading Cashflow Timeseries ---*/
export const getCashflowTimeSeriesLoding = () => {
    return {
        type: CASHFLOW_TIMESERIES_LOADING
    }
}

/*--- Clear Cashflow Timeseries ---*/
export const clearCurrentCashflowTimeseries = () => {
    return {
        type: CLEAR_CURRENT_CASHFLOW_TIMESERIES
    }
}