import React from 'react';

const FileDetails = (props) => {
    return (
        <div className="file_details">
            <div className="fileId">
                Id : {props.itemId}
            </div>
            <div className="file_data">
                <div className="file_name">
                    <span style={{textTransform: 'capitalize'}}>{props.name}</span> : {props.itemName}
                </div>
                <div className="file_size">
                    File Size: {props.size}
                </div>
                <div className="file_uploaded_status">
                    <div className="file_uploaded">
                        Uploaded : {props.uploadStatus ? <i className="fas fa-check-circle"></i> : <i className="fas fa-times-circle"></i>}
                    </div>
                    <div className="file_processing">
                        <div className="file_processing_stage">
                            Processing 1st stage : {props.processStatus ? <i className="fas fa-check-circle"></i> : <i className="fas fa-times-circle"></i>}
                        </div>
                        <div className="file_processing_stage">
                            Processing 2st stage : {props.processStatusPartialLock ? <i className="fas fa-times-circle"></i> : <i className="fas fa-check-circle"></i>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FileDetails;