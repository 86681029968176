import axios from 'axios';
import { AUTH_TOKEN, TOKEN_AUTH, DOMAIN_LIST, DOMAIN_MODULES_LOADING, GET_ERRORS } from './types';
import { setCurrentUser } from './authActions';
import { notification } from 'antd';

/*--- Get Domain List ---*/
export const domainList = () => dispatch => {
    dispatch(getDomainModuleLoding());
    axios.get(`/rest/register/domains`, AUTH_TOKEN())
        .then(res => {
            dispatch({
                type: DOMAIN_LIST,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            })
        })
}

/*--- Domain Selection ---*/
export const selectDomain = (data, route, history) => dispatch => {
    axios.post(`/rest/register/domains`, data, AUTH_TOKEN())
        .then(res => {
            console.log(res)
            notification.success({
                message: 'Domain Created',
                description: "Domain has created Successfully"
            });
            dispatch(setCurrentUser(TOKEN_AUTH()))
            setTimeout(history.push(route), 3000);
        })
        .catch(err =>{
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            })
        })
}

/*--- Domain & Module Loading ---*/
export const getDomainModuleLoding = () => {
    return {
        type: DOMAIN_MODULES_LOADING
    }
}