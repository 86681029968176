import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

class ClientwiseInflow extends Component {

    componentDidMount() {
        this.clientwiseInflow(this.props.clientwiseInflow)
    }

    clientwiseInflow = data => {
        var width=1250,height=400;

		const render = data => {
			data.forEach(function(d){
				d["%-inflow"]=100* d["%-inflow"];
			})

			const xvalue =  d => d["%-inflow"];
            const yvalue = d => d.particulars;
            
            data.sort(function(a,b){return a["%-inflow"]-b["%-inflow"]})

			var margin = {top: 20, right: 10, bottom: 60, left: 130};
			var innerwidth = width - margin.left - margin.right;
			var innerheight = height - margin.top - margin.bottom;
            const svg = d3.select('#clientwiseInflow').append("svg")
                .attr("width",width).attr("height",height);

			const g = svg.append('g')
						.attr('transform', "translate(" + margin.left + "," + margin.top + ")");


			console.log(data)

			const yscale = d3.scaleBand()
						.domain(data.map(yvalue))
						.range([innerheight, 0])
						.padding(0.3);

			const xaxistickformat = number =>
						 100*(number);

			const yaxisG = g.append('g').call(d3.axisLeft(yscale)
					   // .tickFormat(xaxistickformat)
						// .tickSize(-innerheight+10)
						);

				yaxisG.select('.domain')
					.remove();                  

            // var color  = d3.scaleOrdinal().range(['rgba(255, 99, 132, 0.2)', 
            //     'rgba(54, 162, 235, 0.2)', 
            //     'rgba(255, 206, 86, 0.2)', 
            //     'rgba(75, 192, 192, 0.2)', 
            //     'rgba(153, 102, 255, 0.2)', 
            //     'rgba(255, 159, 64, 0.2)' ])

            // var strokecolor=d3.scaleOrdinal().range([ 'rgba(255,99,132,1)', 
            //         'rgba(54, 162, 235, 1)', 
            //         'rgba(255, 206, 86, 1)', 
            //         'rgba(75, 192, 192, 1)', 
            //         'rgba(153, 102, 255, 1)', 
            //         'rgba(255, 159, 64, 1 )'])

                const xscale = d3.scaleLinear()
                            .domain([0, d3.max(data, xvalue)])
                            .range([0, innerwidth]);

                console.log(d3.max(data, xvalue))

                const xaxis = d3.axisBottom(xscale);
                                
                //yaxis(g.append('g'));
                const xaxisG = g.append('g').call(xaxis)
                .attr('transform', "translate(0 ," + (innerheight) + ")")

                xaxisG.selectAll('.domain, .tick line')
                                .remove()

                xaxisG.append('text')
                    .attr("class", "Yaxis-label")
                    .attr('y', 40)
                    .attr('x', innerwidth/2)
                    .attr("fill", "black")
                    .text('Cash Inflow %');


                g.selectAll('rect')
                .data(data)
                .enter()
                .append('rect')
                .attr("y", function(d){ return yscale(yvalue(d)) })
                .attr('width', d =>xscale(xvalue(d)))
                .attr('height', yscale.bandwidth() )
                .attr("fill","rgb(236,179,255)")
                .attr("stroke","rgb(191,0,255)")
        }
        render(data)
    }

    render() {
        return (
            <div className="clientwiseInflow" id="clientwiseInflow"></div>
        );
    }
}

ClientwiseInflow.propTypes = {
    clientwiseInflow: PropTypes.array.isRequired
};

export default ClientwiseInflow;