import { EXPENSE_LOADING, GET_DIFFERENT_HEADWISE_OUTFLOW, GET_MONTHLY_TOPK_EXPENSE, CLEAR_CURRENT_EXPENSE } from '../actions/types';

const initialState = {
    loading: false,
    differentHeadwiseOutflow: null,
    monthlyTopkExpense: null
}

export default function(state = initialState, action) {
    switch(action.type) {
        case EXPENSE_LOADING:
            return {
                ...state,
                loading: true
            }
        case GET_DIFFERENT_HEADWISE_OUTFLOW:
            return {
                ...state,
                differentHeadwiseOutflow: action.payload,
                loading: false
            }
        case GET_MONTHLY_TOPK_EXPENSE:
            return {
                ...state,
                monthlyTopkExpense: action.payload,
                loading: false
            }
        case CLEAR_CURRENT_EXPENSE:
            return {
                ...state,
                loading: false,
                differentHeadwiseOutflow: null,
                monthlyTopkExpense: null
            }
        default:
            return state;
    }
}