import React, { Component } from "react";
import $ from 'jquery';
import * as d3 from 'd3'

class RadialChart extends Component {

    componentDidMount() {
        console.log(this.props.radialChart)
        this.radialChart(this.props.radialChart);
    }

    radialChart = (data) => {
        const $ctnr = $("#radialchart")
        var margin = {top: 0, right: 0, bottom:0, left: 0},
        width = $ctnr.width() - margin.left - margin.right,
        height = $ctnr.height() - margin.top - margin.bottom,
        radius = 100;

    var canvas1 = d3.select("#radialchart")
            .append("svg")
            .attr("width", width)
            .attr("height", height)
            .attr("class","backsvg")
            .append("g")
            .attr("transform", "translate(" + (height + 20) +", " + 140 + ")");
            
    var color = d3.scaleOrdinal()
                .range(["#c1a5f0", "#c2f0a5", "#82E0AA", "#F4D03F", "#7b6888", "#98abc5",
                "#F5B041", "#F1948A",
                "#a05d56", "#ff8c00",
                "#8a89a6"]);

    var arc = d3.arc()
            .innerRadius(radius-10)
            .outerRadius(0)
            //.padAngle(0.03)
            .cornerRadius(10);

    // var labelArc = d3.arc()
    //         .outerRadius(radius - 50)
    //         .innerRadius(radius - 50)
            
    //        ; 
            

    var pie = d3.pie()
            .sort(null)
            .value(function(d) { return d.Amount; })

            canvas1.append("text")
            .attr("class","title")
            .attr('x', 20)
            .attr('y', -100)
            .text("Yearly Total Sales")

        


        const render = (data) =>{

            // var key = Object.keys(data)
            // console.log(data);
            var pdata = [];
            var key = Object.keys(data)

            // console.log("key",key)

            for(var i=0;i<key.length;i++)
            {
                var da = data[key[i]]["quarterly"]
                var s = d3.nest()
                    .rollup(function(v) { return { total: d3.sum(v, function(d) { return d["revenue"]})}})
                    .entries(da);

                pdata.push({"name": key[i], "value": s.total})

            }
            // var da = data['2018']["quarterly"]
            // var da1 = data['2019']["quarterly"]


            // var s = d3.nest()
            //         .rollup(function(v) { return { total: d3.sum(v, function(d) { return d["quarterly_sales"]})}})
            //         .entries(da);

            // var s1 = d3.nest()
            //         .rollup(function(v) { return { total: d3.sum(v, function(d) { return d["quarterly_sales"]})}})
            //         .entries(da1);

            // console.log("s1:",s1)

            // var pdata = [ {"name": "2018", "value": s.total},
            //                         {"name": "2019", "value": s1.total}]

            // console.log(pdata)
        
        

        pdata.forEach(function(d) {
            d.Amount = +d.value;
            d.Party_Name = d.name;
        });
        

        // data.forEach(function(d){
        //     d.Amount = +d.Amount;
        //     d.Party_Name = d.Party_Name;
        // })
        
        // console.log(pie(pdata))
        var g = canvas1.selectAll(".arc")
                .data(pie(pdata))
                .enter()
                .append("g")
                .attr("class", "arc");

        var number = d3.format(".2s")

        g.append("path")
            .attr("d", arc)
            .style("fill", function(d) {return color(d.data.Party_Name)})
            .style('stroke', 'white')
            .style('stroke-width', 4)
            .transition()
            .ease(d3.easeLinear)
            .duration(2000)
            .attrTween("d", pietween)

        g.append("svg:title")
            .text(function(d) {
                var t = "Total Purchase";
                return t +" : " +number(d.data.Amount)});
        
        var total= d3.sum(data, function(d){return d.Amount;});
        // console.log(total)
        // var toPercent = d3.format("0.1%");
   

        g.append("text")
            .transition()
            .ease(d3.easeLinear)
            .duration(2000)
            .attr("transform", function(d){ return "translate(" + arc.centroid(d) + ")"})
            //.attr("dy", ".35em")
            .attr("text-anchor", "middle")
            .attr("font-size", "12px")
            .text(function(d, i) {
          
                return "Year: "+d.data.name
    
            });
            

        var legends=g.append("g").attr("transform","translate(500,100)")
            .selectAll(".legends").data(data);
           var legend= legends.enter().append("g").classed("legends",true)
           .attr("transform",function(d,i){
               return "translate(-180, -180)";
           });
        legend.append("rect").attr("width",15).attr("height",15).attr("fill",function(d){
               return color(d.Party_Name);})
               .attr("x",-50)
               .attr("y", function(d, i) {return i*20+1;});

        legend.append("text").classed("label",true).text(function(d){
               return d.Party_Name;
           })
           .attr("fill", "#99994d")
               .attr("x",0)
               .attr("y", function(d, i) {return i*20+14;})
               .style("font-size", "15px");

               function pietween(b){
                b.innerRadius = 0;
                var i = d3.interpolate({startAngle: 0, endAngle: 0}, b);
                return function(t){return arc(i(t))}
            }



        }
        render(data)

       
        }

        componentWillUnmount(){
            d3.select("svg").remove();
        }

    render() {
        return (
            <div id="radialchart"></div>
        )
    }
}


export default RadialChart