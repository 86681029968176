import { CASHFLOW360_LOADING, GET_REVENUE_EXPENSE_KPI, GET_MONTHLY_INFLOW_OUTFLOW, GET_CUM_INFLOW_OUTFLOW_BALANCE, GET_MONTHLY_CASH_BALANCE, CLEAR_CURRENT_CASHFLOW360 } from '../actions/types';

const initialState = {
    loading: false,
    revenueExpenseKpi: null,
    monthlyInflowOutflow: null,
    cumInflowOutflow: null,
    monthlyCashBalance: null
}

export default function(state = initialState, action) {
    switch(action.type) {
        case CASHFLOW360_LOADING:
            return {
                ...state,
                loading: true
            }
        case GET_REVENUE_EXPENSE_KPI:
            return {
                ...state,
                revenueExpenseKpi: action.payload,
                loading: false
            }
        case GET_MONTHLY_INFLOW_OUTFLOW:
            return {
                ...state,
                monthlyInflowOutflow: action.payload,
                loading: false
            }
        case GET_CUM_INFLOW_OUTFLOW_BALANCE:
            return {
                ...state,
                cumInflowOutflow: action.payload,
                loading: false
            }
        case GET_MONTHLY_CASH_BALANCE:
            return {
                ...state,
                monthlyCashBalance: action.payload,
                loading: false
            }
        case CLEAR_CURRENT_CASHFLOW360:
            return {
                ...state,
                ...initialState
            }
        default:
            return state;
    }
}