import React from 'react';
import Slider from "react-slick";

const Slides = (props) => {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 2000,
        pauseOnHover: true
    };

    const imageOut = (
        props.data.map((a,i) => (
            <div key={i}>
                <img 
                    src={a} 
                    alt=""
                    style={{
                        ...props.style
                    }} 
                />
            </div>
        ))
    )
    return (
        <div>
            <Slider {...settings}>
                {imageOut}
            </Slider>
        </div>
    );
};

export default Slides;