import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import AnimatedProgressProvider from './AnimatedProgressProvider';
import { easeQuadInOut } from 'd3-ease';

const AnimationProgressBAr = (props) => {
    return (
        <AnimatedProgressProvider
            valueStart={0}
            valueEnd={props.percentage}
            duration={2}
            easingFunction={easeQuadInOut}
        >
            {
                value => {
                    const roundedValue = Math.round(value * 100) / 100;
                    return (
                        <CircularProgressbar 
                            className={props.className}
                            value={value}
                            text={`${roundedValue}%`}

                            styles={buildStyles({ 
                                pathTransition: 'none',
                                textSize: '18px',

                                // Colors
                                // pathColor: `rgba(252, 119, 105, ${props.percentage / 100})`,
                                // textColor: 'rgba(252, 119, 105)'
                                ...props.style
                            })}
                        />
                    );
                }
            }
        </AnimatedProgressProvider>
    );
};

export default AnimationProgressBAr;