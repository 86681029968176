import { FILE_UPLOADING_LOADING, FILE_UPLOADING_RESULT, FILE_PROCESSING_RESULT,  UPLOADING_PROGRESS_PERCENTAGE, 
        CLEAR_CURRENT_FILE_UPLOADING, FILE_PARTIAL_LOCK } from '../actions/types';

const initialState = {
    loading: false,
    fileUploadingProgress : null,
    fileUploadingResult: null,
    fileProcessingResult: null,
    filePartialLock: null
}

export default function(state = initialState, action) {
    switch(action.type) {
        case FILE_UPLOADING_LOADING:
            return {
                ...state,
                loading: true
            }
        case UPLOADING_PROGRESS_PERCENTAGE:
            return {
                ...state,
                fileUploadingProgress : action.payload,
                loading: false
            }
        case FILE_UPLOADING_RESULT: 
            return {
                ...state,
                fileUploadingResult: action.payload,
                loading: false
            }
        case FILE_PROCESSING_RESULT:
            return {
                ...state,
                fileProcessingResult: action.payload,
                loading: false
            }
        case FILE_PARTIAL_LOCK:
            return {
                ...state,
                filePartialLock: action.payload,
                loading: false
            }
        case CLEAR_CURRENT_FILE_UPLOADING:
            return {
                ...state,
                fileUploadingProgress : null,
                fileUploadingResult: null,
                fileProcessingResult: null,
                filePartialLock: null
            }
        default :
            return state
    }
}