import React, { Component } from 'react'
import AbcGraphs from './AbcGraphs';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class AbcChart extends Component{
    render()
    {
        return (
            <div >
                <AbcGraphs />
            </div>
        )
    }

}

AbcChart.propTypes = {
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(AbcChart);