import React,{ Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as d3 from 'd3';

class PieChart extends Component {

    componentDidMount(){
      // console.log(this.props.pieChart)
      this.piechart(this.props.pieChart);
    }

    piechart = (data) =>{
        var margin = { top: 20, bottom: 20, left: 20, right: 20 }
        var width = 400 - margin.left - margin.right,
          height = 400 - margin.top - margin.bottom;

          var radius = 180;

          var svg = d3.select("#piechart")
            .append("svg")
            .attr("width", width)
            .attr("height", height)

          svg.append("text")
            .attr("x", width / 2)
            .attr("y", 30)
            .attr("class", "pie_title")
            .style("text-anchor", "middle")
            .text("Sales Piechart")

          var color = d3.scaleOrdinal().range(['#87CEFA', "	#7CFC00", "#FF1493"])

          var g = svg.append("g").attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

          var pie = d3.pie().sort(null)
            .value(function (d) {
              return d.frequency;
            });

          var arc = d3.arc()
            .innerRadius(0)
            .outerRadius(radius - 50);

          var explode = function (x, index) {
            var offset = 5;
            var angle = (x.startAngle + x.endAngle) / 2;
            var xOff = Math.sin(angle) * offset;
            var yOff = -Math.cos(angle) * offset;
            return "translate(" + xOff + "," + yOff + ")";
          }

          const render = (data) =>{

            data.forEach(function (d) {
                d.letter = d.cat;
                d.frequency = d.counts;
              });

              var piedata = pie(data);

            var path = g.selectAll(".arc")
            .data(piedata)
            .enter().append("g")
            .attr("class", "arc")

            path.append("path")
              .attr("d", arc)
              .attr('fill', function (d) { return color(d.data.counts) })
              .attr("transform", explode);

            path.append("text")
              .attr("transform", function (d) { return "translate(" + arc.centroid(d) + ")" })
              .text(function (d) { return d.data.letter })
              .attr("font-size", "20px");


            path.append("svg:title")
              .text(function (d) {
                var t = "Products";
                return d.data.counts + "  " + t
              });
          }
          render(data);

    }


    render(){
        return (
            <div id="piechart"></div>
        )
    }

}

PieChart.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  pieChart: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
})

export default connect(mapStateToProps)(PieChart)