import axios from "axios";
import {
  ANALYTICS_URL,
  ACCESS_TOKEN,
  CLIENT_ID,
  CASHFLOW360_LOADING,
  GET_REVENUE_EXPENSE_KPI,
  GET_MONTHLY_INFLOW_OUTFLOW,
  GET_CUM_INFLOW_OUTFLOW_BALANCE,
  GET_MONTHLY_CASH_BALANCE,
  CLEAR_CURRENT_CASHFLOW360,
} from "./types";
import { logoutUser } from "../actions/authActions";

/*--- Revenue Expense Kpi Details ---*/
export const getRevenueExpenseKpi = () => (dispatch) => {
  dispatch(clearCurrentCashflow360());
  dispatch(getCashFlow360Loding());
  axios
    .get(
      `${ANALYTICS_URL}/cash_flow/revenue_expense_kpi/${CLIENT_ID()}`,
      ACCESS_TOKEN()
    )
    .then((res) => {
      const data = res.data || {}; // Fallback to an empty object if res.data is null/undefined
      dispatch({
        type: GET_REVENUE_EXPENSE_KPI,
        payload: data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_REVENUE_EXPENSE_KPI,
        payload: {}, // Dispatch an empty object if there’s an error
      });

      if (err.response && err.response.status === 401) {
        dispatch(logoutUser());
      } else {
        console.error("Error fetching data:", err);
      }
    });
};

/*--- Get Monthly Inflow Outflow ---*/
export const getMonthlyInflowOutflow = () => (dispatch) => {
  dispatch(clearCurrentCashflow360());
  dispatch(getCashFlow360Loding());
  axios
    .get(
      `${ANALYTICS_URL}/cash_flow/monthly_inflow_outflow/${CLIENT_ID()}`,
      ACCESS_TOKEN()
    )
    .then((res) => {
      const data = res.data || {};
      dispatch({
        type: GET_MONTHLY_INFLOW_OUTFLOW,
        payload: data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_MONTHLY_INFLOW_OUTFLOW,
        payload: {},
      });
      if (err.response && err.response.status === 401) {
        dispatch(logoutUser());
      }
    });
};

/*--- Get Cum Inflow Outflow Balance ---*/
export const getCumInflowOutflowBalance = () => (dispatch) => {
  dispatch(clearCurrentCashflow360());
  dispatch(getCashFlow360Loding());
  axios
    .get(
      `${ANALYTICS_URL}/cash_flow/cum_inflow_outflow_balance/${CLIENT_ID()}`,
      ACCESS_TOKEN()
    )
    .then((res) => {
      const data = res.data || {};
      dispatch({
        type: GET_CUM_INFLOW_OUTFLOW_BALANCE,
        payload: data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_CUM_INFLOW_OUTFLOW_BALANCE,
        payload: {},
      });
      if (err.response && err.response.status === 401) {
        dispatch(logoutUser());
      }
    });
};

/*--- Get Monthly Cash Balance ---*/
export const getMonthlyCashBalance = () => (dispatch) => {
  dispatch(getCashFlow360Loding());
  axios
    .get(
      `${ANALYTICS_URL}/cash_flow/monthly_cash_balance/${CLIENT_ID()}`,
      ACCESS_TOKEN()
    )
    .then((res) => {
      const data = res.data || {};
      dispatch({
        type: GET_MONTHLY_CASH_BALANCE,
        payload: data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_MONTHLY_CASH_BALANCE,
        payload: {},
      });
      if (err.response && err.response.status === 401) {
        dispatch(logoutUser());
      }
    });
};

/*--- Loading 360 Data ---*/
export const getCashFlow360Loding = () => {
  return {
    type: CASHFLOW360_LOADING,
  };
};

/*--- Clear Cashflow360 ---*/
export const clearCurrentCashflow360 = () => {
  return {
    type: CLEAR_CURRENT_CASHFLOW360,
  };
};
