import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import jwt_decode from 'jwt-decode';

let currentTime;
let decoded;

if(localStorage.AnalyticalAppToken) {
    const token = JSON.parse(localStorage.getItem('AnalyticalAppToken'));

    // Decode token and get user info and exp
    decoded = jwt_decode(token.auth_token);

    // check for expired token
    currentTime = Date.now() / 1000;
}

const PrivateRoute = ({ component: Component, auth, ...rest }) => (
    <Route 
        { ...rest }
        render = {props => 
            (decoded && decoded.exp < currentTime) || !localStorage.AnalyticalAppToken ? (
                <Redirect 
                    to={{
                        pathname: '/login',
                        state: { from: props.location }
                    }}
                />
            ) : (
                <Component { ...props } />
            )
        }
    />
);

PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps)(PrivateRoute);