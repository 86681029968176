import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RequestLoadingData from '../../Loading/RequestLoadingData';
import { logoutUser } from '../../../redux/actions/authActions';
import PredictionChart from './PredictionChart';
import { getHistAndPredData, getNextSevedaysPredData, getCurrentNextMonthPredData } from '../../../redux/actions/timeSeriesPredictionAction';
import isEmpty from '../../../validation/is-empty';

class TimeSeriesPrediction extends Component {

    componentDidMount() {
        this.props.getHistAndPredData()
        this.props.getNextSevedaysPredData() 
        this.props.getCurrentNextMonthPredData()
    }

    render() {
        const { loading, getHistAndPredData, getNextSevedaysPredData, getCurrentNextMonthPredData } = this.props.timeSeriesPrediction;

        let timeSeriesPrediction;

        if(getHistAndPredData !== null && getNextSevedaysPredData !== null && getCurrentNextMonthPredData !== null){
            if(isEmpty(getHistAndPredData) === true || isEmpty(getNextSevedaysPredData) === true || isEmpty(getCurrentNextMonthPredData) === true){
                timeSeriesPrediction = (
                    <div>
                        <p className="lead text-muted">Sorry</p>
                        <p>Something went wrong please try again after some time</p>
                    </div>
                );
            } else {
                timeSeriesPrediction = (
                    <div>
                        <div className="row">
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                <div className="card timeSeries Prediction-card">
                                    <h4 className="text-center">Next 7 Days Forecast</h4>
                                    <table className="table table-bordered" id="tab1" style={{ border: "1px solid black" }}>
                                        <thead>
                                            <tr style={{backgroundColor:"#87CEFA",color:"white",fontWeight:"bold"}}>
                                                <th>Date</th>
                                                <th>Lower</th>
                                                <th>Prediction</th>
                                                <th>Upper</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                getNextSevedaysPredData &&
                                                getNextSevedaysPredData.map((tab1, index) => {
                                                    return (<tr key={index}>
                                                        <td>{tab1.ds}</td>
                                                        <td>{tab1.prediction}</td>
                                                        <td>{tab1.lower}</td>
                                                        <td>{tab1.upper}</td>
                                                    </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                                <div className="card timeSeries Prediction-card">
                                    <h4 className="text-center">Current and Next Month Forecast</h4>
                                    <table className="table table-bordered" id="tab2" style={{ border: "1px solid black" }}>
                                        <thead>
                                            <tr style={{backgroundColor:"#87CEFA",color:"white",fontWeight:"bold"}}>
                                                <th>Month_Year</th>
                                                <th>Lower</th>
                                                <th>Prediction</th>
                                                <th>Upper</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{getCurrentNextMonthPredData.curr_month.month_year}</td>
                                                <td>{getCurrentNextMonthPredData.curr_month.prediction}</td>
                                                <td>{getCurrentNextMonthPredData.curr_month.lower}</td>
                                                <td>{getCurrentNextMonthPredData.curr_month.upper}</td>
                                            </tr>
                                            <tr>
                                                <td>{getCurrentNextMonthPredData.next_month.month_year}</td>
                                                <td>{getCurrentNextMonthPredData.next_month.prediction}</td>
                                                <td>{getCurrentNextMonthPredData.next_month.lower}</td>
                                                <td>{getCurrentNextMonthPredData.next_month.upper}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="card timeSeries Prediction-card pred-chart">
                                    <PredictionChart prediction={getHistAndPredData} />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        } else {
            timeSeriesPrediction = <RequestLoadingData />
        }

        return (
            <div className="container-fluid">
                {timeSeriesPrediction}
            </div>
        );
    }
}

TimeSeriesPrediction.propTypes = {
    getHistAndPredData: PropTypes.func.isRequired,
    getNextSevedaysPredData: PropTypes.func.isRequired,
    getCurrentNextMonthPredData: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    timeSeriesPrediction: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    timeSeriesPrediction: state.timeSeriesPrediction
})

export default connect(mapStateToProps, { getHistAndPredData, getNextSevedaysPredData, getCurrentNextMonthPredData, logoutUser })(TimeSeriesPrediction);