import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { notification } from 'antd';
import ImageSlide from './ImageSlide';
import TextFieldGroup from '../Reusable_Components/TextFieldGroup';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { registeruser } from '../../redux/actions/authActions';
import classnames from 'classnames';
import Logo from '../../Resources/images/Logo/Z9BhAe.png';
import isEmpty from '../../validation/is-empty';
import validateRegisterInput from '../Validation/Register_Login/Register';

class Register extends Component {

    constructor(){
        super();
        this.state = {
            fname: {
                value: '',
                valid: false
            },
            lname: {
                value: '',
                valid: false
            },
            email: {
                value: '',
                valid: false
            },
            password: {
                value: '',
                valid: false
            },
            confirmPassword: {
                value: '',
                valid: false
            },
            // passwordValid: false,
            errors: {},
            getInitialState: function() {
                return {
                    checked: false
                }
            },
        }
    }

    componentDidMount() {
        const paramsString = this.props.location.search;
        const params = new URLSearchParams(paramsString);
        const tags = params.get('email');
        if(tags){
            this.setState({
                email: {
                    value: tags,
                    valid: true
                }
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if(isEmpty(nextProps.errors) === false) {
            const status = nextProps.errors.status;
            const message = nextProps.errors.data.message;
            if(status === 409 || status === 500){
                notification.error({
                    message: 'Registration Error',
                    description: message
                });
            } else if(status === 404){
                notification.error({
                    message: 'Email Sending Error',
                    description: message
                });
            }
        }
    }

    changeHandler = e => {
        this.setState({ 
            [e.target.name]: { 
                value: e.target.value, 
                valid: !!e.target.value 
            } 
        });
    };

    passwordValid = e => {
        if(e.target.name === 'password' && this.state.confirmPassword.value !== e.target.value){
            this.setState({
                errors : {
                    confirmPasswordError : 'Password Match Error'
                }
            })
        }

        if(e.target.name === 'confirmPassword' && this.state.password.value === this.state.confirmPassword.value){
            this.setState({
                errors : {
                    confirmPasswordSuccess : 'Password Match Success'
                }
            })
        }
    }

    handleCheck = () => {
        this.setState({
            checked: !this.state.checked
        })
    }

    onSubmit = e => {
        e.preventDefault();

        const { errors, isValid } = validateRegisterInput(this.state, this.state.checked)
        
        if(isValid){
            const userData = {
                firstName : this.state.fname.value,
                lastName : this.state.lname.value,
                email : this.state.email.value,
                password : this.state.confirmPassword.value
            };
            // console.log(userData);
            this.props.registeruser(userData, this.props.history);
        } else {
            this.setState({ errors })
        }
    }

    render() {
        const { errors } = this.state;

        // const { user } = this.props.auth;

        return (
            <div className="container-fluid">
                <div className="row login-container">
                    <div className="col-md-6 login_form_image">
                        <ImageSlide/>
                    </div>
                    <div className="col-md-6 login-form">
                        <div className="text-center logo">
                            <Link to="/">
                                <img src={Logo} alt="Logo" style={{height: '40px'}} />
                            </Link>
                            <div className="register_heading">
                                Join Analytics Community!
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <div className="register_user_card">
                                <div className="d-flex justify-content-center">
                                    <div className="brand_signup">
                                        Sign<span className="up_brand">Up</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center px-5">
                                    <form noValidate onSubmit={this.onSubmit} autoComplete="off">
                                        <div className="grey-text">
                                            <div className="row">
                                                <div className="col-xs-6 col-sm-6 col-md-6 py-0 m-0">
                                                    <TextFieldGroup 
                                                        label="First Name"
                                                        icon="user"
                                                        name="fname"
                                                        type="text"
                                                        value={this.state.fname.value}
                                                        onChange={this.changeHandler}
                                                        error={errors.fname}
                                                    />
                                                </div>
                                                <div className="col-xs-6 col-sm-6 col-md-6 py-0 m-0">
                                                    <TextFieldGroup 
                                                        label="Last Name"
                                                        icon="user"
                                                        name="lname"
                                                        type="text"
                                                        value={this.state.lname.value}
                                                        onChange={this.changeHandler}
                                                        error={errors.lname}
                                                    />
                                                </div>
                                            </div>
                                            <TextFieldGroup 
                                                label="Email Address"
                                                icon="envelope"
                                                name="email"
                                                type="text"
                                                value={this.state.email.value}
                                                onChange={this.changeHandler}
                                                error={errors.email}
                                            />
                                            <div className="row">
                                                <div className="col-xs-6 col-sm-6 col-md-6 py-0 m-0">
                                                    <TextFieldGroup 
                                                        label="Password"
                                                        icon="lock"
                                                        name="password"
                                                        type="password"
                                                        value={this.state.password.value}
                                                        onChange={this.changeHandler}
                                                        onKeyUp={this.passwordValid}
                                                        error={errors.password}
                                                    />
                                                </div>
                                                <div className="col-xs-6 col-sm-6 col-md-6 py-0 m-0">
                                                    <TextFieldGroup 
                                                        label="Confirm Password"
                                                        icon="key"
                                                        name="confirmPassword"
                                                        type="password"
                                                        value={this.state.confirmPassword.value}
                                                        onChange={this.changeHandler}
                                                        onKeyUp={this.passwordValid}
                                                        error={errors.confirmPassword || errors.confirmPasswordError}
                                                        success={errors.confirmPasswordSuccess}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="checkbox mr-5">
                                                <label className="checkbox-label">
                                                    <input 
                                                        type="checkbox" 
                                                        className={classnames('form-check-input', {
                                                            'is-invalid': errors.checked
                                                        })}  
                                                        id="materialUnchecked" 
                                                        onChange={this.handleCheck} 
                                                        defaultChecked={this.state.checked} 
                                                    />
                                                    <span className="cr"><i className="cr-icon fa fa-check"></i></span>
                                                    Accept the <Link to="#">Terms and Conditions</Link>
                                                </label>
                                            </div>
                                            {/* <div className="form-check">
                                                <input 
                                                    type="checkbox"
                                                    className={classnames('form-check-input', {
                                                        'is-invalid': errors.checked
                                                    })}  
                                                    id="materialUnchecked" 
                                                    onChange={this.handleCheck} 
                                                    defaultChecked={this.state.checked} 
                                                />
                                                <label className="form-check-label">Accept the</label>
                                            </div> */}
                                            {/* <Link to="#">Terms and Conditions</Link> */}
                                        </div>
                                        {
                                            errors.checked ?
                                                <div className="error-msgs">{errors.checked}</div>
                                            :
                                            null
                                        }
                                        <button className="btn purple-gradient btn-rounded btn-block my-2 waves-effect" type="submit">Sign up</button>
                                    </form>
                                </div>
                                <div className="d-flex justify-content-center">
                                    Have an account? <Link className="ml-2" to="/login">Login</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Register.propTypes = {
    registeruser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
})

export default connect(mapStateToProps, { registeruser })(withRouter(Register));