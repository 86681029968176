import React, { Component } from 'react';
import Zoom from 'react-reveal/Zoom';
import Profile_logo from '../../Resources/images/Team/profile_logo.jpg';

class Team extends Component {

    state = {
        name: ['Rajesh', 'Ramesh', 'Selvaraj', 'Pamuleti', 'Anantha', 'Shailendra', 'Soumya'],
        image: ['', '', '', '', '', '', ''],
        designation: ['Product Owner', 'Infrastructure', 'Data Scientist', 'Application Architect', 'React Developer', 'Visuals Developer', 'Visuals Developer'],
        text: ['Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', 'Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', 'Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', 'Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', 'Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', 'Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', 'Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'],
        links: {
            facebook: ['#', '#', '#', '#', '#', '#', '#'],
            twitter: ['#', '#', '#', '#', '#', '#', '#'],
            instagram: ['#', '#', '#', '#', '#', '#', '#'],
            linkedln: ['#', '#', '#', '#', 'https://www.linkedin.com/in/anantha-madhava-202846121/', '#', '#']
        },
        delay: [0, 100, 200, 300, 400, 500, 600]
    }

    team = () => (
        this.state.name.map((a,i) => (
            <Zoom key={i} delay={this.state.delay[i]}>
                <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="single-team">
                        <div className="img-area">
                            {
                                this.state.image[i] === '' ?
                                    <img src={Profile_logo} className="img-responsive" alt="" />
                                :
                                    <img src={this.state.image[i]} className="img-responsive" alt="" />
                            }
                            <div className="social">
                                <ul className="list-inline">
                                    {/* <li className="list-inline-item"><a href={this.state.links.facebook[i]} rel="noopener noreferrer" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                    <li className="list-inline-item"><a href={this.state.links.twitter[i]} rel="noopener noreferrer" target="_blank"><i className="fab fa-twitter"></i></a></li>
                                    <li className="list-inline-item"><a href={this.state.links.instagram[i]} rel="noopener noreferrer" target="_blank"><i className="fab fa-instagram"></i></a></li> */}
                                    <li className="list-inline-item"><a href={this.state.links.linkedln[i]} rel="noopener noreferrer" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="img-text">
                            <h4>{this.state.name[i]}</h4>  
                            <h5>{this.state.designation[i]}</h5>  
                        </div>                                
                    </div>
                </div>
            </Zoom>
        ))
    )
    render() {
        return (
            <section id="team" className="container-fluid homepage team team-area">
                <div className="container mt-3 py-5 text-center">
                    <div className="row mb-5 section-heading">
                        <div className="col">
                            <h1>Our Team</h1>
                            <p className="mt-3"></p>
                        </div>
                    </div>
                    <div className="row">
                        {this.team()}
                    </div>
                </div>
            </section>
        );
    }
}

export default Team;