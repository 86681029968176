import { ABC_LOADING, GET_SALES_ABC_CATDATA, GET_SALES_AND_PROFIT_ABC_SUMMARY, GET_ABCA_ACAT_TABLE, CLEAR_CURRENT_ABC } from '../actions/types';

const initialState = {
    loading: false,
    abcCatData: null,
    abcSalesProfitSummary: null,
    abcCatTable: null
}

export default function(state = initialState, action) {
    switch(action.type) {
        case ABC_LOADING:
            return {
                ...state,
                loading: true
            }
        case GET_SALES_ABC_CATDATA:
            return {
                ...state,
                abcCatData: action.payload,
                loading: false
            }
        case GET_SALES_AND_PROFIT_ABC_SUMMARY:
            return {
                ...state,
                abcSalesProfitSummary: action.payload,
                loading: false
            }
        case GET_ABCA_ACAT_TABLE:
            return {
                ...state,
                abcCatTable : action.payload,
                loading: false
            }
        case CLEAR_CURRENT_ABC:
            return {
                ...state,
                abcCatData: null,
                abcSalesProfitSummary: null,
                abcCatTable: null
            }
        default:
            return state;
    }
}