import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ModelCard from './ModelCard';
import { domainList, selectDomain } from '../../redux/actions/selectDomainActions';

class HomeNonClient extends Component {

    componentDidMount() {
        this.props.domainList();
    }

    domain = name => {
        console.log(name)
        const { domainList } = this.props.domainModule;
        let obj = domainList.find(o => o.name === name);
        console.log(obj)
        console.log(obj._id)
        const data = {
            domain : obj._id
        }
        console.log(data)
        let route;
        if(name === 'Retail') {
            route = '/insights360';
        } else if(name === 'Cashflow Analysis') {
            route = '/cashflow360';
        }
        this.props.selectDomain(data, route, this.props.history);
    }

    render() {
        const { loading, domainList } = this.props.domainModule;

        let selectDomain;

        if(loading === false && domainList !== null) {
            selectDomain = (
                domainList.map((a,i) => (
                    <ModelCard key={i} model={a} domain={this.domain} />
                ))
            )
        } else {
            selectDomain = console.log('loading')
        }

        return (
            <div className="container-fluid domain-bg">
                <div className="container">
                    <div className="d-flex justify-content-center domain_selection">
                        Select Domain
                    </div>
                    <div className="Domain_module_size">
                        <div className="row selectDomain">
                            {selectDomain}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

HomeNonClient.propTypes = {
    domainList: PropTypes.func.isRequired,
    selectDomain : PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    domainModule: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    domainModule : state.domainModule
})

export default connect(mapStateToProps, { domainList, selectDomain })(withRouter(HomeNonClient));