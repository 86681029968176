import React from 'react';
import { Link } from 'react-router-dom';

const ModelCard = (props) => {
    const { name, description, imgUrl, isActive } = props.model;
    return (
        <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="card hoverable mb-4">
                <img className="card-img-top" src={imgUrl} alt={name}/>
                {
                    !isActive ?
                        <span><h5>Coming Soon</h5></span>
                    :
                        null
                }
                <div className="card-body">
                    <h5 className="card-title">{name}</h5>
                    <p className="card-text">{description}</p>
                    {
                        !isActive ?
                            <div className="comming-soon"></div>
                        :
                        <Link to="#" className="btn btn-outline-primary waves-effect btn-sm" name={name} onClick={e => props.domain(e.target.name)}>{name}</Link>
                    }
                </div>
            </div>
        </div>
    );
};

export default ModelCard;