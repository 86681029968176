import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as d3 from 'd3';
import { monthlySalesData } from '../../../redux/actions/insights360Actions';

class LineChart extends Component {
    
    componentDidMount() {
        this.lineChart(this.props.lineChart);
    }

    lineChart = (data) => {
        var margin = {top: 20, right: 20, bottom: 70, left: 70},
        width = 600 - margin.left - margin.right,
        height = 360 - margin.top - margin.bottom;

        // parse the date / time
        var parseDate = d3.timeParse("%b-%Y");
        var x = d3.scaleTime().range([0, width]);
        var y = d3.scaleLinear().range([height, 0]);


        var div = d3.select("body").append("div")   
        .attr("class", "tooltip")               
        .style("opacity", 0);

        var svg1 = d3.select("#linechart").append("svg")
        // .style("background", "#F5FFFA")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)

        var svg = svg1.append("g")
        .attr("transform", "translate("+ margin.left +"," + margin.top + ")");

        // Get the data
        const render = data => {

            // format the data
            data.forEach(function(d) {
                d.key = parseDate(d["mm-yyyy"]);

                d.value = +d.monthly_sales;
            });

            function sortByDateAscending(a, b) {
                // Dates will be cast to numbers automagically:
                return a.key - b.key;
            }
            
            data = data.sort(sortByDateAscending);

            // Scale the range of the data
            x.domain(d3.extent(data, function(d) { return d.key; }));
            y.domain([0, d3.max(data, function(d) { return d.value; })]);

            // define the line
            var valueline = d3.area()
                .x(function(d) { return x(d.key); })
                .y0(height)
                .y1(function(d) { return y(d.value); });

            var valueline1 = d3.line()
                .x(function(d) { return x(d.key); })
                .y(function(d) { return y(d.value); });

            svg1.append("rect")
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", 700)
                .attr("height", 400)
                .attr("fill", "none")

            svg.append("path")
            .data([data])
            .attr("d", valueline)
            .attr("fill", "#E0FFFF")
            .attr("opacity", "0.7")


            svg.append("path")
            .data([data])
            .attr("class", "line")
            .attr("d", valueline1)

            const xaxistickformat = number =>
                                        d3.format('.3s')(number);

            // Add the X Axis
            var xaxis = svg.append("g")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x).tickFormat(d3.timeFormat("%b %y")).ticks(11))

            xaxis.selectAll(".domain, .tick line").remove()

                 xaxis.selectAll("text")  
                .attr("transform", "rotate(-90)")
                            .style("text-anchor", "end")
                            .attr("dx", "-.8em")
                            .style("font-size","13px")
                            .attr("dy", "-.15em")
                            .style("font-family","'Courgette', cursive;");

            // var xaxis2 = xaxis1.append('text')
            //         .attr("class", "axis-label")
            //         .attr('y', 100)
            //         .attr('x', 100)
            //         .attr("fill", "black")
            //         .text('Months')
                            

            // Add the Y Axis
            var yaxis = svg.append("g")
                            .call(d3.axisLeft(y).tickFormat(xaxistickformat).tickSize(-width).tickPadding(8))
                            .attr("transform", "translate(0, 0)");
            yaxis.selectAll('.domain')
                            .remove();

            yaxis.append('text')
                            .attr("class", "Yaxis-label")
                            .attr('y', -50)
                            .attr('x', -150)
                            .attr("fill", "black")
                            .attr("transform", "rotate(-90)")
                            .text('Amount');              

            var formatTime = d3.timeFormat("%B %Y")

            var number = d3.format(".3s")
            // Add the scatterplot
            svg.selectAll("dot")
            .data(data)
            .enter().append("circle")
            .attr("r", 4.5)
            .attr("cx", function(d) { return x(d.key); })
            .attr("cy", function(d) { return y(d.value); })
            .attr("fill", "#7cb5ec")
            
            .attr("stroke", "white")
            .attr("stroke-width", "2px")
            .on("mouseover", function(d) {   
                d3.select(this).attr("r", 5)
                            // .attr("fill", "#00FFFF")
                            .attr("stroke", "#06C")
                            .attr("stroke-width", "2px")   
                div.transition()        
                    .duration(200)      
                    .style("opacity", .9);      
                div.html(formatTime(d.key) + "<br/>"  + number(d.value))   
                    .style("left", (d3.event.pageX) + "px")     
                    .style("top", (d3.event.pageY - 28) + "px");    
                })                  
            .on("mouseout", function(d) {  
                d3.select(this).attr("r", 4.5)
                .attr("fill", "#7cb5ec")
                .attr("stroke", "white")
                .attr("stroke-width", "2px")   
                div.transition()        
                    .duration(500)      
                    .style("opacity", 0);   
            });
        }
        render(data)
    }

    componentWillUnmount(){
        d3.select("svg").remove();
    }
    
    render() {
        return (
            <div id="linechart"></div>
        );
    }
}

LineChart.propTypes = {
    monthlySalesData : PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    insights360: PropTypes.object.isRequired,
    lineChart: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    insights360: state.insights360
})

export default connect(mapStateToProps, { monthlySalesData })(LineChart);