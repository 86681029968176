import React from 'react';
import Header from './components/Header_Footer/Header';
import Footer from './components/Header_Footer/Footer';
import ChatBots from './components/ChatBot/ChatBot';

const Layout = (props) => {
    return (
        <div>
            <Header />
            {props.children}
            <ChatBots />
            {/* <Footer /> */}
        </div>
    );
};

export default Layout;