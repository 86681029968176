import React, { Component } from 'react';
import { MDBMask, MDBView, MDBRow, MDBCol, MDBBadge, MDBIcon } from "mdbreact";

class ModelNonClient extends Component {
    render() {
        const newsStyle = {
            borderBottom: "1px solid #e0e0e0",
            marginBottom: "1.5rem"
          };
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-2">
                        <MDBCol md="12" lg="100%" className="mb-lg-0 mb-12">
                            <MDBView hover rounded  zoom>
                            <img
                                className="img-fluid"
                                src=""
                                alt=""
                                style={{height: "100px"}}
                            />
                            <a href="#!">
                                <MDBMask className="flex-center">
                                    <p className="black-text">Retail</p>
                                </MDBMask>
                            </a>
                            </MDBView>
                            <MDBRow className="mb-3">
                                <MDBCol size="12">
                                    <a href="#!">
                                    <MDBBadge color="blue">
                                        <MDBIcon fab icon="salesforce" className="pr-2" aria-hidden="true" />
                                            Retail
                                        </MDBBadge>
                                    </a>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </div>
                    <div className="col-md-10">
                        
                    </div>
                </div>
            </div>
        );
    }
}

export default ModelNonClient;