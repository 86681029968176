import axios from 'axios';
import { DATA_REPOSITORY_LOADING, DATA_REPOSITORY, CLEAR_CURRENT_DATA_REPOSITORY } from './types';
import { logoutUser } from '../actions/authActions';

/*--- Data Repository ---*/
export const dataRepository = () => dispatch => {
    dispatch(getDataRepositoryLoading());
    axios.get('/rest/doc/upload')
        .then(res => 
            dispatch({
                type: DATA_REPOSITORY,
                payload: res.data
            })    
        )
        .catch(err => {
            if(err.response && err.response.status === 500){
                dispatch({
                    type: DATA_REPOSITORY,
                    payload: {}
                })
            }

            if(err.response && err.response.status === 401){
                dispatch(logoutUser());
            }
        })
}

/*--- Data Repository Loading ---*/
export const getDataRepositoryLoading = () => {
    return {
        type: DATA_REPOSITORY_LOADING
    }
}

/*--- Clear DataRepository ---*/
export const clearRepository = () => {
    return {
        type: CLEAR_CURRENT_DATA_REPOSITORY
    }
}