import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'

import authReducer from './authReducer';
import errorReducer from './errorReducer';
import domainModule from './selectDomainReducer';

/*--- Retail ---*/
import insights360 from './insights360Reducer';
import timeseries from './timeSeriesReducer';
import abcAnalysis from './abcReducer';
import itemAnalysis from './itemAnalysisReducer';
import timeSeriesPrediction from './timeSeriesPredictionReducer';
import marketBasket from './marketBasketReducer';

/*--- Finance Analysis ---*/
import cashflow360 from './cashflow360Reducer';
import expense from'./expenseReducer';
import revenue from './revenueReducer';
import cashflowForecast from './cashflowForecastReducers';
import customerAnalysis from './customerAnalysisReducer';
import cashFlowTimeSeries from './cashFlowTimeSeriesReducers';

import fileUpload from './fileUploadReducer';
import repository from './repositoryReducer';
import chatBot from './chatBotReducer';

const persistConfig = {
    key: 'AnalyticalApp',
    storage,
    whitelist: ['insights360', 'timeseries', 'abcAnalysis', 'timeSeriesPrediction', 'cashflow360', 'expense', 'revenue', 'cashFlowTimeSeries', 'cashflowForecast']
}

const rootReducer = combineReducers({
    auth: authReducer,
    errors: errorReducer,
    domainModule: domainModule,

    /*--- Retail ---*/
    insights360: insights360,
    timeseries: timeseries,
    abcAnalysis: abcAnalysis,
    itemAnalysis: itemAnalysis,
    timeSeriesPrediction: timeSeriesPrediction,
    marketBasket: marketBasket,

    /*--- Finance Analysis ---*/
    cashflow360: cashflow360,
    expense: expense,
    revenue: revenue,
    customerAnalysis: customerAnalysis,
    cashFlowTimeSeries: cashFlowTimeSeries,
    cashflowForecast: cashflowForecast,

    fileUpload: fileUpload,
    repository: repository,
    chatBot: chatBot
});

export default persistReducer(persistConfig, rootReducer);