import { CHATBOT_LOADING, CHATBOT, CLEAR_CURRENT_CHATBOT } from '../actions/types';

const initialState = {
    loading: false,
    chatBot: []
}

export default function(state = initialState, action) {
    switch(action.type) {
        case CHATBOT_LOADING:
            return {
                ...state,
                loading: true
            }
        case CHATBOT:
            return {
                ...state,
                chatBot: [...state.chatBot, action.payload],
                loading: false
            }
        case CLEAR_CURRENT_CHATBOT:
            return {
                ...state,
                loading: false,
                chatBot: []
            }
        default:
            return state;
    }
}