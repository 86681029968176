import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NavbarUl from '../Reusable_Components/Navbar/NavbarUl';
import NavbarLi from '../Reusable_Components/Navbar/NavbarLi';
import NavLinkDrop from '../Reusable_Components/Navbar/NavLinkDrop';
import NavDropToggle from '../Reusable_Components/Navbar/NavDropToggle';
import DropLink from '../Reusable_Components/Navbar/DropLink';
import NavLink from '../Reusable_Components/Navbar/NavLink';
import { clearCurrentChatBot } from '../../redux/actions/chatBotActions';
import { clearRepository } from '../../redux/actions/repositoryAction';
import { clearCurrentInsights360 } from '../../redux/actions/insights360Actions';
import { clearCurrentTimeseries } from '../../redux/actions/timeSeriesAction';
import { clearCurrentAbc } from '../../redux/actions/abcActions';
import { clearCurrentItemAnalysis } from '../../redux/actions/itemAnalysisActions';
import { clearCurrentTimeSeriesPrediction } from '../../redux/actions/timeSeriesPredictionAction';
import { clearCurrentMarketBasket } from '../../redux/actions/marketBasketActions';
import { clearCurrentCashflow360 } from '../../redux/actions/cashflow360Actions';
import { clearCurrentExpense } from '../../redux/actions/expenseAction';
import { clearCurrentRevenue } from '../../redux/actions/revenueActions';
import { clearCurrentCustomerAnalysis } from '../../redux/actions/customerAnalysisActions';
import { clearCurrentCashflowTimeseries } from '../../redux/actions/cashFlowTimeSeriesActions';
import { clearCurrentCashflowForecast } from '../../redux/actions/cashflowForecastActions';
import { logoutUser } from '../../redux/actions/authActions';
import ClientRegORFileUpload from './ClientRegORFileUpload';

class Header extends Component {

  onLogoutClick = () => {
    this.props.clearCurrentChatBot();
    this.props.clearRepository();
    this.props.clearCurrentInsights360();
    this.props.clearCurrentTimeseries();
    this.props.clearCurrentAbc();
    this.props.clearCurrentItemAnalysis();
    this.props.clearCurrentTimeSeriesPrediction();
    this.props.clearCurrentMarketBasket();
    this.props.clearCurrentCashflow360();
    this.props.clearCurrentExpense();
    this.props.clearCurrentRevenue();
    this.props.clearCurrentCustomerAnalysis();
    this.props.clearCurrentCashflowTimeseries();
    this.props.clearCurrentCashflowForecast();
    this.props.logoutUser();
  }

  render() {
    const { user } = this.props.auth;
    const { domain } = this.props.auth.user;
    const Retail = domain && domain.name === 'Retail';
    const CashFlow = domain && domain.name === 'Cashflow Analysis'
    const { client } = this.props.auth.user;
    const pathname = this.props.location.pathname.substr(1);
    let pathUrl;
    if(pathname === 'insights360' || pathname === 'cashflow360'){
      pathUrl = '360 View'
    } else if(pathname === 'timeSeries' || pathname === 'cashflowTimeSeries'){
      pathUrl = 'Time Series Analysis'
    } else if(pathname === 'abc'){
      pathUrl = 'ABC Analysis'
    } else if(pathname === 'itemAnalysis'){
      pathUrl = 'Item Analysis'
    } else if(pathname === 'timeSeriesPrediction'){
      pathUrl = 'Time Series Prediction'
    } else if(pathname === 'market'){
      pathUrl = 'Market Basket Analysis'
    } else if(pathname === 'expense'){
      pathUrl = 'Expense'
    } else if(pathname === 'revenue'){
      pathUrl = 'Revenue'
    } else if(pathname === 'customerAnalysis'){
      pathUrl = 'Customer Analysis'
    } else if(pathname === 'cashflowForecast'){
      pathUrl = 'Forecast Predictions'
    }

    const modelName = (
      domain && domain.name === 'Retail' ?
        'POS Analysis'
      : domain && domain.name === 'Cashflow Analysis' ?
        'CashFlow Analysis'
      : 
        null
    )

    return (
      <div className="navigation-menu">
        <nav className="mb-1 navbar navbar-expand-lg navbar-dark white-color">
          <Link className="navbar-brand" to="/">
            {
              client && client.isActive === true && user.isUploaded === true ?
                (
                  pathUrl === '' || pathUrl === undefined ?
                    <strong>{modelName} - {client.name}</strong>
                  :
                    <strong>{modelName} - {client.name} - {pathUrl}</strong>
                )
              :
                <strong>{modelName}</strong>
            }
          </Link>
          <button className="navbar-toggler navbar-toggler-right custom-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-333"
            aria-controls="navbarSupportedContent-333" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent-333">
            <NavbarUl>
              <NavbarLi dropdown>
                <NavLinkDrop 
                  name="Insights"
                />
                {
                  Retail ?
                    <NavDropToggle>
                      <DropLink to="/insights360" name="360°"/>
                      <DropLink to="/timeSeries" name="Time Series"/>
                      <DropLink to="/abc" name="ABC Analysis"/>
                      <DropLink to="/itemAnalysis" name="Item Analysis"/>
                    </NavDropToggle>
                  : CashFlow ?
                    <NavDropToggle>
                      <DropLink to="/cashflow360" name="360°" />
                      <DropLink to="/expense" name="Expense" />
                      <DropLink to="/revenue" name="Revenue" />
                      <DropLink to="/customerAnalysis" name="Customer Analysis" />
                      <DropLink to="/cashflowTimeSeries" name="Time Series" />
                    </NavDropToggle>
                  :
                   null
                }
              </NavbarLi>
              {
                Retail ?
                  <NavbarLi dropdown>
                    <NavLinkDrop 
                      name="Forecast"
                    />
                    <NavDropToggle>
                      <DropLink to="/timeSeriesPrediction" name="Time Series Prediction" />
                      <DropLink to="/market" name="Market Basket Analysis" />
                    </NavDropToggle>
                  </NavbarLi>
                : CashFlow ?
                  <NavbarLi>
                    <NavLink to="/cashflowForecast" name="Forecast" />
                  </NavbarLi>
                :
                  null
              }
              {
                client && client.isActive === true ?
                  <NavbarLi dropdown>
                    <NavLinkDrop 
                      name="Data"
                    />
                    <NavDropToggle right={true}>
                      <DropLink to="/fileUpload" name="Upload" />
                      <DropLink to="/dataRepository" name="Data Repository" />
                    </NavDropToggle>
                  </NavbarLi>
                : 
                  null
              }
              <NavbarLi dropdown>
                <NavLinkDrop 
                  icon="user"
                  name={`${user.firstName}`}
                />
                <NavDropToggle right={true}>
                  <DropLink to="#" name="Logout" onClick={this.onLogoutClick} />
                </NavDropToggle>
              </NavbarLi>
            </NavbarUl>
          </div>
        </nav>
        {
          client && client.isActive === true ?
            null
          :
            <ClientRegORFileUpload to="/ClientRegistration" />
        }
      </div>
    );
  }
}

Header.propTypes = {
  clearCurrentChatBot: PropTypes.func.isRequired,
  clearRepository: PropTypes.func.isRequired,
  clearCurrentInsights360: PropTypes.func.isRequired,
  clearCurrentTimeseries: PropTypes.func.isRequired,
  clearCurrentAbc: PropTypes.func.isRequired,
  clearCurrentItemAnalysis: PropTypes.func.isRequired,
  clearCurrentTimeSeriesPrediction:PropTypes.func.isRequired,
  clearCurrentMarketBasket: PropTypes.func.isRequired,
  clearCurrentCashflow360: PropTypes.func.isRequired,
  clearCurrentExpense: PropTypes.func.isRequired,
  clearCurrentRevenue: PropTypes.func.isRequired,
  clearCurrentCustomerAnalysis: PropTypes.func.isRequired,
  clearCurrentCashflowTimeseries: PropTypes.func.isRequired,
  clearCurrentCashflowForecast: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, { clearCurrentChatBot, clearRepository, clearCurrentInsights360, clearCurrentTimeseries, clearCurrentAbc, clearCurrentItemAnalysis, clearCurrentTimeSeriesPrediction, clearCurrentMarketBasket, clearCurrentCashflow360, clearCurrentExpense, clearCurrentRevenue, clearCurrentCustomerAnalysis, clearCurrentCashflowTimeseries, clearCurrentCashflowForecast, logoutUser })(withRouter(Header));