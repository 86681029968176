import isEmpty from '../../../validation/is-empty';
import Validator from 'validator';

export default function validateResetPasswordInput(data) {
    let errors = {};

    console.group(data)
    data.newPassword.value = !isEmpty(data.newPassword.value) ? data.newPassword.value : '';
    data.confirmPassword.value = !isEmpty(data.confirmPassword.value) ? data.confirmPassword.value : '';

    if(Validator.isEmpty(data.newPassword.value)) {
        errors.newPassword = 'Password field is required';
    }

    if(Validator.isEmpty(data.confirmPassword.value)) {
        errors.confirmPassword = 'Password Error';
    }

    if(!Validator.equals(data.newPassword.value, data.confirmPassword.value)) {
        errors.confirmPassword = 'Password must match';
    }

    return {
        errors,
        isValid: isEmpty(errors)
    }
}