import axios from 'axios';
import { ANALYTICS_URL, ACCESS_TOKEN, CLIENT_ID, TIMESERIES_LOADING, TIMESERIES_DETAILS, GET_ERRORS, 
        CLEAR_CURRENT_TIMESERIES } from './types';
import { logoutUser } from './authActions';

/*--- TimeSeries Data ---*/
export const timeSeriesData = () => dispatch => {
    dispatch(getTimeSeriesLoding());
    axios.get(`${ANALYTICS_URL}/retail/getQuterlyMonthlyWeeklySalesCurrPrevYear/${CLIENT_ID()}`, ACCESS_TOKEN())
        .then(res => {
            dispatch({
                type: TIMESERIES_DETAILS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
            if(err.response && err.response.status === 401){
                dispatch(logoutUser());
            }
        })
}

/*--- Loading Timeseries ---*/
export const getTimeSeriesLoding = () => {
    return {
        type: TIMESERIES_LOADING
    }
}

/*--- Clear Timeseries ---*/
export const clearCurrentTimeseries = () => {
    return {
        type: CLEAR_CURRENT_TIMESERIES
    }
}