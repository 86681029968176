import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { dataRepository } from '../../../redux/actions/repositoryAction';
import RequestLoadingData from '../../Loading/RequestLoadingData';
import isEmpty from '../../../validation/is-empty';
import Repos from './Repos';

class Repository extends Component {

    state = {
        setFaq: false
    }

    componentDidMount() {
        this.props.dataRepository()
    }

    toggleFAQ = () => {
        this.setState({ setFaq: !this.state.setFaq })
    }
    
    render() {
        const { loading, repository } = this.props.repository;

        let repos;
        if(loading === false && repository !== null) {
            if(isEmpty(repository) === true) {
                repos = (
                    <div className="noData">
                        No data uploaded
                    </div>
                )
            } else {
                const ob = repository.reduce( (total, currentValue) => {
                    total[currentValue.category] = currentValue
                    return total
                },{})

                const data = [ob];
                repos = (
                    data.map((faq, i) => (
                        <Repos key={i} faq={repository} index={i} toggleFAQ={this.toggleFAQ} active={this.state.setFaq} />
                    ))
                )
            }
        } else {
            repos = <RequestLoadingData />
        }
        return (
            <div className="dataRepository">
                <div className="container">
                    <div className="row mb-1 section-heading text-center">
                        <div className="col">
                            <h1>Data Repository</h1>
                        </div>
                    </div>
                    <div className="accordion" id="accordionExample">
                        {repos}
                    </div>
                </div>
            </div>
        );
    }
}

Repository.propTypes = {
    dataRepository: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    repository: state.repository
});

export default connect(mapStateToProps, { dataRepository })(Repository);