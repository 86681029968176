import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function NavLink({ to, name }) {
    return (
        <Link to={to} className="nav-link">{name}</Link>
    )
}

NavLink.propTypes = {
    to: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
}

export default NavLink