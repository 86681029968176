import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

class MonthlyCashBalance extends Component {

    componentDidMount() {
        this.monthlyCashBalance(this.props.monthlyCashBalance)
    }

    monthlyCashBalance = data => {
        var width=600,height=350;
        var margin = { top: 10, right: 20, bottom: 100, left: 40 };
        var innerwidth = width - margin.left - margin.right;
        var innerheight = height - margin.top - margin.bottom;
       
        const svg = d3.select('#monthlyCashBalance').append('svg')
        .attr('width',width)
        .attr('height',height);

        const render = data => {
          
            const yvalue =  d => d.cash_balance;
            const xvalue = d => d.mm_yyyy;

            var parse=d3.timeParse("%b-%Y")
            var format=d3.timeFormat("%b-%Y")
            var parse_balance=d3.timeParse("%b-%y")

            data["monthly_agg_balance"].forEach(function(d){
                d.mm_yyyy=parse(d.mm_yyyy)
            })

            data["balance_at_15th"].forEach(function(d){
                d.mm_yyyy=parse_balance(d.mm_yyyy)
            })
            
            var yMax=d3.max([d3.max(data.monthly_agg_balance, function(d){return d.cash_balance}), d3.max(data.balance_at_15th, function(d){return d.cash_balance})])
            var yMin=d3.min([d3.min(data.monthly_agg_balance, function(d){return d.cash_balance}), d3.min(data.balance_at_15th, function(d){return d.cash_balance})])

            

            const g = svg.append('g')
                        .attr('transform', "translate(" + margin.left + "," + margin.top + ")");

            const yscale = d3.scaleLinear()
                        .domain([yMin,yMax])
                        .range([innerheight, 0]);

            const xaxistickformat = number =>
                            d3.format('.3s')(number);

            const yaxisG = g.append('g').call(d3.axisLeft(yscale)
                        .tickFormat(xaxistickformat)
                        );

            yaxisG.selectAll('.domain .tick line')
                    .remove();

                    var color  = d3.scaleOrdinal().range(['rgba(255, 99, 132, 0.2)', 
                    'rgba(54, 162, 235, 0.2)', 
                    'rgba(255, 206, 86, 0.2)', 
                    // 'rgba(75, 192, 192, 0.2)', 
                    'rgba(153, 102, 255, 0.2)', 
                    'rgba(255, 159, 64, 0.2)',
                    'rgb(184, 245, 233)',
                    'rgb(211, 242, 184)',
                    'rgb(217, 219, 213)' ,
                    'rgb(225, 245, 166)',
                    // 'rgb(204, 229, 230)',
                    'rgb(242, 204, 255)'])

                var strokecolor=d3.scaleOrdinal().range([ 'rgba(255,99,132,1)', 
                        'rgba(54, 162, 235, 1)', 
                        'rgba(255, 206, 86, 1)', 
                        // 'rgba(75, 192, 192, 1)', 
                        'rgba(153, 102, 255, 1)', 
                        'rgba(255, 159, 64, 1 )',
                        'rgb(43, 237, 199)','rgb(63, 217, 7)','rgb(160, 161, 157)',
                        'rgb(173, 230, 7)',
                        // 'rgb(14, 234, 237)',
                        'rgb(191, 0, 255)'
                    ])


            const xscale = d3.scaleBand()
                        .domain(data["monthly_agg_balance"].map(xvalue))
                        .range([0, innerwidth]).padding(0.1);

            const xaxis = d3.axisBottom(xscale).tickFormat(format);

            const xaxisG = g.append('g').call(xaxis)
                .attr('transform', "translate(0 ," + (innerheight)+ ")")
                .selectAll("text")	
                .style("text-anchor", "end")
                .attr("dx", "-.8em")
                .attr("dy", ".15em")
                .attr("transform", "rotate(-65)")

            xaxisG.selectAll('.domain , .tick line')
                            .remove()

            xaxisG.append('text')
                .attr("class", "Yaxis-label")
                .attr('y', -170)
                .attr('x', -170)
                .attr("fill", "black")
                .attr("transform", "rotate(-65)")
                .text('Name Of Items');

            g.selectAll('rect')
            .data(data.monthly_agg_balance)
            .enter()
            .append('rect')
            .attr("y", function(d){ return yscale(d.cash_balance) })
            .attr('x', d => xscale(xvalue(d)))
            .attr('width', xscale.bandwidth())
            .attr('height', d => innerheight -  yscale(yvalue(d)))
            .attr("fill",function(d){return color(d.mm_yyyy)})
            .attr("stroke", function(d){return strokecolor(d.mm_yyyy)})

                function sortByDate(a,b){
                    return a.mm_yyyy - b.mm_yyyy;
                }

                var dataline = data["balance_at_15th"].sort(sortByDate);

            g.append('path')
            .data([dataline])
            .attr("d",d3.line()
                        .x(function(d){return xscale(d.mm_yyyy)+xscale.bandwidth()/2})
                        .y(function(d){return yscale(d.cash_balance)}))
            .attr("stroke","rgba(54, 162, 235, 1)")
            .attr("stroke-width",1)
            .attr("fill","none")

            g.selectAll('circle')
            .data(dataline)
            .enter()
            .append("circle")
            .attr("r",4)
            .attr("cx",function(d){return xscale(d.mm_yyyy)+xscale.bandwidth()/2})
            .attr("cy",function(d){return yscale(d.cash_balance)})
            .attr("fill","rgb(54, 162, 235) ")
            .attr("stroke","white")
            .attr("stroke-width",2)

            var legendedata = ["Mid-of-Month Cash Balance","Month-Wise Cash Balance"];

            var pathway="M 205 330 A 4 4 0 1 1 205.003999999333336 330.999998000000167 Z";
            //  var pathline="M 200 330 L 218 330";
            var legend = g.selectAll(".legend")
                    .data(legendedata)
                    .enter().append("g")
                    .attr("class", "legend")
                    .attr("transform", function(d, i) { return "translate(" + (width/50) + "," + (height+(margin.bottom/2)-50)+")"; });

            // legend.append("rect")
            //     .attr("x", function(d,i){ return width/8+(i*230)-20 })
            //     .attr("y", -6)
            //     .attr("width", 12)
            //     .attr("height",10)
            //     .style("fill",function(d) { return color(d); });

            legend.append("text")
                .attr("x", function(d,i){ return width/8+(i*230) })
                .attr("y", -10)
                .text(function(d){return d})
                
      
                // .attr("stroke-width","2px")
                svg.append("path")
                .attr("d",pathway)
                .attr("fill","steelblue")
                    

                var pathline = [{x:200, y:330}, {x:218, y:330}]

                var line = d3.line()
                                .x(function(d){ return d.x })
                                .y(function(d){ return d.y})

                svg.append("path")
                    .attr("d", line(pathline))
                    // .attr("fill","steelblue")
                    .attr("stroke", "steelblue")
                    .attr("stroke-width", "2px")

                svg.append("rect")
                    .attr("x", "450")
                    .attr("y", "325")
                    .attr("width", "8")
                    .attr("height", "8")
                    .attr("fill", "steelblue")

        }
        render(data)
    }

    render() {
        return (
            <div className="monthlyCashBalance" id="monthlyCashBalance"></div>
        );
    }
}

MonthlyCashBalance.propTypes = {
    monthlyCashBalance: PropTypes.object.isRequired
};

export default MonthlyCashBalance;