import React from 'react';
import Sales from '../../Resources/images/KeyQueries/sales.jpeg';
import Finance from '../../Resources/images/KeyQueries/finance.jpeg';
import Workforce from '../../Resources/images/KeyQueries/workforce.jpg';

const KeyQueries = () => {
    return (
        <section className="container-fluid key-queries">
            <div className="row mb-4 section-heading">
                <div className="col">
                    <h1>The key insights and forecast from my360.ai</h1>
                    <p className="mt-3"></p>
                </div>
            </div>
            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                <ol className="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol>
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img className="d-block w-100" src={Sales} alt="Sales"/>
                        <div className="carousel-caption d-md-block">
                            <div className="container content">
                                <h2 className="animated fadeInDown">Sales Analytics</h2>
                                <div className="d-flex justify-content-between content-animation">
                                    <div>
                                        <h5 className="animated fadeInDown">Insights</h5>
                                        <ol>
                                            <li className="animated fadeInLeft">Time Perspective</li>
                                            <li className="animated fadeInLeft">Item Perspective</li>
                                            <li className="animated fadeInLeft">ABC Analysis</li>
                                        </ol>
                                    </div>
                                    <div>
                                        <h5 className="animated fadeInDown">Forecasts</h5>
                                        <ol>
                                            <li className="animated fadeInRight">Sales Forecast</li>
                                            <li className="animated fadeInRight">Recomended Basket</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img className="d-block w-100" src={Finance} alt="Finance"/>
                        <div className="carousel-caption d-md-block">
                            <div className="container content">
                                <h2 className="animated fadeInDown text-center">Finance Analytics</h2>
                                <div className="d-flex justify-content-between content-animation">
                                    <div>
                                        <h5 className="animated fadeInDown">Insights</h5>
                                        <ol>
                                            <li className="animated fadeInLeft">Receivable Perspective</li>
                                            <li className="animated fadeInLeft">Payable Perspective</li>
                                            <li className="animated fadeInLeft">Time Perspective</li>
                                        </ol>
                                    </div>
                                    <div>
                                        <h5 className="animated fadeInDown">Forecasts</h5>
                                        <ol>
                                            <li className="animated fadeInRight">Receivable Forecast</li>
                                            <li className="animated fadeInRight">Expense Basket</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img className="d-block w-100" src={Workforce} alt="Workforce"/>
                        <div className="carousel-caption d-md-block">
                            <div className="container content">
                                <h2 className="animated fadeInDown text-center">Workforce Analytics</h2>
                                <div className="d-flex justify-content-between content-animation">
                                    <div>
                                        <h5 className="animated fadeInDown">Insights</h5>
                                        <ol>
                                            <li className="animated fadeInLeft">Department Perspective</li>
                                            <li className="animated fadeInLeft">Operation Perspective</li>
                                            <li className="animated fadeInLeft">Time Perspective</li>
                                        </ol>
                                    </div>
                                    <div>
                                        <h5 className="animated fadeInDown">Forecasts</h5>
                                        <ol>
                                            <li className="animated fadeInRight">Hygiene Forecast</li>
                                            <li className="animated fadeInRight">Churn Forecast</li>
                                            <li className="animated fadeInRight">Budget Forecast</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>
        </section>
    );
};

export default KeyQueries;