import axios from 'axios';
import setAuthToken from '../../utils/setAuthToken';
// import jwt_decode from 'jwt-decode';
import { notification } from 'antd';
import { accountRetailParticalLock, accountCashflowParticalLock } from './fileUploadActions';
import isEmpty from '../../validation/is-empty';

import { GET_ERRORS, SET_CURRENT_USER } from './types';

/*--- Register User ---*/
export const registeruser = (userData, history) => dispatch => {
    axios.post('/auth/signup', userData)
        .then(res => {
                notification.success({
                    message: 'Registration Successful',
                    description: res.data.message
                })
                console.log(res)
                dispatch(registerEmailConfirm(res.data.user, history))
            }
        )
        .catch(err => 
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            })    
        )
};

export const registerEmailConfirm = (user, history) => dispatch => {
    axios.post('/rest/email/sendmail', {email : user})
        .then(res => {
            notification.success({
                message: 'Email Sent',
                description: res.data.message
            })
            // console.log(res)
            setTimeout(function(){ history.push('/login'); }, 2000);
        })
        .catch(err => 
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            })    
        )
}

// Login - Get User Token
export const loginUser = userData => dispatch => {
    axios.post('/auth/signin', userData)
        .then(res => {
            notification.success({
                message: 'Login Success',
                description: "User Login Successfully"
            })
            // console.log(res)
            // Save to localstorage
            const token = res.data.auth_token;
            const Analytics_token = "jwt " + res.data.access_token;
            const AnalyticalAppToken = {
                auth_token : token,
                access_token : Analytics_token,
                client_id : "1"
            }
            // console.log(AnalyticalAppToken)
            // Set token to localstorage
            localStorage.setItem('AnalyticalAppToken', JSON.stringify(AnalyticalAppToken));
            // Set token to Auth Header
            setAuthToken(token);
            // Decode token to get user data
            // const decoded = jwt_decode(token);
            // Set current user
            dispatch({
                type: GET_ERRORS,
                payload: {}
            })
            dispatch(setCurrentUser(token));
        })
        .catch(err => 
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            })
        )
};

// Set logged in user
export const setCurrentUser = token => dispatch => {
    if(isEmpty(token) === true) {
        dispatch({
            type: SET_CURRENT_USER,
            payload: token
        })
    } else {
        const headers = {
            'Authorization': token
        }
        axios.get('/rest/user/userinfo', {headers})
            .then(res => {
                dispatch({
                    type: SET_CURRENT_USER,
                    payload: res.data
                })
                const { domain, client, isUploaded, filesInfo, initialLoad } = res.data;
                console.log(res.data)
                const token = JSON.parse(localStorage.getItem('AnalyticalAppToken'));
                if(isUploaded === false){
                    const clientIdChange = {
                        auth_token : token.auth_token,
                        access_token : token.access_token,
                        client_id : "1"
                    }
                    localStorage.setItem('AnalyticalAppToken', JSON.stringify(clientIdChange));
                } else if(isUploaded === true){
                    if(domain && domain.name === 'Retail') {
                        if(filesInfo.fileCount === 2 && filesInfo.partialLock === true){
                            dispatch(accountRetailParticalLock(client._id));
                        }
                    } else if(domain && domain.name === 'Cashflow Analysis') {
                        if(filesInfo.fileCount === 1 && filesInfo.partialLock === true){
                            dispatch(accountCashflowParticalLock(client._id));
                        }
                    }
                    const clientIdChange = {
                        auth_token : token.auth_token,
                        access_token : token.access_token,
                        client_id : client._id
                    }
                    localStorage.setItem('AnalyticalAppToken', JSON.stringify(clientIdChange));
                }
            })
            .catch(err => { 
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                })
            });
    }
}

/*--- Forgot Password ---*/
export const forgotPassword = (userEmail, history) => dispatch =>{
    axios.post('/auth/forgotPwd', userEmail)
        .then(res => 
            notification.success({
                message: 'Email Sent',
                description: res.data.message
            })
        )
        .catch(err => 
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            })    
        )
}

/*--- Reset Password ---*/
export const resetPassword = (passwordReset, token, history) => dispatch => {
    axios.post(`/auth/resetPwd?token=${token}`, passwordReset)
        .then(res => {
            notification.success({
                message: 'Password Reset Successful',
                description: res.data.message
            })
            setTimeout(function(){ history.push('/login'); }, 2000);
        })
        .catch(err => 
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            })    
        )
}

// Log user out
export const logoutUser = () => dispatch => {
    // Remove token from the localstorage
    localStorage.removeItem('AnalyticalAppToken');
    // Remove auth headers from future requests
    setAuthToken(false);
    // Set current user to {} which will set isAuthenticated to false
    dispatch(setCurrentUser({}));
}