import axios from 'axios';
import { ANALYTICS_URL, CLIENT_ID, ACCESS_TOKEN, CASHFLOW_FORECAST_LOADING, GET_MONTHLY_CASH_BALANCE_FORECAST, GET_WEEKLY_HIST_PRED_DATA, CLEAR_CURRENT_CASHFLOW_FORECAST } from '../actions/types';
import { logoutUser } from '../actions/authActions';

/*--- Monthly Cash Balance Forecast ---*/
export const getMonthlyCashBalanceForecast = () => dispatch => {
    dispatch(getCashflowForecastLoading());
    axios.get(`${ANALYTICS_URL}/cash_flow/monthly_cash_blance_forecast/${CLIENT_ID()}`, ACCESS_TOKEN())
        .then(res => {
            dispatch({
                type: GET_MONTHLY_CASH_BALANCE_FORECAST,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_MONTHLY_CASH_BALANCE_FORECAST,
                payload: {}
            })
            if(err.response && err.response.status === 401){
                dispatch(logoutUser());
            }
        })
}

/*--- Weekly Hist Pred Data ---*/
export const getWeeklyHistPredData = () => dispatch => {
    dispatch(getCashflowForecastLoading());
    axios.get(`${ANALYTICS_URL}/cash_flow/weekly_hist_pred_data/${CLIENT_ID()}`, ACCESS_TOKEN())
        .then(res => {
            dispatch({
                type: GET_WEEKLY_HIST_PRED_DATA,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_WEEKLY_HIST_PRED_DATA,
                payload: {}
            })
            if(err.response && err.response.status === 401){
                dispatch(logoutUser());
            }
        })
}

/*--- cashflow Forecast Loading ---*/
export const getCashflowForecastLoading = () => {
    return {
        type: CASHFLOW_FORECAST_LOADING
    }
}

/*--- Clear Cashflow Forecast ---*/
export const clearCurrentCashflowForecast = () => {
    return {
        type: CLEAR_CURRENT_CASHFLOW_FORECAST
    }
}