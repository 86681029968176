import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { notification } from 'antd';

import Layout from './Layout';

import PrivateRoute from './components/Reusable_Components/PrivateRoute';

import Login from './components/Register_Login/Login';
import Register from './components/Register_Login/Register';
import ResetPassword from './components/Register_Login/ResetPassword';

import Loading from './components/Loading/Loading';

import Welcome from './components/Welcome/Welcome';

import Insights360 from './components/Insights/360/360';
import TimeSeries from './components/Insights/Time_Series/TimeSeries';
import ItemAnalysis from './components/Insights/ItemAnalysis/ItemAnalysis';
import AbcChart from "./components/Insights/Abc/Abc";

import TimeSeriesPrediction from './components/Forecast/TimeSeriesPrediction/TimeSeriesPrediction';
import MarketBasket from './components/Forecast/Market_Basket/Market';

import HomeNonClient from './components/Domain_&_Model_Selection/HomeNonClient';
import ModelNonClient from './components/Domain_&_Model_Selection/ModelNonClient';

import Cashflow360 from './components/CashFlow/Insights/360/360';
import Expense from './components/CashFlow/Insights/Expense/Expense';
import Revenue from './components/CashFlow/Insights/Revenue/Revenue';
import CustomerAnalysis from './components/CashFlow/Insights/CustomerAnalysis/CustomerAnalysis';
import CashflowTimeSeries from './components/CashFlow/Insights/CashflowTimeSeries/CashflowTimeSeries';
import Forecast from './components/CashFlow/Forecast/Forecast';

import FileUpload from './components/Data/Upload/FileUpload';
import DataRepository from './components/Data/DataRepository/Repository';
import ClientRegistration from './components/Client_Registration/ClientRegistration';

class App extends Component {

  constructor(props) {
    super(props);
    notification.config({
      placement: 'topRight',
      top: 70,
      duration: 3,
    });
  }

  render() {
    return (
      <Switch>
        <Route exact path="/" component={Loading(Welcome)} />
        <Route path="/login" component={Loading(Login)} />
        <Route path="/register" component={Loading(Register)} />
        <Route path="/resetPassword" component={Loading(ResetPassword)} />
        <PrivateRoute path="/selectDomain" component={Loading(HomeNonClient)} />
        <PrivateRoute path="/selectModule" component={Loading(ModelNonClient)} />
        <Layout>
          {/*--------------------------- Retail --------------------------*/}
          <PrivateRoute path="/insights360" component={Loading(Insights360)}/>
          <PrivateRoute path="/timeSeries" component={Loading(TimeSeries)} />
          <PrivateRoute path="/abc" component={Loading(AbcChart)} />
          <PrivateRoute path="/itemAnalysis" component={Loading(ItemAnalysis)} />
          <PrivateRoute path="/timeSeriesPrediction" component={Loading(TimeSeriesPrediction)} />
          <PrivateRoute path="/market" component={Loading(MarketBasket)} />
          {/*-------------------------------------------------------------*/}

          {/*------------------------- CashFlow --------------------------*/}
          <PrivateRoute path="/cashflow360" component={Loading(Cashflow360)} />
          <PrivateRoute path="/expense" component={Loading(Expense)} />
          <PrivateRoute path="/revenue" component={Loading(Revenue)} />
          <PrivateRoute path="/customerAnalysis" component={Loading(CustomerAnalysis)} />
          <PrivateRoute path="/cashflowTimeSeries" component={Loading(CashflowTimeSeries)} />
          <PrivateRoute path="/cashflowForecast" component={Loading(Forecast)} />
          {/*-------------------------------------------------------------*/}

          <PrivateRoute path="/fileUpload" component={Loading(FileUpload)} />
          <PrivateRoute path="/dataRepository" component={Loading(DataRepository)} />
          <PrivateRoute path="/clientRegistration" component={Loading(ClientRegistration)} />
        </Layout>
      </Switch>
    );
  }
}

export default App;
