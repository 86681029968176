import React from 'react';
import RingLoader from 'react-spinners/RingLoader';

const RequestLoadingData = () => {
    return (
        <div className="cover-spin">
            <RingLoader
                sizeUnit={"px"}
                size={100}
                color={'#fff'}
            />
        </div>
    );
};

export default RequestLoadingData;