import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resetPassword } from '../../redux/actions/authActions';
import TextFieldGroup from '../Reusable_Components/TextFieldGroup';
import axios from 'axios';
import validateResetPasswordInput from '../Validation/Register_Login/ResetPassword';

class ResetPassword extends Component {

    constructor(){
        super();
        this.state = {
            newPassword: {
                value: '',
                valid: false
            },
            confirmPassword:{
                value: '',
                valid: false
            },
            errors: {},
            validToken : false
        }
    }

    componentDidMount(){
        const url = new URL(window.location.href);
        const token = url.searchParams.get("token");
        console.log(token);
        axios.post('/auth/checkValidity', {token : token})
            .then(res => 
                this.setState({
                    validToken: res.data.valid
                })    
            )
            .catch(err =>
                console.log(err)    
            )
    }

    componentWillReceiveProps(nextProps){
        console.log(nextProps.errors)
        if(nextProps.errors) {
            // const status = nextProps.errors.status;
            // const message = nextProps.errors.data;
            // if(status === 409 || status === 500){
            //     notification.error({
            //         message: 'Login Error',
            //         description: message
            //     });
            // }
        }
    }

    changeHandler = e => {
        this.setState({ 
            [e.target.name]: { 
                value: e.target.value, 
                valid: !!e.target.value 
            } 
        });
    };

    passwordValid = e => {
        if(e.target.name === 'newPassword' && this.state.confirmPassword.value !== e.target.value){
            this.setState({
                errors : {
                    confirmPasswordError : 'Password must match'
                }
            })
        }

        if(e.target.name === 'confirmPassword' && this.state.newPassword.value === this.state.confirmPassword.value){
            this.setState({
                errors : {
                    confirmPasswordSuccess : 'Password match success'
                }
            })
        }
    }

    onSubmit = e => {
        e.preventDefault();

        const { errors, isValid } = validateResetPasswordInput(this.state)

        if(isValid){
            const passwordReset = {
                password : this.state.newPassword.value,
                confirmPassword : this.state.confirmPassword.value
            };
            console.log(passwordReset);
            const url = new URL(window.location.href);
            const token = url.searchParams.get("token");
            console.log(token);
            this.props.resetPassword(passwordReset, token, this.props.history)
        } else {
            this.setState({ errors })
        }
    }

    render() {
        const { errors } = this.state;
        if(this.state.validToken === true){
            return (
                <div className="d-flex justify-content-center">
                    <div className="card">
                        <div className="text-center">
                            <div className="brand_ResetPassword">
                                <span className="in_brand">Reset</span>Password
                            </div>
                        </div>
                        <div className="d-flex justify-content-center form_container">
                            <form noValidate onSubmit={this.onSubmit} autoComplete="off">
                                <div className="gray-text">
                                    <TextFieldGroup 
                                        label="New Password"
                                        name="newPassword"
                                        icon="lock"
                                        type="password"
                                        value={this.state.newPassword.value}
                                        onChange={this.changeHandler}
                                        onKeyUp={this.passwordValid}
                                        error={errors.newPassword}
                                    />
                                    <TextFieldGroup 
                                        label="Confirm Password"
                                        name="confirmPassword"
                                        icon="key"
                                        type="password"
                                        value={this.state.confirmPassword.value}
                                        onChange={this.changeHandler}
                                        onKeyUp={this.passwordValid}
                                        error={errors.confirmPasswordError || errors.confirmPassword}
                                        success={errors.confirmPasswordSuccess}
                                    />
                                </div>
                                <button className="btn blue-gradient btn-rounded btn-block my-2 waves-effect" type="submit">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="d-flex justify-content-center h4">
                    Token Expired
                </div>
            )
        }
    }
}

ResetPassword.propTypes = {
    resetPassword: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
})

export default connect(mapStateToProps, { resetPassword })(withRouter(ResetPassword));