import React,{Component} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MarketChart from './MarketChart';

class MarketBasket extends Component{
    render(){
        return(
            <div className="mbk-analysis">
                <MarketChart />
            </div>
        )
    }


}

MarketBasket.propTypes = {
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(MarketBasket);