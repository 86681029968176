import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { chatBot } from '../../redux/actions/chatBotActions';
import { ANALYTICS_URL,TOKEN_ACCESS } from '../../redux/actions/types';

class ChatBots extends Component {
    
    state = {
        chatBtn: true,
        message: ''
    }

    changeHandler = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    chatBtn = () => {
        const { auth } = this.props;
        const userDetails = {
            sender: auth.user.client && auth.user.client._id,
            message: "start conversation",
            metadata: {
                url: ANALYTICS_URL,
                token: TOKEN_ACCESS(),
                username: auth.user.firstName,
                client_id: auth.user.client && auth.user.client.isActive === true && auth.user.initialLoad === true ? auth.user.client.client._id : "1"
            }
        }
        if(this.props.chats.chatBot.length === 0){
            this.props.chatBot(userDetails, {stage: 'initial'})
        } 
        
        this.setState({
            chatBtn: !this.state.chatBtn
        })
    }

    onSubmit = e => {
        e.preventDefault();

        const { client } = this.props.auth.user;
        const chatMessage = {
            sender: client._id,
            message: this.state.message
        }
        this.props.chatBot(chatMessage, {stage: 'chat'});
        this.setState({ message: '' })
    }

    render() {
        const { auth, chats } = this.props;
        return (
            <div className="chatBot">
                <div 
                    className="chat-messages"
                    style={{
                        display: this.state.chatBtn ? 'none' : 'block'
                    }}
                >
                    <div className="card">
                        <div className="card-header d-flex justify-content-between mb-0">
                            <h5 className="card-title">Welcome { auth.user.firstName }</h5>
                            <button 
                                className="close" 
                                onClick={this.chatBtn}
                            >
                                <i className="fas fa-times"></i>
                            </button>
                        </div>
                        <div className="card-body overflowAuto">
                            {
                                chats.chatBot.map((a,i) => (
                                    <div className={`${a.sender}`} key={i}>
                                        <p className="card-text">{a.message}</p>
                                    </div>
                                ))
                            }
                            {/* <h5 className="card-title">Name</h5> */}
                        </div>
                        <div className="card-footer">
                            <form noValidate className="d-flex bd-highlight" onSubmit={this.onSubmit} autoComplete="off">
                                <input 
                                    type="text"
                                    className="p-2 w-100 bd-highlight"
                                    name="message"
                                    placeholder="Type your message..."
                                    onChange={this.changeHandler} 
                                    value={this.state.message}
                                />
                                <button type="submit" className="p-2 flex-shrink-1 bd-highlight"><i className="fas fa-paper-plane"></i></button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="chatBot-Button">
                    <button 
                        className="chat-bgcolor"
                        name="chatBtn"
                        onClick={this.chatBtn}
                        style={{
                            display: this.state.chatBtn ? 'block' : 'none'
                        }}
                    >
                        <i className="fas fa-comment-alt"></i>
                    </button>
                </div>
            </div>
        );
    }
}

ChatBots.propTypes = {
    chatBot: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    chats: state.chatBot
})

export default connect(mapStateToProps, { chatBot })(ChatBots);