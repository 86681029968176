import React from 'react';
import Register from './Register';
import LineAreaChart from '../../Resources/images/Welcome/LineAreaChart.png';
import AnalyticPlatformMy360 from '../../Resources/images/Welcome/AnalyticPlatformMy360.png';
import WordCloudChart from '../../Resources/images/Welcome/WordCloudChart.png';
import AreaChart from '../../Resources/images/Welcome/AreaChart.png';

export const NeedOfAAP = () => (
  <section className="container-fluid homepage need-of-app md-blue curve">
    <div className="container">
        <div className="row">
          {/* <div className="col-12"> */}
            <div className="col-sm-6 pl-0">
              <div className="left-top-section">
                <div className="content">
                  <img src={AreaChart} alt="AreaChart" />
                </div>
              </div>
              <div className="left-bottom-section">
                <div className="content">
                  <img src={LineAreaChart} alt="BarChart" />
                </div>
              </div>
            </div>
            <div className="col-md-6 align-self-center pl-5">
              <div className="row section-heading text-left">
                  <div className="col">
                      <h1 className="mb-0">Mission of 360</h1>
                  </div>
              </div> 
              <h6 className="d-block text-white">
                <ul>
                  <li>Show Patterns and Trends in no time.</li>
                  <li>Use Machine Learning (ML) to know the Unknown ... to begin with.</li>
                </ul>
                <span className="d-block">

                </span>
              </h6>
            </div>
          {/* </div> */}

        </div>
    </div>
  </section>
)

export const NeedOfAAP2 = () => (
  <section className="container-fluid homepage need-of-app two md-blue curve mt-3">
    <div className="container">
        <div className="row">
          {/* <div className="col-12"> */}
          <div className="col-md-6 align-self-center pl-0">
            <div className="row section-heading text-left">
                <div className="col">
                    <h1 className="mb-0">Why My360 Analytical Platform</h1>
                </div>
            </div>  
            <div>
              <h6 className="d-block text-white">
                <ul>
                  <li>Building a standard BI platform is time consuming to begin with.</li>
                  <li>With our expertise in Data Management and Analytics skills, we standardized Analytics based on Roles.</li>
                  <li>Mission is to achieve personalized Analytical platform to any role.</li> 
                </ul>
              </h6>
              <span className="d-block">
              </span>
            </div>
          </div>
            <div className="col-sm-6 pl-5">
              <div className="left-top-section">
                <div className="content">
                  <img src={WordCloudChart} alt="WordCloudChart" />
                </div>
              </div>
              <div className="left-bottom-section">
                <div className="content">
                  <img src={AnalyticPlatformMy360} alt="AnalyticPlatformMy360" />
                </div>
              </div>
            </div>
            
          {/* </div> */}

        </div>
    </div>
  </section>
)

// //what clients are saying
// export const ClientsReviw = () => (
//   <section className="container-fluid homepage clients-review">
//     <div className="container">
//       <div className="review-ctnr">
//         <h2 className="text-center mb-5"> What clients are saying</h2>
//         <div className="row">
//           <div className="col-sm-6">
//             <div className="info ct float-lg-right">
//               <div className="client-img">

//               </div>
//             </div>
//           </div>
//           <div className="col-sm-6">
//             <div className="info">
//             <div className="review-count">
//             </div>
//             <div className="review-content">
//               <p>
//                 "I just wanted to share a quick note and let you know that you guys do a really good job.
//                 I'm glad I decided to work with you. It's really great how easy your websites are to update andmanage
//               </p>
//               <p>
//                 My new site is so faster and easier to work with than my old"
//               </p>
//             </div>
//             <div className="review-author">
//                 <span className="d-block">Aroha Tech</span>
//                 <span className="d-block"> Aroha</span>
//             </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </section>
// )

//Footer section
export const Footer = () => (
  <section className="container-fluid homepage footer-section sd-blue">
    <div className="container">
    <h2 className="header-subtitle text-center">
      Get better work done
    </h2>
    <div className="description text-center">
      <span className="d-block">
        See why People are using Aroha Analytical Platform
      </span>
    </div>
    <Register />
    </div>
  </section>
)