import React,{Component} from 'react';
import PieChart from './PieChart';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { getSalesAbcCatData, getSalesAndProfitSummary, getCatTable } from '../../../redux/actions/abcActions';
import RequestLoadingData from '../../Loading/RequestLoadingData';
import isEmpty from '../../../validation/is-empty';

class AbcGraphs extends Component{

    componentDidMount(){
        this.props.getSalesAbcCatData();
        this.props.getSalesAndProfitSummary();
        this.props.getCatTable();
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTable: {
                responsiveScroll: {
                    height: '280px'
                }
            }
        }
    })

    render(){
        const { loading, abcCatData, abcSalesProfitSummary, abcCatTable } = this.props.abcAnalysis;

        let abcGraphs;

        if(abcCatData !== null && abcSalesProfitSummary !== null && abcCatTable !== null){
            if(isEmpty(abcCatData) === true || isEmpty(abcSalesProfitSummary) === true || isEmpty(abcCatTable) === true){
                abcGraphs = (
                    <div>
                        <p className="lead text-muted">Sorry</p>
                        <p>Something went wrong please try again after some time</p>
                    </div>
                );
            } else {
                const salesProfitSummaryColumns = [
                    {
                        name: "Categories",
                        label: "Categories",
                        options: {
                            filter: true,
                            sort: true,
                        }
                    },
                    {
                        name: "Profit",
                        label: "Profit",
                        options: {
                            filter: true,
                            sort: true,
                        }
                    },
                    {
                        name: "Sales",
                        label: "Sales",
                        options: {
                            filter: true,
                            sort: true,
                        }
                    },
                    {
                        name: "percet",
                        label: "Percentage",
                        options: {
                            filter: true,
                            sort: true,
                        }
                    }
                ];

                const catTableColumns = [
                    {
                        name: "Item_name",
                        label: "Item Name",
                        options: {
                            filter: true,
                            sort: true,
                        }
                    },
                    {
                        name: "totalsales",
                        label: "Total Sales",
                        options: {
                            filter: true,
                            sort: true,
                        }
                    },
                    {
                        name: "ABC_category_sales",
                        label: "Category Sales",
                        options: {
                            filter: true,
                            sort: true,
                        }
                    },
                    {
                        name: "profit",
                        label: "Profit",
                        options: {
                            filter: true,
                            sort: true,
                        }
                    },
                    {
                        name: "ABC_category_profit",
                        label: "Category Profit",
                        options: {
                            filter: true,
                            sort: true,
                        }
                    }
                ];

                const options = {
                    filter: true,
                    filterType: 'dropdown',
                    responsive: 'scroll',
                    selectableRows: false 
                };
                // console.log(abcCatData)
                // console.log(abcSalesProfitSummary)
                // console.log(abcCatTable)
                abcGraphs = (
                    <div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="card abc-card">
                                    <div className="card-graph">
                                        <PieChart pieChart={abcCatData}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="category-table">
                                    <MuiThemeProvider theme={this.getMuiTheme()}>
                                        <MUIDataTable
                                            title="Categories Description"
                                            data={abcSalesProfitSummary}
                                            columns={salesProfitSummaryColumns}
                                            options={options}
                                        />
                                    </MuiThemeProvider>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="analysis-table">
                                    <MuiThemeProvider theme={this.getMuiTheme()}>
                                        <MUIDataTable
                                            title="ABC Analysis Table"
                                            data={abcCatTable}
                                            columns={catTableColumns}
                                            options={options}
                                        />
                                    </MuiThemeProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        } else {
            abcGraphs = <RequestLoadingData />
        }

        return (
            <div className="container-fluid">
                {abcGraphs}
            </div>
        )
    }
}

AbcGraphs.propTypes = {
    getSalesAbcCatData: PropTypes.func.isRequired,
    getSalesAndProfitSummary: PropTypes.func.isRequired,
    getCatTable: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    abcAnalysis: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
    abcAnalysis: state.abcAnalysis
});

export default connect(mapStateToProps, { getSalesAbcCatData, getSalesAndProfitSummary, getCatTable })(AbcGraphs);