import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import rootReducer from './redux/reducers';

const initialState = {};

const middleware = [thunk];

export const store = createStore(
    rootReducer, 
    initialState,
    process.env.NODE_ENV === 'production'?
        compose(
            applyMiddleware(...middleware)
        )
    :
        compose(
            applyMiddleware(...middleware),
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        )
);

export const persistor = persistStore(store)

export default { store, persistor};