import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import { Layout, Menu, Icon } from 'antd';
const { Sider } = Layout;

class SidebarCustomerList extends Component {

    state = {
        change: ['0'],
        collapsed: false,
        marketDropdown: null,
        marketDropdownIcons: ['login', 'rollback', 'enter', 'radius-upright', 'plus', 'exclamation', 'retweet', 'swap', 'logout', 'stop', 'fall', 'rise', 'stock', 'sliders', 'cloud', 'customer-service', 'dashboard', 'fire', 'star', 'tags'],
        marketDropdownKeys: [],
        marketDropdownValue: []
    }

    componentDidMount(){
        const dropDown = this.props.getCustomerMonthlyRevenue;
        const key = Object.keys(dropDown);
        const value = Object.values(dropDown);
        const dropdownLength = key.length;
        const icon = this.state.marketDropdownIcons.length;
        const result = Math.round(dropdownLength / icon);
        this.setState({
            marketDropdownIcons: this.makeRepeated(this.state.marketDropdownIcons, result),
            marketDropdown: dropDown,
            marketDropdownKeys: [...key],
            marketDropdownValue: [...value]
        })
    }

    // toggle = () => {
    //     this.setState({
    //       collapsed: !this.state.collapsed,
    //     });
    // };

    sidenav = () => (
        this.state.marketDropdownKeys.map((a, i) => (
            <Menu.Item key={this.state.marketDropdownKeys[i]}>
                {/* <Icon type={this.state.marketDropdownIcons[i]} /> */}
                <span>{this.state.marketDropdownValue[i]}</span>
            </Menu.Item>
        ))
    )
    
    makeRepeated = (arr, repeats) => [].concat(...Array.from({ length: repeats }, () => arr));

    change = (value) => {
        if(this.state.change.length > 2) {
            notification.info({
                message: "Customer's Comparison",
                description: "You can only compare 3 Customer's Maximum"
            })
        } else {
            this.setState({
                change: [...this.state.change, value.key]
            }, () => {
                const data = [...this.state.change];
                this.props.change(data)
            })
        }
    }

    onDeselect = (value) => {
        console.log(value.key)    
        var array = [...this.state.change];
        var index = array.indexOf(value.key)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({
                change: array
            }, () => {
                this.props.change([...this.state.change])
            });
        }
    }

    render() {
        return (
            <Sider 
                className={this.state.collapsed ? 'customer-sidebar' : 'customer-sidebar customer-sidebar-scroll'}
                trigger={null} 
                collapsible 
                collapsed={this.state.collapsed}
            >
                <div className="customer-sidebar-Toggle customer-List">
                    {/* <Icon
                        className={this.state.collapsed ? 'trigger menu-unfold' : 'trigger menu-fold'}
                        type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                        onClick={this.toggle}
                    /> */}
                    Customer's List
                </div>
                <Menu 
                    theme="dark" 
                    mode="inline" 
                    multiple={true}
                    selectedKeys={this.state.change}
                    defaultSelectedKeys={['0']} 
                    onSelect={this.change} 
                    onDeselect={this.onDeselect}
                >
                    {this.sidenav()}
                </Menu>
            </Sider>
        );
    }
}

SidebarCustomerList.propTypes = {
    getCustomerMonthlyRevenue: PropTypes.object.isRequired
};

export default SidebarCustomerList;