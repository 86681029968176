import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

class PieChart extends Component {

    componentDidMount() {
        console.log(this.props.update)
        console.log(this.props.monthlyTopk)
        this.pieChart(this.props.update, this.props.monthlyTopk, this.props.type, this.props.path)
    }

    pieChart = (d, data, type, path) => {
        // console.log(i, d.total)

            //Updated pie chart
            console.log("MAI", d)
            var format=d3.timeFormat("%b-%Y")
                
            // set the dimensions and margins of the graph
            var piewidth = 600,
                pieheight = 350,
                piemargin = 50

            // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
            var radius = Math.min(piewidth, pieheight) / 2 - piemargin

            // append the svg object to the div called 'my_dataviz'
            var svg = d3.select("#pie")
            .append("svg")
                .attr("width", piewidth)
                .attr("height", pieheight)
            .append("g")
                .attr("transform", "translate(" + (piewidth/3) + "," + (pieheight/2 + 20) + ")");

           

        //    var default_date = Object.keys(data["data"][0])
        //     console.log("default", default_date)
        //    var piedata = data["data"][0][default_date]
        //    console.log("pie data", piedata)
        var default_date = d.date;
        var piedata

        for(var i=0; i<data["data"].length;i++){
            var da = Object.keys(data["data"][i])
            // console.log("da", format(d.date))
            if(format(d.date) ===  da[0]){
                console.log("true")
                console.log("step", data["data"][i])
                console.log("default", default_date)
                piedata = data["data"][i][format(default_date)]
            }
        }
        console.log("piedata", piedata)
        var total=0;
        piedata.forEach(function(d){
            total+=d[`${type}`];
        })
        console.log("to", total)

            // set the color scale
            var color = d3.scaleOrdinal()
            .range(["#F1948A", "#F5B041", "#82E0AA", "#F4D03F", "#98abc5", "#8a89a6",
                        "#7b6888", "#6b486b",
                        "#a05d56", "#d0743c",
                        "#ff8c00"]);;

            // Compute the position of each group on the pie:
            var pie = d3.pie()
            .value(function(d) {return d[`${type}`]; })
            var data_ready = pie(piedata)
            // Now I know that group A goes from 0 degrees to x degrees and so on.

            // shape helper to build arcs:
            var arcGenerator = d3.arc()
            .innerRadius(0)
            .outerRadius(radius)

            redraw(data_ready)

            // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
            function redraw(data_ready){
                console.log("ready_data", data_ready)

                var arcs = svg.selectAll('mySlices')
                    .data(data_ready)

                    // arcs.transition()
                    //     .duration(1500)
                    //     .attrTween("d", arcTween)


                    .enter()
                    .append('path')
                    .attr('d', arcGenerator)
                    .attr('fill', function(d){ return(color(d.data[`${path}`])) })
                    // .attr("fill", "red")
                    .attr("stroke", "white")
                    .style("stroke-width", "2px")
                    .style("opacity", 0.7)
            }

            var outerArc=d3.arc()
            .innerRadius(radius * .9)
            .outerRadius(radius * .9);            

            // Now add the annotation. Use the centroid method to get the best coordinates
            svg.selectAll('mySlices')
            .data(data_ready)
            .enter()
            .append('text')
            .text(function(d){ return  ((d.data[`${type}`]/total)*100).toFixed(2)+"%"})
            .attr("transform", function(d) { return "translate(" + arcGenerator.centroid(d) + ")";  })
            .style("text-anchor", "middle")
            .style("font-size", 13)

            //var z = d3.scaleOrdinal().range(["#66ff66", "#99ff99", "#00e600"]);

            var legend = svg.selectAll(".legend")
              .data(piedata)
              .enter().append("g")
              .attr("class", "legend")
              //.attr("transform", function(d, i) { return "translate(0," + i * 20 + ")"; });
        
            legend.append("rect")
                .attr("y", function(d,i){return ((-pieheight)/4+(i*20))})
                .attr("x", 140)
                .attr("width", 10)
                .attr("height", 15)
                .style("fill",function(d) { return color(d[`${path}`]); });
        
            legend.append("text")
                .attr("y", function(d,i){return ((-pieheight)/4+(i*20)+7)})
                .attr("x", 155)
                .attr("dy", ".35em")
                .style("font-size", "12px")
                .style("text-anchor", "start")
                .text(function(d) { return d[`${path}`]+"("+d[`${type}`]+")"; });


            // function arcTween(a) {
            //     console.log(this._current);
            //     var i = d3.interpolate(this._current, a);
            //     this._current = i(0);
            //     return function(t) {
            //         return arc(i(t));
            //     };
            // }
    }
    render() {
        return (
            <div className="pie" id="pie"></div>
        );
    }
}

PieChart.propTypes = {
    update: PropTypes.object.isRequired,
    monthlyTopk: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired
};

export default PieChart;