import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom';
import Slides from '../Reusable_Components/Slider';
import { Step1, Step2, Step3, Step4, Step5 } from './Steps';

class AboutAAP extends Component {

    state = {
        steps: ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Step 5']
    }

    tabSteps = (
        this.state.steps.map((a, i) => (
            <Tab className="nav-item" key={i}>
                <Link to="#" className="nav-link"> 
                    {a}
                </Link>
            </Tab>
        ))
    )

    render() {
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            speed: 500,
            autoplaySpeed: 2000,
            pauseOnHover: true
        };
        return (
            <section className="container-fluid homepage about-aap">
                <div className="container text-left pb-0">
                    <div className="sub-section">
                        <div className="row mb-1 section-heading text-left">
                            <div className="col">
                                <h1>Steps to see your insights and Forecasts</h1>
                            </div>
                        </div>
                        <div className="tabs-panel">
                            <Tabs defaultIndex={0}>
                                <TabList className="nav">
                                    {this.tabSteps}
                                </TabList>
                                <TabPanel>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h3>
                                                <span className="d-block">Register and Login</span>
                                            </h3>
                                        </div>
                                        <div className="col-md-6 px-2">
                                            <Slides 
                                                data={Step1}
                                                style={{
                                                    height: "250px",
                                                    width: "100%",
                                                    objectFit: "cover"
                                                }}
                                            />
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h3>
                                                <span className="d-block">Pick the Domain & Module you are intersted in. <br /> See sample visuals and forecasts (industry standard)</span>
                                            </h3>
                                        </div>
                                        <div className="col-md-6 px-2">
                                            <Slides 
                                                data={Step2}
                                                style={{
                                                    height: "250px",
                                                    width: "100%",
                                                    objectFit: "cover"
                                                }}
                                            />
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h3>
                                                <span className="d-block">Register your company as client <br /> Download standard data templates based on your module</span>
                                            </h3>
                                        </div>
                                        <div className="col-md-6 px-2">
                                            <Slides 
                                                data={Step3}
                                                style={{
                                                    height: "250px",
                                                    width: "100%",
                                                    objectFit: "cover"
                                                }}
                                            />
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h3> 
                                                <span className="d-block">Upload Data to my360 platform.</span>
                                            </h3>
                                        </div>
                                        <div className="col-md-6 px-2">
                                            <Slides 
                                                data={Step4}
                                                style={{
                                                    height: "250px",
                                                    width: "100%",
                                                    objectFit: "cover"
                                                }}
                                            />
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h3>
                                                <span className="d-block">See your DATA insights and Forecasts.</span>
                                            </h3>
                                        </div>
                                        <div className="col-md-6 px-2 pb-5">
                                            <Slides 
                                                data={Step5}
                                                style={{
                                                    height: "250px",
                                                    width: "100%",
                                                    objectFit: "cover"
                                                }}
                                            />
                                        </div>
                                    </div>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default AboutAAP;