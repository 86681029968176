import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {

    state={
        email: ''
    }

    changeHandler = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit = e => {
        e.preventDefault();

        console.log(this.state.email)
        this.setState({ email: '' })
    }
    render() {
        return (
            <section className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="foo_title_heading">My<span className="foo_title">360</span></div>
                            <div className="foo_content">
                                <div className="foo_details">
                                    <div className="foo_heading">Have a Questions ?</div>
                                    <div className="foo_contact_details">
                                        <i className="fas fa-map-marker-alt"></i>
                                        <div className="nfo">
                                            Aroha #70, 2nd floor, 45th Cross Rd, 8th Block, Jayanagar, Bengaluru, Karnataka - 560082
                                        </div>
                                    </div>
                                    <div className="foo_contact_details">
                                        <i className="fas fa-phone-alt"></i>
                                        <div className="nfo">
                                            +91 8042068256
                                        </div>
                                    </div>
                                    <div className="foo_contact_details">
                                        <i className="far fa-envelope"></i>
                                        <div className="nfo">
                                            chandra@aroha.co.in
                                        </div>
                                    </div>
                                    <div className="foo_contact_social">
                                        <div className="foo_heading">Social Network</div>
                                        <ul className="foo_social_icons">
                                            <li><a href="https://www.aroha.co.in" rel="noopener noreferrer" target="_blank"><i className="fas fa-globe"></i></a></li>
                                            <li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-google-plus-g"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-linkedin-in"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="foo_heading">Links</div>
                            <div className="foo_content">
                                <ul>
                                    <li><Link to="#">Home</Link></li>
                                    <li><Link to="#">About us</Link></li>
                                    <li><Link to="#">Teams</Link></li>
                                    <li><Link to="#">Privacy Policy</Link></li>
                                    <li><Link to="#">Terms & Conditions</Link></li>
                                </ul> 
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="foo_heading">Opening Hours</div>
                            <div className="foo_content">
                                <div className="foo_timings">
                                    <i className="fas fa-clock"></i> 
                                    <div className="foo_timingHrs">
                                        Mon - Fri : 9:30AM to 6:00PM
                                    </div>
                                </div>
                            </div>
                            <div className="foo_content">
                                <div className="foo_heading">Subscribe Us</div>
                                <form className="subscribe" onSubmit={this.onSubmit}>
                                    <input 
                                        type="email" 
                                        name="email" 
                                        placeholder="Enter email address" 
                                        onChange={this.changeHandler} 
                                        value={this.state.email}
                                    />
                                    <input type="submit" value="Subscribe" />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="foo_copyrights text-center">
                    Copyright &copy; 2019 My360. All rights reserved
                    <div className="foo_powered_by text-capitalize">
                        Powered by Aroha
                    </div>
                </div>
            </section>
        );
    }
}

export default Footer;