import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { notification } from 'antd';
import ImageSlide from './ImageSlide';
import { MDBIcon } from 'mdbreact';
import TextFieldGroup from '../Reusable_Components/TextFieldGroup';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser, forgotPassword } from '../../redux/actions/authActions';
import profile_logo from '../../Resources/images/Login_Page/profile_logo.png';
import Logo from '../../Resources/images/Logo/Z9BhAe.png';
import isEmpty from '../../validation/is-empty';
import RequestLoadingData from './../Loading/RequestLoadingData';
import validateLoginInput from '../Validation/Register_Login/Login';

class Login extends Component {

    constructor(){
        super();
        this.state = {
            email: {
                value: '',
                valid: false
            },
            password: {
                value: '',
                valid: false
            },
            errors: {},
            forgotPassword: false,
            loading : false
        }
    }

    componentDidMount() {
        if(this.props.auth.isAuthenticated) {
            const { domain } = this.props.auth.user;
            if(isEmpty(domain) === true){
                this.props.history.push('/selectDomain')
            } else if (domain && domain.name === 'Retail') {
                this.props.history.push('/insights360')
            } else if (domain && domain.name === 'Cashflow Analysis') {
                this.props.history.push('/cashflow360')
            }
        }

        const paramsString = this.props.location.search;
        const params = new URLSearchParams(paramsString);
        const tags = params.get('email');
        if(tags){
            this.setState({
                email: {
                    value: tags,
                    valid: true
                }
            })
        }
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            loading: false
        })
        if(nextProps.auth.isAuthenticated) {
            const { domain } = nextProps.auth.user;
            const { client } = nextProps.auth.user;
            const { filesInfo } = nextProps.auth.user;
            if(isEmpty(domain) === true){
                this.props.history.push('/selectDomain')
            } else if (client && client.isActive === true && nextProps.auth.user.initialLoad === false) {
                this.props.history.push('/fileUpload')
                notification.info({
                    message: 'Complete File Uploading',
                    description: 'You have pending in file uploading step to see your Visuals'
                });
            } else if (domain && domain.name === 'Retail') {
                if (filesInfo && filesInfo.fileCount > 0){
                    this.props.history.push('/fileUpload')
                    notification.info({
                        message: 'Complete File Uploading',
                        description: 'You have uploaded one file upload another file to see your Visuals'
                    });
                } else {
                    this.props.history.push('/insights360')
                }
            } else if (domain && domain.name === 'Cashflow Analysis') {
                this.props.history.push('/cashflow360')
            }
        }
        
        if(isEmpty(nextProps.errors) === false) {
            const status = nextProps.errors.status;
            const message = nextProps.errors.data.message;
            if(status === 409 || status === 500){
                notification.error({
                    message: 'Login Error',
                    description: message
                });
            }
        }
    }

    changeHandler = e => {
        this.setState({ 
            [e.target.name]: { 
                value: e.target.value, 
                valid: !!e.target.value 
            } 
        });
    };

    forgotPassword = e => {
        if(e.target.name === 'forgotPassword'){
            this.setState({
                forgotPassword : true
            })
        } else if(e.target.name === 'login'){
            this.setState({
                forgotPassword: false
            })
        }
    }

    forgotPasswordValidate = () => {
        let isError = false;

        const errors = {
            errors: {}
        }

        if(!this.state.email.value.includes('@')) {
            isError = true;
            errors.errors.email = 'Email is invalid';
        }

        if(this.state.email.value === '') {
            isError = true;
            errors.errors.email = 'Email field is required';
        }

        this.setState({
        ...this.state,
        ...errors
        });

        return isError;
    }

    onSubmit = e => {
        e.preventDefault();

        const { errors, isValid } = validateLoginInput(this.state)
        
        if(isValid){
            const userData = {
                email : this.state.email.value,
                password : this.state.password.value
            };
            // console.log(userData);
            this.props.loginUser(userData);
            this.setState({
                loading: true
            })
        } else {
            this.setState({ errors })
        }
    }

    onForgotPassword = e => {
        e.preventDefault();

        const forgotErr = this.forgotPasswordValidate();

        if(!forgotErr){
            const userEmail = {
                email : this.state.email.value
            }
            // console.log(userEmail);
            this.props.forgotPassword(userEmail, this.props.history)
        }
    }

    render() {
        const { errors } = this.state;

        return (
            <div className="container-fluid reg_log">
                { this.state.loading ? <RequestLoadingData /> : null }
                <div className="row login-container">
                    <div className="col-md-6 login_form_image">
                        <ImageSlide />
                    </div>
                    <div className="col-md-6 login-form">
                        <div className="text-center logo">
                            <Link to="/">
                                <img src={Logo} alt="Logo" style={{height: '40px'}} />
                            </Link>
                            <div className="login_heading">
                                Join Analytics Platform!
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            {
                                !this.state.forgotPassword ?
                                    <div className="user_card">
                                        <div className="d-flex justify-content-center">
                                            <div className="brand_logo_container">
                                                <img src={profile_logo} className="brand_logo" alt="Profile_Photo" />
                                            </div>
                                            <div className="brand_signin">
                                                <span className="in_brand">Sign</span>In
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center form_container">
                                            <form noValidate onSubmit={this.onSubmit} autoComplete="off">
                                                <div className="grey-text">
                                                    <TextFieldGroup 
                                                        label="Email Address"
                                                        icon="envelope"
                                                        name="email"
                                                        type="text"
                                                        value={this.state.email.value}
                                                        onChange={this.changeHandler}
                                                        error={errors.email}
                                                    />
                                                    <TextFieldGroup 
                                                        label="Password"
                                                        name="password"
                                                        icon="lock"
                                                        type="password"
                                                        value={this.state.password.value}
                                                        onChange={this.changeHandler}
                                                        error={errors.password}
                                                    />
                                                </div>
                                                <div className="d-flex justify-content-around">
                                                    <div className="checkbox mr-5">
                                                        <label className="checkbox-label">
                                                            <input type="checkbox" value="" />
                                                            <span className="cr"><i className="cr-icon fa fa-check"></i></span>
                                                            Remember Me
                                                        </label>
                                                    </div>
                                                    <Link to="#" name="forgotPassword" onClick={this.forgotPassword}>Forgot password?</Link>
                                                </div>
                                                <button className="btn blue-gradient btn-rounded btn-block my-2 waves-effect" type="submit">Sign in</button>
                                            </form>
                                        </div>
                                        <div className="d-flex justify-content-center links">
                                            Don't have an account? <Link className="ml-2" to="/register">Sign Up</Link>
                                        </div>
                                        {/* <p className="font-small dark-grey-text text-right d-flex justify-content-center pt-2">
                                            or Sign in with :
                                        </p>
                                        <div className="d-flex justify-content-center social_icon">
                                            <ul className="d-flex">
                                                <li><Link to="#"><i className="fab fa-linkedin-in" aria-hidden="true"></i></Link></li>
                                                <li><Link to="#"><i className="fab fa-instagram" aria-hidden="true"></i></Link></li>
                                            </ul>
                                        </div> */}
                                    </div>
                                : this.state.forgotPassword ?
                                    <div className="user_card">
                                        <div className="d-flex justify-content-center">
                                            <div className="brand_forgotPassword">
                                                <div className="text-center">
                                                    <h3><i className="fa fa-lock fa-4x"></i></h3>
                                                    <span className="in_brand">Forgot</span>Password ?
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <form noValidate onSubmit={this.onForgotPassword} autoComplete="off">
                                                <div className="grey-text">
                                                    <TextFieldGroup 
                                                        label="Email Address"
                                                        name="email"
                                                        icon="envelope"
                                                        type="text"
                                                        value={this.state.email.value}
                                                        onChange={this.changeHandler}
                                                        error={errors.email}
                                                    />
                                                    <button className="btn blue-gradient btn-rounded btn-block my-2 waves-effect" type="submit">Send Mail <MDBIcon far icon="paper-plane" size="lg" /></button>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            Have an account? <Link className="ml-2" to="#" name="login" onClick={this.forgotPassword}>Login</Link>
                                        </div>
                                    </div>
                                :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
})

const mapDispatchToProps = { loginUser, forgotPassword }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));