import React, { Component } from 'react'
import * as d3 from 'd3'
import $ from 'jquery'

class BarChart extends Component {

    shouldComponentUpdate(nextProps) {
        // console.log(nextProps)
        const { barChartData, filter, hide} = nextProps
        console.log(barChartData)
        console.log(filter)
        console.log(hide)
        this.barChart(barChartData, filter, hide)
        return true
    }

    componentDidMount() {
        const { barChartData, filter, hide} = this.props
        console.log(barChartData)
        console.log(filter)
        console.log(hide)
        this.barChart(barChartData, filter, hide)
    }
    
    barChart = (data, type, hide) => {

        $("#barChart").empty()
        if(hide) return;

        console.log(data)
       
        var margin = { top: 30, bottom: 20, left: 80, right: 5 },
            width = 500 - margin.left - margin.right,
            height = 250 - margin.top - margin.bottom;
           
        var svg = d3.select("#barChart")
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .attr("id", "barChartPlot");

        var g = svg.append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");


        var xScale = d3.scaleBand().range([0, width]);
        var yScale = d3.scaleLinear().range([height, 0]);

        var xAxisG = g.append("g").attr("transform", "translate(0, " + height + ")")
        var yAxsiG = g.append("g")

        var xaxis = d3.axisBottom(xScale);
        var yaxis = d3.axisLeft(yScale).ticks(5);

        xScale.domain(data.map(function (d) { 
            return type === 'weekly' ? d.week : d.month
         }))
        // xScale.domain(this.getDomain(data, type))

        yScale.domain([0, d3.max(data, function (d) { 
            return type === 'weekly' ? d.weekly_sales : d.monthly_sales
         })])

        var plot = g.selectAll("rect")
            .data(data)

        plot.exit().remove();

        plot.attr('width', xScale.bandwidth)
            .attr('height', function (d) {
                return height - yScale( type === 'weekly' ? d.weekly_sales : d.monthly_sales) 
            })
            .attr('x', function (d) {
                return type === 'weekly' ? xScale(d.week)+2 : xScale(d.month) + 2 
            })
            .attr("y", function (d) { 
                return type === 'weekly' ? yScale(d.weekly_sales) : yScale(d.monthly_sales) 
            })
            .attr("stroke", "white")
            .attr("stroke-width", "2px")

        plot.enter()
            .append("rect")
            .attr("x", function (d) { 
                return type === 'weekly' ? xScale(d.week) + 2 : xScale(d.month) + 2 
            })
            .attr("width", xScale.bandwidth)
            .attr("y", function (d) {
                return type === 'weekly' ? yScale(d.weekly_sales) + 2 :yScale(d.monthly_sales);
            })
            .attr("height", function (d) {
                return height - yScale(type === 'weekly' ? d.weekly_sales :d.monthly_sales);
            })
            .attr("fill", "#00CED1")
            .attr("stroke", "white")
            .attr("stroke-width", "2px");

        var abc = xAxisG.call(xaxis);
        var yabc = yAxsiG.call(yaxis)

        abc.selectAll(".domain, .tick line").remove()
        yabc.selectAll(".domain, .tick line").remove()

    }
    getDomain = (d, type) => {
        let dataset = []
        if(type === 'weekly'){
            dataset = d.map( (item) => item.week)
        }else{
            dataset = d.map( (item) => item.month)
        }
        return dataset
    }
    getScale = (d, type) => {

    }
    getMonthlyData = (data) => {

    }
    getWeeklyData = (data) => {

    }
    render() {
        return (
            <div id="barChart"></div>
        )
    }
}

export default BarChart