import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

class CustomerMonthlyRevenue extends Component {

    componentDidMount() {
        console.log(this.props.customerMonthlyRevenue)
        this.customerMonthlyRevenue(this.props.customerMonthlyRevenue)
    }

    customerMonthlyRevenue = (data) => {
        var comma = d3.format(",");

        var margin = {top:20, bottom:50, right:20, left:50},
            height = 500 - margin.top - margin.bottom,
            width = 1000 - margin.right - margin.left;

            const render = data => {

                console.log("CA DATA:",data)

                var cus_name = [] 

                data.forEach(function(d){
                    cus_name.push(d.customer_name)
                })

                var parse = d3.timeParse("%B-%Y")

            var max_date = [];
            var min_date = [];

            var max_rev = []
            data.forEach(function(d){

                max_rev.push(d3.max(d["value"], function(a){ return a.revenue}))
                // d["data"].sort(sortdate)
                d["value"].forEach(function(d){
                    d.mm_yyyy = parse(d.mm_yyyy)
                })
                max_date.push(d3.max(d["value"], function(d){ return d.mm_yyyy}))
                min_date.push(d3.min(d["value"], function(d){ return d.mm_yyyy}))
            })

            console.log(max_rev)

            var svg = d3.select("#customerMonthlyRevenue").append("svg")
                        .attr("width", width+margin.left+margin.right)
                        .attr("height", height+margin.top+margin.bottom)

            var g = svg.append("g").attr("transform", "translate("+margin.left+", "+margin.top+")")

            var x = d3.scaleTime().range([0, width])

            var y = d3.scaleLinear().range([height, 0])

            var tooltip = d3.select("body").append("div").attr("class", "toolTip");

            x.domain([d3.min(min_date, function(d){return d}), d3.max(max_date, function(d){ return d})])

            var xaxis = g.append("g").attr("transform", "translate(0, "+ height +")")
                            .call(d3.axisBottom(x).tickFormat(d3.timeFormat("%b-%y")))

            y.domain([0, d3.max(max_rev, function(d){ return d})])


            var yaxis = g.append("g").attr("transform", "translate(0,0)")
                            .call(d3.axisLeft(y).tickSize(-width)) 
                            
                            d3.selectAll('.domain').remove();

            var line = d3.line()
                        .x(function(d) { return x(d.mm_yyyy); })
                        .y(function(d) { return y(d.revenue); })
            var color = d3.scaleOrdinal().range(d3.schemeCategory10)

            function sortdate(a, b){
                return a.mm_yyyy - b.mm_yyyy;
            }

            for(var i=0;i<data.length;i++){

                var line_data = data[i]["value"]  
                line_data.sort(sortdate)  
                    console.log("line data",line_data)  
                var l = g.append("path")
                    .datum(line_data)
                    .attr("fill", "none")
                    .attr("stroke", color(i))
                    .attr("stroke-width", "4px")
                    .attr("class", "line"+i)
                    .attr("d", line)
                    .on("click", function(d,i){
                    
                        d3.select(this).remove()
                        var cls = this.getAttribute('class')
                        var last = cls[cls.length-1]
                        g.selectAll(".circle"+last).remove()
                        
                    })
                    .on("mouseover",function(d){
                        d3.select(this).raise()
                        .attr("stroke-width","6px")

                        var cls = this.getAttribute('class')
                        var last = cls[cls.length-1]
                        g.selectAll(".circle"+last).raise().attr("r", 5)
                        
                    })
                    .on("mouseout",function(d){
                        d3.select(this).raise()
                        .attr("stroke-width","4px")

                        var cls = this.getAttribute('class')
                        var last = cls[cls.length-1]
                        g.selectAll(".circle"+last).raise().attr("r", 4)
                    
                    })
                    
                    g.selectAll(".circle")
                    .data(data[i]["value"])
                    .enter()
                    .append("circle")
                    .attr("r", 4)
                    .attr("cx", function(d){ return x(d.mm_yyyy)})
                    .attr("cy", function(d){return y(d.revenue)})
                    .attr("stroke","white")
                    .attr("fill",color(i))
                    .attr("stroke-width","1px")
                    .attr("class", "circle"+i)
                    .on("mousemove", function(d){
                        tooltip.style("left", d3.event.pageX - 50 + "px")
                        .style("top", d3.event.pageY - 70 + "px")
                        .style("display", "inline-block")
                        .html("Date : "+(d3.timeFormat("%b-%y")(d.mm_yyyy)) + "<br>" + "Revenue: " +(comma (d.revenue)))
                        .style("background","dimgray")
                        .style("color","white");
                    })
                .on("mouseout", function(d){ tooltip.style("display", "none");});
                
                    //legends
                    var lineLegend = g.selectAll(".lineLegend").data(cus_name)
                            .enter().append("g")
                            .attr("class","lineLegend")
                            .attr("transform", function (d,i) {
                                    return "translate(" + (width/35+(i*250)) + "," + (height+(margin.bottom/2)+10)+")";
                                });

                        lineLegend.append("text").text(function (d) {return d;})
                            .attr("transform", "translate(15, 6)")
                            .text(function(d) { return d.replace(/_/g, " "); }); //align texts with boxes
                        //  .attr("fill",color(index))
                        lineLegend.append("rect")
                            .attr("fill",function(d,i){return color(i)})
                            .attr("width", 12).attr('height', 5);
            }
        }
        render(data)
    }

    render() {
        return (
            <div className="customerMonthlyRevenue" id="customerMonthlyRevenue"></div>
        );
    }
}

CustomerMonthlyRevenue.propTypes = {
    customerMonthlyRevenue: PropTypes.array.isRequired
};

export default CustomerMonthlyRevenue;