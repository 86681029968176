import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <div>
                Footer
            </div>
        );
    }
}

export default Footer;