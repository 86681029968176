import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import AnimatedProgressProvider from '../../Reusable_Components/Circular_Animation_Progress/AnimatedProgressProvider';
import { easeQuadInOut } from 'd3-ease';

const FileUploadingProgress = (props) => {
    return (
        <div className="d-flex justify-content-center mt-5">
            <AnimatedProgressProvider
                valueStart={0}
                valueEnd={props.uploadingProgress}
                duration={2}
                easingFunction={easeQuadInOut}
            >
                {
                    value => {
                        const roundedValue = Math.round(value);
                        return (
                            <CircularProgressbar 
                                className="progress-uploading-Circle"
                                value={roundedValue}
                                text={`${roundedValue}%`}

                                styles={buildStyles({ 
                                    pathTransition: 'none',
                                    textSize: '25px',  

                                    // Colors
                                    pathColor: `rgba(62, 152, 199, ${props.uploadingProgress / 100})`,
                                    textColor: 'rgba(62, 152, 199)',                                                                  
                                })}
                            />
                        );
                    }
                }
            </AnimatedProgressProvider>
        </div>
    );
};

export default FileUploadingProgress;