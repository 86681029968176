import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import $ from 'jquery'

class MonthlyCashBalanceForecast extends Component {

    // constructor() {
    //     super();
    //     this.state = {
    //       width:  1210,
    //       height: 380
    //     }
    // }

    updateDimensions() {
        const wSize = window.innerWidth;
        if(wSize <= 1299) {
            console.log(wSize,'Laptop L')
            this.setState({
                width: 1210,
                height: 380
            })
            this.monthlyCashBalanceForecast(this.props.monthlyCashBalanceForecast)
        } else if(wSize <= 1024) {
            console.log(wSize,'Laptop')
            this.setState({
                width: 930,
                height: 380
            })
            this.monthlyCashBalanceForecast(this.props.monthlyCashBalanceForecast)
        } else if(wSize <= 768) {
            console.log(wSize,'Tablet')
            this.setState({
                width: 680,
                height: 380
            })
            this.monthlyCashBalanceForecast(this.props.monthlyCashBalanceForecast)
        } else if(wSize <= 425) {
            console.log(wSize,'Mobile')
            this.setState({
                width: 400,
                height: 380
            })
            this.monthlyCashBalanceForecast(this.props.monthlyCashBalanceForecast)
        }
    }

    componentDidMount() {
        // this.updateDimensions();
        // window.addEventListener("resize", this.updateDimensions.bind(this));
        this.monthlyCashBalanceForecast(this.props.monthlyCashBalanceForecast)
    }

    // componentWillUnmount() {
    //     window.removeEventListener("resize", this.updateDimensions.bind(this));
    //     d3.select("svg").remove();
    // }

    monthlyCashBalanceForecast = data => {
        var cwidth = $(".cashflowForecast").width();   
        var cheight = $(".cashflowForecast").height();
        console.log(cwidth, cheight)
        var margin = {top: 20, right: 50, bottom: 60, left: 40},
            width = cwidth - margin.left - margin.right,
            height = cheight - margin.top - margin.bottom;

        var svg=d3.select("#monthlyCashBalanceForecast").append("svg")
            .attr("width",cwidth)
            .attr("height",cheight)

        var chart=svg.append("g")
                .attr("transform", "translate("+margin.left+","+margin.top+")");

        var x = d3.scaleTime()
            .range([0, width]);

        var y = d3.scaleLinear()
            .range([height, 0]);

        // gridlines in y axis function
        function make_y_gridlines() {		
                return d3.axisLeft(y)
                    .ticks(8)
                    .tickFormat(d3.format(".2s"))
            }

            var parse=d3.timeParse("%B-%Y");
            var format=d3.timeFormat("%b-%y");

            var xAxis = d3.axisBottom(x)
            .tickFormat(format)
            .ticks(12);

            function datasort(a,b){
                return a.month-b.month
            }

            const render = data =>{
                console.log(data)

                data.forEach(function(d){
                    console.log(d)
                    d.month=parse(d.month);
                })

                data=data.sort(datasort);
                console.log(data)
                console.log(data.map(function(d){return d.month}))

                x.domain(d3.extent(data, function(d){return d.month}));
                y.domain([0,d3.max(data,function(d){return d.prediction})]);

                chart.append("g")
                    .attr("transform","translate(0,0)")
                    .call(make_y_gridlines()
                    .tickSize(-width)
                );

                chart.append("g")
                    .attr("transform","translate(0,"+height+")")
                    .call(xAxis);

                chart.append("path")
                .data([data])
                .attr("fill", "none")
                .attr("stroke", "coral")
                .attr("stroke-width", 2.5)
                .attr("d", d3.line()
                .x(function(d) {console.log(d.month); return x(d.month) })
                .y(function(d) { return y(d.prediction) })
                .curve(d3.curveNatural))

                var div=d3.select("body").append("div")
                .style("opacity",0).attr("class","tooltip")
                
                chart.selectAll("circle")
                .data(data)
                .enter()
                .append("circle")
                .attr("r",3)
                .attr("cx",function(d){return x(d.month)})
                .attr("cy",function(d){return y(d.prediction)})
                .attr("fill","coral")
                .on("mouseover",function(d){
                    div.transition()
                        .duration(200)
                        .style("opacity", .9)
                    div.html(format(d.month)+ "<br/>"  + d3.format(",d")(d.prediction))
                    .style("left",(d3.event.pageX)+"px")
                    .style("top",(d3.event.pageY-28)+"px");
                })
                .on("mouseout",function(d){
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                    });
            }
            render(data)
    }
    
    render() {
        return (
            <div className="monthlyCashBalanceForecast" id="monthlyCashBalanceForecast"></div>
        );
    }
}

MonthlyCashBalanceForecast.propTypes = {
    monthlyCashBalanceForecast: PropTypes.array.isRequired
};

export default MonthlyCashBalanceForecast;