import { CASHFLOW_FORECAST_LOADING, GET_MONTHLY_CASH_BALANCE_FORECAST, GET_WEEKLY_HIST_PRED_DATA, CLEAR_CURRENT_CASHFLOW_FORECAST } from '../actions/types';

const initialState = {
    loading: false,
    monthlyCashBalanceForecast: null,
    weeklyHistPredData: null
}

export default function(state = initialState, action) {
    switch(action.type) {
        case CASHFLOW_FORECAST_LOADING: 
            return {
                ...state,
                loading: true
            }
        case GET_MONTHLY_CASH_BALANCE_FORECAST:
            return {
                ...state,
                monthlyCashBalanceForecast: action.payload,
                loading: false
            }
        case GET_WEEKLY_HIST_PRED_DATA:
            return {
                ...state,
                weeklyHistPredData: action.payload,
                loading: false
            }
        case CLEAR_CURRENT_CASHFLOW_FORECAST:
            return {
                ...state,
                loading: false,
                monthlyCashBalanceForecast: null,
                weeklyHistPredData: null
            }
        default:
            return state;
    }
}