import { INSIGHTS360_LOADING, GET_BASKET_KPI_DETAILS, GET_MONTHLY_SALES_YEAR_WISE_DATA, GET_WEEKLY_SALES_PROFIT,
        GET_MONTHLY_SALES_DATA, GET_TOP10_ITEMS_BASED_ON_SALES, CLEAR_CURRENT_INSIGHTS360 } from '../actions/types';

const initialState = {
    loading: false,
    basketKpi: null,
    monthlySalesData: null,
    monthlySalesYearWise : null,
    top20ItemsBased: null,
    weeklySales: null
}

export default function(state = initialState, action) {
    switch(action.type) {
        case INSIGHTS360_LOADING:
            return {
                ...state,
                loading: true
            }
        case GET_BASKET_KPI_DETAILS:
            return {
                ...state,
                basketKpi: action.payload,
                loading: false
            }
        case GET_MONTHLY_SALES_DATA:
            return {
                ...state,
                monthlySalesData: action.payload,
                loading: false
            }
        case GET_MONTHLY_SALES_YEAR_WISE_DATA:
            return {
                ...state,
                monthlySalesYearWise: action.payload,
                loading: false
            }
        case GET_TOP10_ITEMS_BASED_ON_SALES:
            return {
                ...state,
                top20ItemsBased: action.payload,
                loading: false
            }
        case GET_WEEKLY_SALES_PROFIT:
            return {
                ...state,
                weeklySales: action.payload,
                loading: false
            }
        case CLEAR_CURRENT_INSIGHTS360:
            return {
                ...state,
                basketKpi: null,
                monthlySalesData: null,
                monthlySalesYearWise : null,
                top20ItemsBased: null,
                weeklySales: null
            }
        default:
            return state;
    }
}