import axios from 'axios';
import { ANALYTICS_URL, TOKEN_AUTH, ACCESS_TOKEN, GET_ERRORS, FILE_UPLOADING_LOADING, 
    FILE_UPLOADING_RESULT, FILE_PROCESSING_RESULT, UPLOADING_PROGRESS_PERCENTAGE, CLEAR_CURRENT_FILE_UPLOADING, 
    FILE_PARTIAL_LOCK } from './types';
import { setCurrentUser } from './authActions';
import { notification } from 'antd';

/*--- File Upload ---*/
export const dataFileUpload = (formData, domain) => dispatch => {
    dispatch(clearCurrentFileUploading());
    dispatch(fileUploadLoding());
    const token = JSON.parse(localStorage.getItem('AnalyticalAppToken'));
    const headers = {
        'Authorization': token.auth_token,
        "Content-Type" : "multipart/form-data",
        onUploadProgress: progressEvent => {
            console.log('Upload Progress: ' + Math.round(progressEvent.loaded / progressEvent.total * 100) + '%')
            dispatch(fileUploadProgress(Math.round(progressEvent.loaded / progressEvent.total * 100)))
        },
        timeout: 600000
    }
    axios.post(`/rest/doc/upload`, formData, headers)
        .then(res => {
            notification.success({
                message: 'Uploaded successfully',
                description: 'File uploaded successfully'
            });
            console.log(res)
            dispatch({
                type: FILE_UPLOADING_RESULT,
                payload: res
            })
            const { file } = res.data;
            if(domain === 'Retail') {
                dispatch(uploadRetailID(file))
            } else if (domain === 'Cashflow Analysis') {
                console.log('Uploaded SuccessFully')
                dispatch(uploadCashFlowID(file))
            }
        })
        .catch(err => 
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            })
        )
}

/*--- File ID to Python ---*/
export const uploadRetailID = (Object_id) => dispatch => {
    console.log('Retail ObjectId Progress')
    dispatch(clearCurrentFileUploading());
    axios.get(`${ANALYTICS_URL}/retail/postdatafile/${Object_id}`, ACCESS_TOKEN())
        .then(res => {
            notification.success({
                message: 'File Processed successfully',
            });
            console.log(res)
            dispatch({
                type: FILE_PROCESSING_RESULT,
                payload: res.data
            })
            dispatch(setCurrentUser(TOKEN_AUTH()))
        })
        .catch(err => 
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            })
        )
} 

export const uploadCashFlowID = (Object_id) => dispatch => {
    console.log('Cashflow ObjectId Progress')
    dispatch(clearCurrentFileUploading());
    axios.get(`${ANALYTICS_URL}/cash_flow/upload_data_file/${Object_id}`, ACCESS_TOKEN())
        .then(res => {
            notification.success({
                message: 'File Processed successfully',
            });
            console.log(res)
            dispatch({
                type: FILE_PROCESSING_RESULT,
                payload: res.data
            })
            dispatch(setCurrentUser(TOKEN_AUTH()))
        })
        .catch(err => 
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            })
        )
} 

/*------------------------------------------*/

/*--- Partial Lock ---*/
export const accountRetailParticalLock = client_id => dispatch => {
    dispatch(clearCurrentFileUploading());
    dispatch(fileUploadLoding());
    axios.get(`${ANALYTICS_URL}/retail/PostFileUpload/${client_id}`, ACCESS_TOKEN())
        .then(res =>{
            notification.success({
                message: 'Processed successfully',
                description: 'Your Visualization are ready'
            });
            dispatch({
                type: FILE_PARTIAL_LOCK,
                payload: res.data
            })
            setTimeout(function(){ window.location.href = '/insights360'; }, 2000);
        })
        .catch(err => 
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            })
        )
}

export const accountCashflowParticalLock = client_id => dispatch => {
    dispatch(clearCurrentFileUploading());
    dispatch(fileUploadLoding());
    axios.get(`${ANALYTICS_URL}/cash_flow/process_data_file/${client_id}`, ACCESS_TOKEN())
        .then(res =>{
            notification.success({
                message: 'Processed successfully',
                description: 'Your Visualization are ready'
            });
            dispatch({
                type: FILE_PARTIAL_LOCK,
                payload: res.data
            })
            setTimeout(function(){ window.location.href = '/cashflow360'; }, 2000);
        })
        .catch(err => 
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            })
        )
}

/*------------------------------------------*/

/*--- File Uploading Progress */
export const fileUploadProgress = progress => {
    return {
        type : UPLOADING_PROGRESS_PERCENTAGE,
        payload : progress
    }
}

/*--- File Uploading Loading ---*/
export const fileUploadLoding = () => {
    return {
        type: FILE_UPLOADING_LOADING
    }
}

/*--- Clear File Uploading ---*/
export const clearCurrentFileUploading = () => {
    return {
        type: CLEAR_CURRENT_FILE_UPLOADING
    }
}