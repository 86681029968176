import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import { dataFileUpload } from "../../../redux/actions/fileUploadActions";
import { notification } from "antd";
import FileUploadingProgress from "./FileUploadingProgress";
import isEmpty from "../../../validation/is-empty";
import { ItemFile, SalesFile, CashflowAnalysis } from "./DownloadFileType";
import UploadingFilesType from "./UploadingFilesType";
import UploadedFiles from "./UploadedFiles";

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  get initialState() {
    return {
      setFile: null,
      file: [],
      filename: "Choose file",
      category: "",
      errors: {},
      itemValue: false,
      salesValue: false,
      uploadingStatus: false,
      uploadingProgress: false,
      processingFile: false,
    };
  }

  handleChange = (e) => {
    this.setState({
      setFile: e.target.files[0],
      filename: e.target.files[0].name,
    });
  };

  handleCheck = (e) => {
    const handleCheck = e.target.name;
    if (handleCheck === "item") {
      this.setState({
        itemValue: e.target.checked,
        salesValue: !e.target.checked,
        category: handleCheck,
      });
    } else if (handleCheck === "sales") {
      this.setState({
        itemValue: !e.target.checked,
        salesValue: e.target.checked,
        category: handleCheck,
      });
    }
  };

  validate = () => {
    const { domain } = this.props.auth.user;
    let isError = false;

    const errors = {
      errors: {},
    };

    if (domain && domain.name === "Retail") {
      if (this.state.category === "") {
        isError = true;
        errors.errors.category =
          "Select Category of File which you are uploading";
      }
    }

    if (this.state.setFile === null) {
      isError = true;
      errors.errors.setFile = "Select File";
    }

    this.setState({
      ...this.state,
      ...errors,
    });

    return isError;
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { domain } = this.props.auth.user;
    const err = this.validate();

    if (!err) {
      const files = this.state.setFile;
      console.log(files);

      let reader = new FormData();
      reader.append("file", files);
      if (domain && domain.name === "Retail") {
        reader.append("category", this.state.category);
      } else if (domain && domain.name === "Cashflow Analysis") {
        reader.append("category", "bank");
      }

      this.props.dataFileUpload(reader, domain && domain.name);
    }
  };

  componentWillReceiveProps(nextProps) {
    const {
      fileUploadingProgress,
      fileUploadingResult,
      fileProcessingResult,
      filePartialLock,
    } = nextProps.fileUpload;
    const { domain } = nextProps.auth.user;
    if (fileUploadingProgress) {
      this.setState({
        uploadingStatus: true,
        uploadingProgress: true,
      });
    }

    if (fileUploadingResult !== null) {
      this.setState({
        uploadingProgress: false,
        uploadingStatus: false,
        processingFile: true,
      });
    }

    if (fileProcessingResult !== null) {
      const { user } = nextProps.auth;
      if (domain && domain.name === "Retail") {
        if (user.filesInfo.fileCount === 1) {
          console.log("1st file Uploaded");
          this.setState(this.initialState);
        } else if (user.filesInfo.fileCount > 1) {
          console.log("2st file Uploaded");
          this.setState({
            processingFile: true,
          });
        }
      } else if (domain && domain.name === "Cashflow Analysis") {
        if (user.filesInfo.fileCount === 1) {
          console.log(fileProcessingResult);
          this.setState({
            processingFile: true,
          });
        }
      }
    }

    if (filePartialLock !== null) {
      console.log(filePartialLock);
      this.setState(this.initialState);
    }

    if (isEmpty(nextProps.errors) === false) {
      console.log(nextProps.errors);
      this.setState(this.initialState);
      const status = nextProps.errors.status;
      const statusText = nextProps.errors.statusText;
      if (status === 409 || status === 500) {
        const message =
          nextProps.errors.data.message ||
          nextProps.errors.data.find((o) => o.message).message;
        notification.error({
          message: statusText,
          description: message,
        });
      }
    }
  }

  render() {
    // const { errors } = this.state;
    // const { fileUploadingProgress } = this.props.fileUpload;
    // const { user } = this.props.auth;
    // const { domain } = this.props.auth.user;
    // const itemFound = user.filesInfo && user.filesInfo.fileTypes.includes("item");
    // const salesFound = user.filesInfo && user.filesInfo.fileTypes.includes("sales");
    // const bankDumpFound = user.filesInfo && user.filesInfo.fileTypes.includes("bank");
    const { errors } = this.state;
    const { fileUploadingProgress } = this.props.fileUpload;
    const { user } = this.props.auth;
    const { domain } = user;

    // Ensure 'user' and 'user.filesInfo' are valid, and 'user.filesInfo.fileTypes' is an array
    const itemFound =
      user.filesInfo &&
      Array.isArray(user.filesInfo.fileTypes) &&
      user.filesInfo.fileTypes.includes("item");
    const salesFound =
      user.filesInfo &&
      Array.isArray(user.filesInfo.fileTypes) &&
      user.filesInfo.fileTypes.includes("sales");
    const bankDumpFound =
      user.filesInfo &&
      Array.isArray(user.filesInfo.fileTypes) &&
      user.filesInfo.fileTypes.includes("bank");

    return (
      <div className="container mt-2">
        <div className="fileUpload">
          <h4 className="display-4 text-center mb-4">Upload File</h4>
          <div className="d-flex bd-highlight">
            <div className="mr-auto bd-highlight">
              {domain && domain.name === "Retail" ? (
                <UploadingFilesType
                  data={[ItemFile, SalesFile]}
                  name={["Item", "Sales"]}
                />
              ) : domain && domain.name === "Cashflow Analysis" ? (
                <UploadingFilesType
                  data={[CashflowAnalysis]}
                  name={["Bank_Dump"]}
                />
              ) : null}
            </div>
            <div className="bd-highlight">
              {domain && domain.name === "Retail" ? (
                <UploadedFiles
                  name={["Item", "Sales"]}
                  file={[itemFound, salesFound]}
                />
              ) : domain && domain.name === "Cashflow Analysis" ? (
                <UploadedFiles name={["Bank_Dump"]} file={[bankDumpFound]} />
              ) : null}
            </div>
          </div>
          <form noValidate onSubmit={(e) => this.onSubmit(e)}>
            {domain && domain.name === "Retail" ? (
              <div className="form-check form-check-inline">
                <div className="checkbox mr-2">
                  <label className="checkbox-label mb-0">Category :</label>
                </div>
                <div className="checkbox mr-4">
                  <label className="checkbox-label mb-0">
                    <input
                      type="checkbox"
                      className={classnames("form-check-input", {
                        "is-invalid": errors.category,
                      })}
                      id="materialUnchecked"
                      name="item"
                      onChange={this.handleCheck}
                      checked={this.state.itemValue}
                    />
                    <span className="cr">
                      <i className="cr-icon fa fa-check"></i>
                    </span>
                    Item
                  </label>
                </div>
                <div className="checkbox mr-4">
                  <label className="checkbox-label mb-0">
                    <input
                      type="checkbox"
                      className={classnames("form-check-input", {
                        "is-invalid": errors.category,
                      })}
                      id="materialUnchecked"
                      name="sales"
                      onChange={this.handleCheck}
                      checked={this.state.salesValue}
                    />
                    <span className="cr">
                      <i className="cr-icon fa fa-check"></i>
                    </span>
                    Sales
                  </label>
                </div>
              </div>
            ) : null}
            {errors.category ? (
              <div className="error-msgs">{errors.category}</div>
            ) : null}
            <div className="custom-file mb-4">
              <input
                type="file"
                className={classnames("custom-file-input", {
                  "is-invalid": errors.setFile,
                })}
                id="validatedCustomFile"
                onChange={(e) => this.handleChange(e)}
                required
              />
              <label
                className="custom-file-label"
                htmlFor="validatedCustomFile"
              >
                {this.state.filename}
              </label>
              {errors.setFile ? (
                <div className="invalid-feedback ml-0">{errors.setFile}</div>
              ) : null}
            </div>

            <input
              type="submit"
              value="Upload"
              className="btn btn-primary btn-block m-0"
            />
          </form>
        </div>
        {this.state.uploadingProgress ? (
          <FileUploadingProgress uploadingProgress={fileUploadingProgress} />
        ) : null}
        {this.state.uploadingStatus ? (
          <div className="fileProcessing">
            Please Wait Uploading Your File ....
          </div>
        ) : null}
        {this.state.processingFile ? (
          <div className="fileProcessing">
            Please Wait Processing Your File ....
          </div>
        ) : null}
      </div>
    );
  }
}

FileUpload.propTypes = {
  dataFileUpload: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  fileUpload: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  fileUpload: state.fileUpload,
});

export default connect(mapStateToProps, { dataFileUpload })(FileUpload);
