import React, { Component } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";

class MonthlyTopk extends Component {
  componentDidMount() {
    console.log(this.props.monthlyTopk);
    this.MonthlyTopk(this.props.monthlyTopk, this.props.type);
  }

  update = (data) => {
    this.props.update(data);
  };

  MonthlyTopk = (data, type) => {
    var margin = { top: 20, bottom: 20, right: 70, left: 70 },
      width = 650 - margin.left - margin.right,
      height = 400 - margin.top - margin.bottom;

    var svg = d3
      .select("#MonthlyTopk")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);

    var g = svg
      .append("g")
      .attr("transform", "translate(" + margin.left + ", " + margin.top + ")");

    //     var allGroup = ["Quater1", "Quater2", "Quater3", "Quater4"]

    var x = d3.scaleBand().range([0, width]).padding(0.4);
    var y = d3.scaleLinear().range([height, 0]);

    var parse = d3.timeParse("%b-%Y");
    var format = d3.timeFormat("%b-%y");

    // const render = (data, type) =>{

    //     var topSum=[]
    //     data["data"].forEach(function(d,i){
    //         console.log(Object.keys(d))
    //     var date= Object.keys(d)
    //     var sum=0;
    //     data["data"][i][date].forEach(function(d){

    //             sum+=d[`${type}`]

    //     })
    //     topSum.push({date:date[0],total:sum})
    //     })
    //     console.log(topSum)

    //     topSum.forEach(function(d){
    //         d.date=parse(d.date);

    //     })

    //     //date sort
    //     function sortdate(a,b){
    //         return a.date-b.date;
    //     }
    //     topSum.sort(sortdate);

    //     x.domain(topSum.map(function(d){return d.date}))
    //     y.domain([0,d3.max(topSum,function(d){return d.total})])

    //     var xAxis=d3.axisBottom(x)
    //             .tickFormat(format)
    //             .ticks(12);
    //             //console.log(xAxis)

    //     var yAxis=d3.axisLeft(y)
    //         .ticks(12);

    //     g.append("g")
    //     .attr("transform","translate(0,"+height+")")
    //     .call(xAxis)

    //     g.append("g")
    //     .attr("transform","translate(0,0)")
    //     .call(yAxis)

    //     g.selectAll("rect")
    //     .data(topSum)
    //     .enter()
    //     .append("rect")
    //     .attr("width",x.bandwidth())
    //     .attr("height", function(d){ return height-y(d.total)})
    //     .attr("x",function(d){return x(d.date)})
    //     .attr("y",function(d){return y(d.total)})
    //     .attr("fill","steelblue")
    //     .on("click",this.update);
    // }
    const render = (data, type) => {
      // Check if data["data"] is defined and is an array
      if (data && Array.isArray(data["data"]) && data["data"].length > 0) {
        var topSum = [];

        // Loop through the data array
        data["data"].forEach(function (d, i) {
          var date = Object.keys(d);
          var sum = 0;

          // Check if data[i][date] is defined and an array
          if (data["data"][i] && Array.isArray(data["data"][i][date])) {
            data["data"][i][date].forEach(function (d) {
              // Safely access the value for the provided type
              if (d[`${type}`] !== undefined) {
                sum += d[`${type}`];
              }
            });

            // Push the calculated sum and date into topSum
            topSum.push({ date: date[0], total: sum });
          } else {
            console.warn(
              `Data at index ${i} for date ${date} is undefined or not an array.`
            );
          }
        });

        // Ensure topSum is not empty before proceeding
        if (topSum.length > 0) {
          topSum.forEach(function (d) {
            d.date = parse(d.date); // Use d3.timeParse to parse the date
          });

          // Sort the topSum array by date
          topSum.sort((a, b) => a.date - b.date);

          // Set up the x and y domains for the chart
          x.domain(
            topSum.map(function (d) {
              return d.date;
            })
          );
          y.domain([
            0,
            d3.max(topSum, function (d) {
              return d.total;
            }),
          ]);

          // Define x and y axes
          var xAxis = d3.axisBottom(x).tickFormat(format).ticks(12);

          var yAxis = d3.axisLeft(y).ticks(12);

          // Append the axes to the chart
          g.append("g")
            .attr("transform", "translate(0," + height + ")")
            .call(xAxis);

          g.append("g").attr("transform", "translate(0,0)").call(yAxis);

          // Create bars for the chart
          g.selectAll("rect")
            .data(topSum)
            .enter()
            .append("rect")
            .attr("width", x.bandwidth())
            .attr("height", function (d) {
              return height - y(d.total);
            })
            .attr("x", function (d) {
              return x(d.date);
            })
            .attr("y", function (d) {
              return y(d.total);
            })
            .attr("fill", "steelblue")
            .on("click", this.update);
        } else {
          console.warn("No valid data in topSum to render.");
        }
      } else {
        console.warn(
          'Data or data["data"] is either undefined or not an array.'
        );
      }
    };

    render(data, type);
  };

  componentWillUnmount() {
    d3.select("svg").remove();
  }

  render() {
    return <div className="MonthlyTopk" id="MonthlyTopk"></div>;
  }
}

MonthlyTopk.propTypes = {
  MonthlyTopk: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default MonthlyTopk;
