import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCustomerMonthlyRevenue, postCustomerMonthlyRevenue } from '../../../../redux/actions/customerAnalysisActions';
import RequestLoadingData from '../../../Loading/RequestLoadingData';
import isEmpty from '../../../../validation/is-empty';
import SidebarCustomerList from './SidebarCustomerList';
import CustomerAnalysisMultiple from './CustomerAnalysisMultiple';
import CustomerMonthlyRevenue from './CustomerMonthlyRevenue';

class CustomerAnalysis extends Component {

    state = {
        data: [],
        dataLoading: false,
        keyvalue: []
    }

    componentDidMount() {
        this.setState({ keyvalue: ['0'] })
        this.props.getCustomerMonthlyRevenue();
        this.props.postCustomerMonthlyRevenue(this.postData('0'))
    }

    postData = (value) => {
        return {
            "customer_id": value
        }
    }

    change = (value) => {
        console.log(value)
        this.setState({ keyvalue: [...value], data: [], dataLoading: false })
        value.forEach(element => {
            setTimeout(this.props.postCustomerMonthlyRevenue(this.postData(element)))
        });
        // this.props.postCustomerMonthlyRevenue(this.postData(value))
    }

    componentWillReceiveProps(nextProps) {
        const { getCustomerMonthlyRevenue, postCustomerMonthlyRevenue } = nextProps.customerAnalysis
        if(getCustomerMonthlyRevenue !== null && postCustomerMonthlyRevenue !== null) {
            let key = this.getKeyByValue(getCustomerMonthlyRevenue, postCustomerMonthlyRevenue.customer_name);
            let value = postCustomerMonthlyRevenue.data;
            console.log({key, customer_name: postCustomerMonthlyRevenue.customer_name, value})
            this.setState({
                data: [...this.state.data, { key, customer_name: postCustomerMonthlyRevenue.customer_name, value }],
                dataLoading: true
            })
        }
    }

    getKeyByValue = (object, value) => {
        return Object.keys(object).find(key => object[key] === value);
    }

    render() {
        const { loading, getCustomerMonthlyRevenue, postCustomerMonthlyRevenue } = this.props.customerAnalysis;

        let customerAnalysis;
        if(loading === false && getCustomerMonthlyRevenue !== null && postCustomerMonthlyRevenue !== null) {
            if(isEmpty(getCustomerMonthlyRevenue) === true || isEmpty(postCustomerMonthlyRevenue) === true) {
                customerAnalysis = (
                    <div>
                        <p className="lead text-muted">Sorry</p>
                        <p>Something went wrong please try again after some time</p>
                    </div>
                )
            } else {
                const { data, dataLoading, keyvalue } = this.state;
                customerAnalysis = (
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card customerAnalysis">
                                <div className="row">
                                    <div className="col-md-2">
                                        <SidebarCustomerList 
                                            getCustomerMonthlyRevenue={getCustomerMonthlyRevenue}
                                            change={this.change}
                                        />
                                        {/* <CustomerAnalysisMultiple 
                                            getCustomerMonthlyRevenue={getCustomerMonthlyRevenue}
                                            change={this.change}
                                        /> */}
                                    </div>
                                    <div className="col-md-10">
                                        <div className="d-flex justify-content-center card-graph-Content">
                                            Customer Analysis
                                        </div>
                                        <div className="card-graph">
                                            {
                                                dataLoading && keyvalue.length === data.length?
                                                    <CustomerMonthlyRevenue 
                                                        customerMonthlyRevenue={data}
                                                    />
                                                :
                                                    null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        } else {
            customerAnalysis = <RequestLoadingData />
        }
        return (
            <div className="container-fluid">
                {customerAnalysis}
            </div>
        );
    }
}

CustomerAnalysis.propTypes = {
    getCustomerMonthlyRevenue: PropTypes.func.isRequired,
    postCustomerMonthlyRevenue: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    customerAnalysis: state.customerAnalysis
})

export default connect(mapStateToProps, { getCustomerMonthlyRevenue, postCustomerMonthlyRevenue })(CustomerAnalysis);