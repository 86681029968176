import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
const { Option } = Select;

class CustomerAnalysisMultiple extends Component {

    state = {
        change: ['0'],
        collapsed: true,
        marketDropdown: null,
        marketDropdownIcons: ['login', 'rollback', 'enter', 'radius-upright', 'plus', 'exclamation', 'retweet', 'swap', 'logout', 'stop', 'fall', 'rise', 'stock', 'sliders', 'cloud', 'customer-service', 'dashboard', 'fire', 'star', 'tags'],
        marketDropdownKeys: [],
        marketDropdownValue: [],
        selectTag: false
    }

    componentDidMount(){
        const dropDown = this.props.getCustomerMonthlyRevenue;
        const key = Object.keys(dropDown);
        const value = Object.values(dropDown);
        const dropdownLength = key.length;
        const icon = this.state.marketDropdownIcons.length;
        const result = Math.round(dropdownLength / icon);
        this.setState({
            marketDropdownIcons: this.makeRepeated(this.state.marketDropdownIcons, result),
            marketDropdown: dropDown,
            marketDropdownKeys: [...key],
            marketDropdownValue: [...value]
        })
    }

    makeRepeated = (arr, repeats) => [].concat(...Array.from({ length: repeats }, () => arr));

    handleChange = (value) => {
        this.props.change(value);
    }

    sidenav = () => (
        this.state.marketDropdownKeys.map((a, i) => (
            <Option key={a}>{this.state.marketDropdownValue[i]}</Option>
        ))
    )

    render() {
        return (
            <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Please Select Customers to Compare"
                defaultValue={['0']}
                onChange={this.handleChange}
            >
                {this.sidenav()}
            </Select>
        );
    }
}

CustomerAnalysisMultiple.propTypes = {

};

export default CustomerAnalysisMultiple;