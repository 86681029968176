import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { MDBIcon } from 'mdbreact';

function NavLinkDrop({ icon, name }) {
    return (
        <Link to="#" className="nav-link" id="navbarDropdownMenuLink-333" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {icon ? <MDBIcon icon={icon} /> : null}  {name}
        </Link>
    )
}

NavLinkDrop.propTypes = {
    icon: PropTypes.string,
    name: PropTypes.string.isRequired
}

export default NavLinkDrop


