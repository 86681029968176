import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as d3 from 'd3'

class ProfitChart extends Component {

    componentDidMount(){
        // console.log(this.props.monthlyProfit)
        this.barupdate(this.props.monthlyProfit)
    }

    barupdate = (data) => {
        var margin = {top:0, bottom:20, left:50, right:5 },
                        width = 500 - margin.left - margin.right,
                        height = 250 - margin.top - margin.bottom;
                       // barPadding = 1;

        var color = d3.scaleOrdinal()
            .range(d3.schemeCategory20);

        var svg1 = d3.select("#itemProfit")
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .attr("id","barChartPlot");

        var g1 = svg1.append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");


        var xScale = d3.scaleBand().range([0, width]);  
        var yScale = d3.scaleLinear().range([height,0]);

        var xAxisG1 = g1.append("g").attr("transform", "translate(0, "+ height+")")
        var yAxsiG1 = g1.append("g")

        var xaxis1 = d3.axisBottom(xScale);
        var yaxis1 = d3.axisLeft(yScale).ticks(5); 

        var parse = d3.timeParse("%m");
        var format = d3.timeFormat("%b")

        const render = data =>{

            data.forEach(function(d){
                d.name = format(parse(d.month))
                d.value = d["2019"]
            })


            xScale.domain(data.map(function(d){ return d.name}))

            yScale.domain([0, d3.max(data, function(d) { return d.value; })])

            // console.log("here")
            var plot = g1.selectAll("rect")  
                .data(data)

            plot.exit().remove();

            plot.attr('width', xScale.bandwidth)
                .attr('height', function(d){ return height - yScale(d.value)})
                .attr('x', function(d){ return xScale(d.name)+2})
                .attr("y", function(d){ return yScale(d.value)})
                .attr("stroke", "white")
                .attr("stroke-width", "2px")

            plot.enter()
                    .append("rect")
                    .attr("x", function(d){return xScale(d.name)+2})
                    .attr("width", xScale.bandwidth)   
                    .attr("y", function(d) {
                        return yScale(d.value);
                    })  
                    .attr("height", function(d) {
                        return height-yScale(d.value);
                    })
                    .attr("fill", function(d){return color(d.name)})
                    .attr("stroke", "white")
                    .attr("stroke-width", "2px");



            var abc = xAxisG1.call(xaxis1);
            var yabc = yAxsiG1.call(yaxis1)

            abc.selectAll(".domain, .tick line").remove()
            yabc.selectAll(".domain, .tick line").remove()

        }
        render(data)
    }

    render() {
        return (
            <div>
                <div className="text-center mb-0">
                    <h5>Profit Chart</h5>
                </div>
                <div id="itemProfit"></div>
            </div>
        );
    }
}

ProfitChart.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    monthlyProfit: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
})

export default connect(mapStateToProps)(ProfitChart);