import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LineChart from './LineChart';
import DonutChart from './DonutChart';
import Scatter from './ScatterChart';
import GroupedBarChart from './GroupedBarChart';
import RequestLoadingData from '../../Loading/RequestLoadingData';
import { logoutUser } from '../../../redux/actions/authActions';
import { monthlySalesData, monthlySalesYearwiseData, top10ItemsBasedOnSales, weeklySalesProfit } from '../../../redux/actions/insights360Actions';
import isEmpty from '../../../validation/is-empty';

class InsightsGraphs extends Component {

    state = {
        loading: false
    }

    componentDidMount(){
        this.props.monthlySalesData()
        this.props.monthlySalesYearwiseData()
        this.props.top10ItemsBasedOnSales()
        this.props.weeklySalesProfit()
    }

    componentWillReceiveProps(nextProps){
        const { monthlySalesData, monthlySalesYearWise, top20ItemsBased, weeklySales } = nextProps.insights360;
        if(monthlySalesData !== null && monthlySalesYearWise !== null && top20ItemsBased !== null && weeklySales !== null) {
            this.setState({
                loading: true
            })
        }
    }

    render() {
        const { loading, monthlySalesData, monthlySalesYearWise, top20ItemsBased, weeklySales } = this.props.insights360;
        
        let insightsGraphs;

        if(monthlySalesData !== null && monthlySalesYearWise !== null && top20ItemsBased !== null && weeklySales !== null && this.state.loading){
            if(isEmpty(monthlySalesData) === true || isEmpty(monthlySalesYearWise) === true || isEmpty(top20ItemsBased) === true || isEmpty(weeklySales) === true){
                insightsGraphs = (
                    <div>
                        <p className="lead text-muted">Sorry</p>
                        <p>Something went wrong please try again after some time</p>
                    </div>
                );
            } else {
                insightsGraphs = (
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div className="card insight-card">
                                <div className="card-graph">
                                    <LineChart lineChart={monthlySalesData} />
                                </div>
                                <div className="d-flex justify-content-center card-graph-Content">
                                    Sales Chart
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div className="card insight-card">
                                <div className="card-graph">
                                    <GroupedBarChart groupedBarChart={monthlySalesYearWise} />
                                </div>
                                <div className="d-flex justify-content-center card-graph-Content">
                                    Sales yearly Comparision 
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div className="card insight-card">
                                <div className="card-graph">
                                    <DonutChart donutChart={top20ItemsBased} />
                                </div>
                                <div className="d-flex justify-content-center card-graph-Content">
                                    Top 10 Items ({new Date().getFullYear()})
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div className="card insight-card">
                                <div className="card-graph">
                                    <Scatter scatterChart={weeklySales} />
                                </div>
                                <div className="d-flex justify-content-center card-graph-Content">
                                    Week Wise Performance ({new Date().getFullYear()})
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        } else {
            insightsGraphs = <RequestLoadingData />
        }

        return (
            <div className="container-fluid">
                {insightsGraphs}
            </div>
        );
    }
}

InsightsGraphs.propTypes = {
    monthlySalesData : PropTypes.func.isRequired, 
    monthlySalesYearwiseData : PropTypes.func.isRequired, 
    top10ItemsBasedOnSales : PropTypes.func.isRequired, 
    weeklySalesProfit : PropTypes.func.isRequired,
    logoutUser : PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    insights360: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    insights360: state.insights360
})

export default connect(mapStateToProps, { monthlySalesData, monthlySalesYearwiseData, top10ItemsBasedOnSales, weeklySalesProfit, logoutUser })(InsightsGraphs);