import { CUSTOMER_ANALYSIS_LOADING, GET_CUSTOMER_ANALYSIS, POST_CUSTOMER_ANALYSIS, CLEAR_CURRENT_CUSTOMER_ANALYSIS } from '../actions/types';

const initialState = {
    loading: false,
    getCustomerMonthlyRevenue: null,
    postCustomerMonthlyRevenue: null
}

export default function(state = initialState, action) {
    switch(action.type) {
        case CUSTOMER_ANALYSIS_LOADING:
            return {
                ...state,
                loading: true
            }
        case GET_CUSTOMER_ANALYSIS:
            return {
                ...state,
                getCustomerMonthlyRevenue: action.payload,
                loading: false
            }
        case POST_CUSTOMER_ANALYSIS:
            return {
                ...state,
                postCustomerMonthlyRevenue: action.payload,
                loading: false
            }
        case CLEAR_CURRENT_CUSTOMER_ANALYSIS:
            return {
                ...state,
                loading: false,
                getCustomerMonthlyRevenue: null,
                postCustomerMonthlyRevenue: null
            }
        default:
            return state;
    }
}