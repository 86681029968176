import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { MDBInput } from 'mdbreact';
import classnames from 'classnames';
import { clientRegistration } from '../../redux/actions/clientRegistrationAction';

class ClientRegistration extends Component {

    constructor(){
        super();
        this.state = {
            fname: {
                value: '',
                valid: false
            },
            email: {
                value: '',
                valid: false
            },
            phoneNo: {
                value: '',
                valid: false
            },
            errors : {}
        }
    }

    changeHandler = e => {
        this.setState({ 
            [e.target.name]: { 
                value: e.target.value, 
                valid: !!e.target.value 
            } 
        });
    };

    onSubmit = e => {
        e.preventDefault();

        const clientDetails = {
            name: this.state.fname.value,
            clientOrgEmail: this.state.email.value,
            clientPhone: this.state.phoneNo.value,
            abbr: "SOMECLIENTABBR",
        }
        console.log(clientDetails)
        this.props.clientRegistration(clientDetails, this.props.history);
    }

    componentWillReceiveProps(nextProps){
        if(!nextProps.auth.isAuthenticated) {
            nextProps.history.push('/');
        }

        if(nextProps.errors){
            console.log(nextProps.errors)
        }
    }

    render() {

        const { errors } = this.state;

        return (
            <div className="container">
                <div className="client-registration">
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6 py-2">
                            <form noValidate onSubmit={this.onSubmit} autoComplete="off">
                                <div className="grey-text">
                                    <h5 className="display-5 text-center">Client Registration Form</h5>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-6 py-0 m-0">
                                        <MDBInput
                                            label="Organisation Name"
                                            className={classnames('', {
                                                'is-invalid': errors.fname
                                            })}
                                            icon="user"
                                            type="text"
                                            name="fname"
                                            onChange={this.changeHandler}
                                            value={this.state.fname.value}
                                        >
                                            {
                                                errors.fname ?
                                                    <div className="invalid-feedback">{errors.fname}</div>
                                                :
                                                null
                                            }
                                        </MDBInput>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-6 py-0 m-0">
                                        <MDBInput
                                            label="Organisation E-mail"
                                            className={classnames('', {
                                                'is-invalid': errors.email
                                            })}
                                            icon="envelope"
                                            type="email"
                                            name="email"
                                            onChange={this.changeHandler}
                                            value={this.state.email.value}
                                        >
                                            {
                                                errors.email ?
                                                    <div className="invalid-feedback">{errors.email}</div>
                                                :
                                                null
                                            }
                                        </MDBInput>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-6 py-0 m-0">
                                        <MDBInput
                                            label="Phone Number"
                                            className={classnames('', {
                                                'is-invalid': errors.email
                                            })}
                                            icon="mobile-alt"
                                            type="text"
                                            name="phoneNo"
                                            onChange={this.changeHandler}
                                            value={this.state.phoneNo.value}
                                        >
                                            {
                                                errors.email ?
                                                    <div className="invalid-feedback">{errors.phoneNo}</div>
                                                :
                                                null
                                            }
                                        </MDBInput>
                                        </div>
                                    </div>
                                    <button className="btn morpheus-den-gradient btn-rounded btn-block my-2 waves-effect text-white" type="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 py-2">

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ClientRegistration.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
})

export default connect(mapStateToProps, { clientRegistration })(withRouter(ClientRegistration));