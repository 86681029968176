import React, { Component } from 'react';
import { HOC } from './Hoc';
import Top from './Top';
import KeyQueries from './KeyQueries';
import { NeedOfAAP, NeedOfAAP2} from './Sections';
import AboutAAP from './AboutAAP';
import ThingsBegin from './ThingsBegin';
import Team from './Team';
// import Location from './Location';
import Footer from './Footer';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

class Home extends Component {
    render() {
        return (
            <HOC className="welcome">
                <Top />
                <KeyQueries />
                <NeedOfAAP />
                <NeedOfAAP2 />
                <AboutAAP />
                {/* <Team /> */}
                <ThingsBegin />
                {/* <ClientsReviw /> */}
                {/* <Location /> */}
                <Footer />
            </HOC>
        );
    }
}

Home.propTypes = {
    auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps)(Home);