import { CASHFLOW_TIMESERIES_LOADING, CASHFLOW_TIMESERIES_DETAILS, CLEAR_CURRENT_CASHFLOW_TIMESERIES } from '../actions/types';

const initialState = {
    loading: false,
    cashflowTimeSeriesDetails: null
}

export default function(state = initialState, action) {
    switch(action.type) {
        case CASHFLOW_TIMESERIES_LOADING:
            return {
                ...state,
                loading: true
            }
        case CASHFLOW_TIMESERIES_DETAILS:
            return {
                ...state,
                cashflowTimeSeriesDetails: action.payload,
                loading: false
            }
        case CLEAR_CURRENT_CASHFLOW_TIMESERIES:
            return {
                ...state,
                ...initialState
            }
        default:
            return state;
    }
}