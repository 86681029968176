import React, { Component } from 'react';
import Zoom from 'react-reveal/Zoom';

class ThingsBegin extends Component {

    state = {
        icon:['fas fa-cloud', 'fas fa-chart-area', 'fas fa-tools'],
        title: ['Cloud based platform', 'Subscription model', 'No fixed contract'],
        delay: [500, 0, 500]
    }

    whyMy360 = () => (
        this.state.icon.map((a,i) => (
            <Zoom key={i} delay={this.state.delay[i]}>
                <div className="col-md-4 platform-begin">
                    <div className="card hoverable">
                        <div className="icon">
                            <div className="image"><i className={this.state.icon[i]}></i></div>
                        </div>
                        <div className="description">
                            <h5 className="text-capitalize">{this.state.title[i]}</h5>
                        </div>
                    </div>
                </div>
            </Zoom>
        ))
    )

    render() {
        return (
            <section className="container-fluid homepage platform">
                <div className="container">
                    <div className="review-ctnr">
                        <div className="row mb-4 section-heading">
                            <div className="col">
                                <h1>Why My360</h1>
                                <p className="mt-3"></p>
                            </div>
                        </div>
                        <div className="row text-center">
                            {this.whyMy360()}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ThingsBegin;