// import React, { Component } from "react";
// import PropTypes from "prop-types";
// import { connect } from "react-redux";
// import RequestLoadingData from "../../../Loading/RequestLoadingData";
// import {
//   getMonthlyInflowOutflow,
//   getCumInflowOutflowBalance,
//   getMonthlyCashBalance,
// } from "../../../../redux/actions/cashflow360Actions";
// import isEmpty from "../../../../validation/is-empty";
// import Kpi360 from "./Kpi360";
// import MonthlyCashBalance from "./MonthlyCashBalance";
// import CumInflowOutflowBalance from "./CumInflowOutflowBalance";
// import MonthlyInflowOutflow from "./MonthlyInflowOutflow";

// class Cashflow360 extends Component {
//   componentDidMount() {
//     this.props.getMonthlyInflowOutflow();
//     this.props.getCumInflowOutflowBalance();
//     this.props.getMonthlyCashBalance();
//   }

//   render() {
//     const {
//       loading,
//       monthlyInflowOutflow,
//       cumInflowOutflow,
//       monthlyCashBalance,
//     } = this.props.cashflow360;

//     let cashflow360;
//     if (
//       monthlyInflowOutflow !== null &&
//       cumInflowOutflow !== null &&
//       monthlyCashBalance !== null
//     ) {
//       if (
//         isEmpty(monthlyInflowOutflow) === true ||
//         isEmpty(cumInflowOutflow) === true ||
//         isEmpty(monthlyCashBalance) === true
//       ) {
//         cashflow360 = (
//           <div>
//             <p className="lead text-muted">Sorry</p>
//             <p>Something went wrong please try again after some time</p>
//           </div>
//         );
//       } else {
//         cashflow360 = (
//           <div className="row">
//             <div className="col-md-12 col-lg-6">
//               <div className="card Cashflow360">
//                 <div className="d-flex justify-content-center card-graph-Content">
//                   Aggregated Monthly Cash Balance
//                 </div>
//                 <div className="card-graph">
//                   <MonthlyCashBalance monthlyCashBalance={monthlyCashBalance} />
//                 </div>
//               </div>
//             </div>
//             <div className="col-md-12 col-lg-6">
//               <div className="card Cashflow360">
//                 <div className="d-flex justify-content-center card-graph-Content">
//                   Cumulative Inflow Outflow
//                 </div>
//                 <div className="card-graph">
//                   <CumInflowOutflowBalance
//                     cumInflowOutflow={cumInflowOutflow}
//                   />
//                 </div>
//               </div>
//             </div>
//             <div className="col-md-12">
//               <div className="card Cashflow360">
//                 <div className="d-flex justify-content-center card-graph-Content">
//                   Monthly Cash Inflow Outflow
//                 </div>
//                 <div className="card-graph">
//                   <MonthlyInflowOutflow
//                     monthlyInflowOutflow={monthlyInflowOutflow}
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         );
//       }
//     } else {
//       cashflow360 = <RequestLoadingData />;
//     }

//     return (
//       <div className="container-fluid">
//         <Kpi360 />
//         {cashflow360}
//       </div>
//     );
//   }
// }

// Cashflow360.propTypes = {
//   getMonthlyInflowOutflow: PropTypes.func.isRequired,
//   getCumInflowOutflowBalance: PropTypes.func.isRequired,
//   getMonthlyCashBalance: PropTypes.func.isRequired,
//   cashflow360: PropTypes.object.isRequired,
// };

// const mapStateToProps = (state) => ({
//   cashflow360: state.cashflow360,
// });

// export default connect(mapStateToProps, {
//   getMonthlyInflowOutflow,
//   getCumInflowOutflowBalance,
//   getMonthlyCashBalance,
// })(Cashflow360);

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import RequestLoadingData from "../../../Loading/RequestLoadingData";
import {
  getMonthlyInflowOutflow,
  getCumInflowOutflowBalance,
  getMonthlyCashBalance,
} from "../../../../redux/actions/cashflow360Actions";
import isEmpty from "../../../../validation/is-empty";
import Kpi360 from "./Kpi360";
import MonthlyCashBalance from "./MonthlyCashBalance";
import CumInflowOutflowBalance from "./CumInflowOutflowBalance";
import MonthlyInflowOutflow from "./MonthlyInflowOutflow";

// Utility function to check for undefined and null
const isValueEmpty = (value) =>
  value === null || value === undefined || isEmpty(value);

class Cashflow360 extends Component {
  componentDidMount() {
    const {
      getMonthlyInflowOutflow,
      getCumInflowOutflowBalance,
      getMonthlyCashBalance,
    } = this.props;
    getMonthlyInflowOutflow();
    getCumInflowOutflowBalance();
    getMonthlyCashBalance();
  }

  render() {
    const {
      loading,
      monthlyInflowOutflow,
      cumInflowOutflow,
      monthlyCashBalance,
    } = this.props.cashflow360;

    // Debug logs
    console.log("Loading:", loading);
    console.log("Monthly Inflow Outflow:", monthlyInflowOutflow);
    console.log("Cumulative Inflow Outflow:", cumInflowOutflow);
    console.log("Monthly Cash Balance:", monthlyCashBalance);

    let content;
    if (loading) {
      content = <RequestLoadingData />;
    } else if (
      isValueEmpty(monthlyInflowOutflow) ||
      isValueEmpty(cumInflowOutflow) ||
      isValueEmpty(monthlyCashBalance)
    ) {
      content = (
        <div>
          <p className="lead text-muted">Sorry</p>
          <p>Something went wrong. Please try again later.</p>
        </div>
      );
    } else {
      content = (
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <div className="card Cashflow360">
              <div className="d-flex justify-content-center card-graph-Content">
                Aggregated Monthly Cash Balance
              </div>
              <div className="card-graph">
                <MonthlyCashBalance
                  monthlyCashBalance={
                    monthlyCashBalance ? monthlyCashBalance : []
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6">
            <div className="card Cashflow360">
              <div className="d-flex justify-content-center card-graph-Content">
                Cumulative Inflow Outflow
              </div>
              <div className="card-graph">
                <CumInflowOutflowBalance
                  cumInflowOutflow={cumInflowOutflow ? cumInflowOutflow : []}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="card Cashflow360">
              <div className="d-flex justify-content-center card-graph-Content">
                Monthly Cash Inflow Outflow
              </div>
              <div className="card-graph">
                <MonthlyInflowOutflow
                  monthlyInflowOutflow={
                    monthlyInflowOutflow ? monthlyInflowOutflow : []
                  }
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="container-fluid">
        <Kpi360 />
        {content}
      </div>
    );
  }
}

Cashflow360.propTypes = {
  getMonthlyInflowOutflow: PropTypes.func.isRequired,
  getCumInflowOutflowBalance: PropTypes.func.isRequired,
  getMonthlyCashBalance: PropTypes.func.isRequired,
  cashflow360: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    monthlyInflowOutflow: PropTypes.any,
    cumInflowOutflow: PropTypes.any,
    monthlyCashBalance: PropTypes.any,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  cashflow360: state.cashflow360,
});

const mapDispatchToProps = {
  getMonthlyInflowOutflow,
  getCumInflowOutflowBalance,
  getMonthlyCashBalance,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cashflow360);
