import axios from 'axios';
import { CHATBOT_URL, CHATBOT_LOADING, CHATBOT, CLEAR_CURRENT_CHATBOT } from './types';

/*--- ChatBot ---*/
export const chatBot = (textMsg, stages, sender = 'user') => dispatch => {
    dispatch(getChatBotLoding());
    if(stages.stage === 'chat') {
        const userRequest = {
            sender: sender,
            message: textMsg.message
        }
        dispatch({
            type: CHATBOT,
            payload: userRequest
        })
    }
    axios.post(`${CHATBOT_URL}/webhooks/rest/webhook`, textMsg)
        .then(res => {
            const botResponse = {
                sender: 'bot',
                message: res.data[0].text
            }
            dispatch({
                type: CHATBOT,
                payload: botResponse
            })
        })
        .catch(err => {
            console.log(err)
        })
}

/*--- Loading ChatBot Data ---*/
export const getChatBotLoding = () => {
    return {
        type: CHATBOT_LOADING
    }
}

/*--- Clear ChatBot Data ---*/
export const clearCurrentChatBot = () => {
    return {
        type: CLEAR_CURRENT_CHATBOT
    }
}