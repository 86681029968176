import axios from 'axios';
import { ANALYTICS_URL, ACCESS_TOKEN, CLIENT_ID, CUSTOMER_ANALYSIS_LOADING, GET_CUSTOMER_ANALYSIS, POST_CUSTOMER_ANALYSIS, CLEAR_CURRENT_CUSTOMER_ANALYSIS } from './types';
import { logoutUser } from './authActions';

/*--- Get Customer Monthly Revenue ---*/
export const getCustomerMonthlyRevenue = () => dispatch => {
    dispatch(getCustomerAnalysisLoding());
    axios.get(`${ANALYTICS_URL}/cash_flow/customer_monthly_revenue/${CLIENT_ID()}`, ACCESS_TOKEN())
        .then(res => {
            dispatch({
                type: GET_CUSTOMER_ANALYSIS,
                payload: res.data
            })
        })
        .catch(err =>{
            dispatch({
                type: GET_CUSTOMER_ANALYSIS,
                payload: {}
            })
            if(err.response && err.response.status === 401){
                dispatch(logoutUser());
            }
        })
}

/*--- Post Customer Monthly Revenue ---*/
export const postCustomerMonthlyRevenue = (customerValue) => dispatch => {
    axios.post(`${ANALYTICS_URL}/cash_flow/customer_monthly_revenue/${CLIENT_ID()}`, customerValue, ACCESS_TOKEN())
        .then(res => {
            dispatch({
                type: POST_CUSTOMER_ANALYSIS,
                payload: res.data
            })
        })
        .catch(err =>{
            dispatch({
                type: POST_CUSTOMER_ANALYSIS,
                payload: {}
            })
            if(err.response && err.response.status === 401){
                dispatch(logoutUser());
            }
        })
}

/*--- Loading Customer Analysis Data ---*/
export const getCustomerAnalysisLoding = () => {
    return {
        type: CUSTOMER_ANALYSIS_LOADING
    }
}

/*--- Clear Customer Analysis Data ---*/
export const clearCurrentCustomerAnalysis = () => {
    return {
        type: CLEAR_CURRENT_CUSTOMER_ANALYSIS
    }
}