import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as d3 from 'd3';

class SalesChart extends Component {

    componentDidMount(){
        // console.log(this.props.monthlySales)
        this.barupdate(this.props.monthlySales)
    }

    barupdate = (data) => {

        const width = 600,
        height = 350,
        chartRadius = height / 2 - 50;

        const colorscheme = d3.scaleOrdinal(d3.schemeCategory10);

        let svg = d3.select('#itemSales').append('svg')
        .attr('width', width)
        .attr('height', height)
        .append('g')
            .attr('transform', 'translate(' +( width / 2 -35)+ ',' + (height / 2-45) + ')');


        const PI = Math.PI,
        arcMinRadius = 20,
        arcPadding = 10,
        labelPadding = -5,
        numTicks = 10;


        let scale = d3.scaleLinear().range([0, 2 * PI]);
        let tooltip = d3.select('body').append('div')
                        .attr('class', 'tooltip');
                        
        var parse = d3.timeParse("%m");
        var format = d3.timeFormat("%b")

        const render = data =>{
        
            // console.log(data)

            data.forEach(function(d){
                d.name = format(parse(d.month))
                d.value = d["2019"]
            })

            function sortAsending(a, b)
            {
                return b.value - a.value;
            }

            data=data.sort(sortAsending);
            scale.domain([0, d3.max(data, d => d.value) * 1.1])

            let ticks = scale.ticks(numTicks).slice(0, -1);
            // console.log(scale.ticks(numTicks))
            let keys = data.map((d, i) => d.name);
            
            //number of arcs
            const numArcs = keys.length;
            const arcWidth = (chartRadius - arcMinRadius - numArcs * arcPadding) / numArcs;

            let arc = d3.arc()
                .innerRadius((d, i) => getInnerRadius(i))
                .outerRadius((d, i) => getOuterRadius(i))
                .startAngle(0)
                .endAngle((d, i) => scale(d))

            let radialAxis = svg.append('g')
                .attr('class', 'r axis')
                .selectAll('g')
                .data(data)
                .enter().append('g');

            radialAxis.append('circle')
                .attr('r', (d, i) => getOuterRadius(i) + arcPadding);

            radialAxis.append('text')
                .attr('x', labelPadding)
                .attr('y', (d, i) => -getOuterRadius(i) + arcPadding)
                .style("font-size", "10px")
                .attr("fill", "black")
                .style("font-family", "Arial")
                .text(d => d.name);

            let axialAxis = svg.append('g')
                .attr('class', 'a axis')
                .selectAll('g')
                .data(ticks)
                .enter().append('g')
                    .attr('transform', d => 'rotate(' + (rad2deg(scale(d)) - 90) + ')');

            axialAxis.append('line')
                .attr('x2', chartRadius);

            axialAxis.append('text')
                .attr('x', chartRadius + 10)
                .style('text-anchor', d => (scale(d) >= PI && scale(d) < 2 * PI ? 'end' : null))
                .style("font-size", "10px")
                .attr('transform', d => 'rotate(' + (90 - rad2deg(scale(d))) + ',' + (chartRadius + 10) + ',0)')
                .text(d => d3.format(".3s")(d));

            //data arcs
            var arcg = svg.append('g')
                .attr('class', 'data')

            let arcs = svg.append('g').attr('class', 'data')
                .selectAll('g')
                .data(data)    
                .enter()
                .append('path')
                .style('fill', (d, i) => colorscheme(i))         
                //.attr("d", arc)
                .attr('class', 'arc')
                arcs.exit().remove()


            arcs.transition()
                .delay((d, i) => i * 200)
                .duration(1000)
                .attrTween('d', arcTween);

            arcs.on('mousemove', showTooltip)
            arcs.on('mouseout', hideTooltip)


            function arcTween(d, i) {
                let interpolate = d3.interpolate(0, d.value);
                return t => arc(interpolate(t), i);
            }

            function showTooltip(d) {
                tooltip.style('left', (d3.event.pageX + 10) + 'px')
                .style('top', (d3.event.pageY - 25) + 'px')
                .style('display', 'inline-block')
                .html(d3.format(".3s")(d.value));
            }

            function hideTooltip() {
                tooltip.style('display', 'none');
            }

            function rad2deg(angle) {
                return angle * 180 / PI;
            }

            function getInnerRadius(index) {
                return arcMinRadius + (numArcs - (index + 1)) * (arcWidth + arcPadding);
            }

            function getOuterRadius(index) {
                return getInnerRadius(index) + arcWidth;
            }


        }
        render(data)
    }

    render() {
        return (
            <div>
                <div className="text-center mb-0">
                    <h5>Sales Chart</h5>
                </div>
                <div id="itemSales" className="itemSalesTimeSeries"></div>
            </div>
        );
    }
}

SalesChart.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    monthlySales: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
})

export default connect(mapStateToProps)(SalesChart);