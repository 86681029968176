import React from 'react';

const Image_slide = () => {
    return (
        <ul className="cb-slideshow">
            <li><span>Image 01</span><div><h3>Data Analytics</h3></div></li>
            <li><span>Image 02</span><div><h3>Quantitative Analysis</h3></div></li>
            <li><span>Image 03</span><div><h3>Statistics</h3></div></li>
            <li><span>Image 04</span><div><h3>Prediction</h3></div></li>
            <li><span>Image 05</span><div><h3>Data Mining</h3></div></li>
            <li><span>Image 06</span><div><h3>Visualization</h3></div></li>
        </ul>
    );
};

export default Image_slide;