import React from 'react';
import CsvDownloader from 'react-csv-downloader';

const CsvFileDownloader = (props) => {
    return (
        <CsvDownloader
            filename={props.name}
            separator=","
            columns={props.result[0].column}
            datas={props.result[1].datas}
        >
            <button className="csvFileDownloader">{props.name}</button>
        </CsvDownloader>
    );
};

export default CsvFileDownloader;