import axios from 'axios';
import { ANALYTICS_URL, CLIENT_ID, ACCESS_TOKEN, GET_ERRORS, TIME_SERIES_PREDICTION_LOADING, 
    GET_HIST_AND_PRED_DATA, GET_NEXT_SEVENDAYS_PRED_DATA, GET_CURRENT_NEXT_MONTH_PRED_DATA, 
    CLEAR_CURRENT_TIME_SERIES_PREDICTION } from './types';
import { logoutUser } from '../actions/authActions';

/*--- Hist And PredData ---*/
export const getHistAndPredData = () => dispatch => {
    dispatch(getTimeSeriesPredictionLoading());
    axios.get(`${ANALYTICS_URL}/retail/GetHistAndPredData/${CLIENT_ID()}`, ACCESS_TOKEN())
        .then(res => {
            dispatch({
                type: GET_HIST_AND_PRED_DATA,
                payload : res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_HIST_AND_PRED_DATA,
                payload: {}
            })
            if(err.response && err.response.status === 401){
                dispatch(logoutUser());
            }
        })
}

/*--- Next Sevendays PredData ---*/
export const getNextSevedaysPredData = () => dispatch => {
    dispatch(getTimeSeriesPredictionLoading());
    axios.get(`${ANALYTICS_URL}/retail/GetNextSevedaysPredData/${CLIENT_ID()}`, ACCESS_TOKEN())
        .then(res => {
            dispatch({
                type: GET_NEXT_SEVENDAYS_PRED_DATA,
                payload : res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_NEXT_SEVENDAYS_PRED_DATA,
                payload: {}
            })
            if(err.response && err.response.status === 401){
                dispatch(logoutUser());
            }
        })
}

/*--- Current NextMonth PredData ---*/
export const getCurrentNextMonthPredData = () => dispatch => {
    dispatch(getTimeSeriesPredictionLoading());
    axios.get(`${ANALYTICS_URL}/retail/GetCurrentNextMonthPredData/${CLIENT_ID()}`, ACCESS_TOKEN())
        .then(res => {
            dispatch({
                type: GET_CURRENT_NEXT_MONTH_PRED_DATA,
                payload : res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_CURRENT_NEXT_MONTH_PRED_DATA,
                payload: {}
            })
            if(err.response && err.response.status === 401){
                dispatch(logoutUser());
            }
        })
}

/*--- Time Series Prediction Loading ---*/
export const getTimeSeriesPredictionLoading = () => {
    return {
        type: TIME_SERIES_PREDICTION_LOADING
    }
}

/*--- Clear Time Series Prediction ---*/
export const clearCurrentTimeSeriesPrediction = () => {
    return {
        type: CLEAR_CURRENT_TIME_SERIES_PREDICTION
    }
}