import LoginImg from '../../Resources/images/GuideSlide/Login.PNG';
import RegisterImg from '../../Resources/images/GuideSlide/Register.PNG';
import My360 from '../../Resources/images/GuideSlide/My360.png';

import SelectDomain from '../../Resources/images/GuideSlide/selectDomain.png';

import clientRegistration from '../../Resources/images/GuideSlide/clientRegistration.png';
import DownloadTemplate from '../../Resources/images/GuideSlide/DownloadTemplate.png';

import UploadItemFile from '../../Resources/images/GuideSlide/uploadFileItem.png';
import UploadSalesFile from '../../Resources/images/GuideSlide/uploadFileSales.png';

import Insights360 from '../../Resources/images/GuideSlide/dataUploaded-360View.png';
import Timeseries from '../../Resources/images/GuideSlide/dataUploaded-timeSeries.png';
import Abc from '../../Resources/images/GuideSlide/dataUploaded-ABC.png';
import ItemAnalysis from '../../Resources/images/GuideSlide/dataUploaded-ItemAnalysis.png';
import TimeSeriesPrediction from '../../Resources/images/GuideSlide/dataUploaded-timeSeriesPrediction.png';
import MarketBasketAnalysis from '../../Resources/images/GuideSlide/dataUploaded-MarketBasket.png';

export const caurosel = [
    {
        _id: 1,
        index: 0,
        picture: RegisterImg,
        Title: "Register"
    },
    {
        _id: 2,
        index: 1,
        picture: LoginImg,
        Title: "Login"
    },
    {
        _id: 3,
        index: 2,
        picture: My360,
        Title: "My360 Visuals"
    }
]

export const Step1 = [RegisterImg, LoginImg]

export const Step2 = [SelectDomain, Insights360, Timeseries, Abc, ItemAnalysis, TimeSeriesPrediction, MarketBasketAnalysis]

export const Step3 = [clientRegistration, DownloadTemplate]

export const Step4 = [UploadItemFile, UploadSalesFile]

export const Step5 = [Insights360, Timeseries, Abc, ItemAnalysis, TimeSeriesPrediction, MarketBasketAnalysis]