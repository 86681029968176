import isEmpty from '../../../validation/is-empty';
import Validator from 'validator';

export default function validateLoginInput(data) {
    let errors = {};

    data.email.value = !isEmpty(data.email.value) ? data.email.value : '';
    data.password.value = !isEmpty(data.password.value) ? data.password.value : '';

    if(!Validator.isEmail(data.email.value)) {
        errors.email = 'Email is Invalid';
    }

    if(Validator.isEmpty(data.email.value)) {
        errors.email = 'Email field is required';
    }

    if(Validator.isEmpty(data.password.value)) {
        errors.password = 'Password field is required';
    }

    return {
        errors,
        isValid: isEmpty(errors)
    }
}