import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Zoom from "react-reveal/Zoom";
import CountUp from "react-countup";
import { getRevenueExpenseKpi } from "../../../../redux/actions/cashflow360Actions";
import isEmpty from "../../../../validation/is-empty";

class Kpi360 extends Component {
  state = {
    cardTitleName: [],
    icons: [
      "fa-hand-holding-usd",
      "fa-money-check-alt",
      "fa-wallet",
      "fa-coins",
    ],
    cardTitleNumberStart: [0, 0, 0, 0],
    cardTitleNumberEnd: [],
    delay: [0, 100, 200, 300],
    counting: true,
    yearData: "",
  };

  componentDidMount() {
    this.props.getRevenueExpenseKpi();
  }

  componentWillReceiveProps(nextProps) {
    const { revenueExpenseKpi } = nextProps.cashflow360;

    if (revenueExpenseKpi && revenueExpenseKpi.data) {
      if (!isEmpty(revenueExpenseKpi.data)) {
        const key = Object.keys(revenueExpenseKpi.data) || [];
        const value = Object.values(revenueExpenseKpi.data) || [];

        this.setState({
          yearData: revenueExpenseKpi.year || "",
          cardTitleName: [...key],
          cardTitleNumberEnd: [...value],
        });
      }
    } else {
      console.warn(
        "revenueExpenseKpi or revenueExpenseKpi.data is null or undefined"
      );
    }
  }

  formatTitle = (title) => {
    const formattedTitle = title
      .split("_")
      .map((word) => word[0].toUpperCase() + word.substr(1).toLowerCase())
      .join(" ");

    if (formattedTitle === "Monthly Avg Revenue")
      return "Monthly Average Revenue";
    if (formattedTitle === "Monthly Avg Expense")
      return "Monthly Average Expense";

    return formattedTitle;
  };

  cashflowKpi = () => {
    const {
      cardTitleName,
      icons,
      cardTitleNumberEnd,
      yearData,
      counting,
      delay,
    } = this.state;

    return cardTitleName.map((title, i) => (
      <Zoom key={i} delay={delay[i]}>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3 cashflow">
          <div className="card">
            <div className="d-flex justify-content-around">
              <div className="icon">
                <i className={`fas ${icons[i]}`}></i>
              </div>
              <div className="card-body">
                <p className="card-title">
                  {this.formatTitle(title)}
                  <span className="card-titleYear"> - ({yearData})</span>
                </p>
                <h3 className="card-title-number">
                  {counting ? (
                    <CountUp
                      start={0}
                      end={cardTitleNumberEnd[i] || 0}
                      prefix="₹ "
                      onEnd={() => this.setState({ counting: false })}
                    />
                  ) : (
                    `₹ ${new Intl.NumberFormat("en-IN").format(
                      cardTitleNumberEnd[i] || 0
                    )}`
                  )}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </Zoom>
    ));
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row kpi-360 cashflow-360">{this.cashflowKpi()}</div>
      </div>
    );
  }
}

Kpi360.propTypes = {
  getRevenueExpenseKpi: PropTypes.func.isRequired,
  cashflow360: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  cashflow360: state.cashflow360,
});

export default connect(mapStateToProps, { getRevenueExpenseKpi })(Kpi360);
