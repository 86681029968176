import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function DropLink({ to, name, onClick }) {
    return (
        <Link className="dropdown-item" to={to} onClick={onClick}>{name}</Link>
    )
}

DropLink.propTypes = {
    to: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func
}

export default DropLink;