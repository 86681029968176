import React, { Component } from 'react';
import * as d3 from 'd3';
import $ from 'jquery';

class PredictionChart extends Component {

    componentDidMount() {
        console.log(this.props.prediction)
        this.PredictionChart(this.props.prediction)
    }

    PredictionChart = (data) => {
        var cwidth = $("#pchart").width();
        var cheight = $("#pchart").height();

        var margin = { top: 50, right: 20, bottom: 60, left: 60 },
            width = cwidth - margin.left - margin.right,
            height = cheight - margin.top - margin.bottom;

        var x = d3.scaleTime().range([0, width]);
        var y = d3.scaleLinear().range([height, 0]);

        var area_data = d3.area()
            .x(function (d) { return x(d.date) })
            .y0(function (d) { return y(d.open) })
            .y1(function (d) { return y(d.close) })


        var valueline3 = d3.line()
            .x(function (d) { return x(d.date); })
            .y(function (d) { return y(d.actual); });

        var valueline4 = d3.line()
            .x(function (d) { return x(d.date); })
            .y(function (d) { return y(d.y); });


        var svg1 = d3.select("#pchart").append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)

        svg1.append("rect")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .attr("fill", "none")



        var svg = svg1.append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");

        svg.append("text")
            .attr("x", (width / 2))

            .attr("y", 0 - (margin.top / 2))
            .attr("class", "chart_title")
            .text("Prediction Chart");

        const render = (data) => {

            // if (error) throw error;

            // parse the date / time
            var parseTime = d3.timeParse("%d-%m-%Y");

            var array1 = data[0]["history"];
            var array2 = data[0]["prediction"];
            
            console.log(array1)


            // format the data
            array1.forEach(function (d) {
                d.date = parseTime(d.ds);
                d.open = 0;
                d.close = 0;
                d.actual = 0;
                d.y = +d.y;
            });
            array2.forEach(function (d) {
                d.date = parseTime(d.ds);
                d.open = +d.yhat_lower;
                d.close = +d.yhat_upper;
                d.actual = +d.yhat;
                d.y = 0;
            });
            var data1 = array1.concat(array2)
            console.log("check: ", data1)

            x.domain(d3.extent(data1, function (d) { return d.date; }));
            y.domain([0, d3.max(data1, function (d) {
                return Math.max(d.close, d.open, d.actual, d.y);
            })]);

      
            // Add the X Axis
            var xaxis = svg.append("g")
                .attr("transform", "translate(0," + height + ")")
                .call(d3.axisBottom(x).tickFormat(d3.timeFormat("%b %Y")));

            xaxis.selectAll(".domain, .tick line").remove()

            // Add the Y Axis
            var yaxis = svg.append("g")
                .attr("class", "yaxis")
                .call(d3.axisLeft(y).tickSize(-width));


            yaxis.selectAll(".domain").remove()


            svg.append("path")
                .data([array2])
                .attr("class", "timepredictionarea")
                .attr("d", area_data)

            //Add the valueline path.
            // svg.append("path")
            //   .data([array2])
            //   .attr("class", "line")
            //   .style("stroke", "steelblue")
            //   .attr("d", valueline);

            // // Add the valueline2 path.
            // svg.append("path")
            //   .data([array2])
            //   .attr("class", "line")
            //   .style("stroke", "steelblue")
            //   .attr("d", valueline2);

            svg.append("path")
                .data([array2])
                .attr("class", "line")
                .style("stroke", "#FFA07A")
                .attr("d", valueline3);


            svg.selectAll("circle")
                .data(array2)
                .enter()
                .append("circle")
                .attr("r", "2.5px")
                .attr("cx", function (d) { return x(d.date) })
                .attr("cy", function (d) { return y(d.actual) })
                .attr("stroke", "#FFA07A")
                .attr("fill", "white")

            svg.append("path")
                .data([array1])
                .attr("class", "line")
                //.style("stroke", "red")
                .attr("d", valueline4);

            svg.selectAll(".circle")
                .data(array1)
                .enter()
                .append("circle")
                .attr("r", 2.5)
                .attr("cx", function (d) { return x(d.date) })
                .attr("cy", function (d) { return y(d.y) })
                .attr("stroke", "steelblue")
                .attr("fill", "white");


        }
        render(data);
    }

    componentWillUnmount() {
        d3.select("svg").remove();
    }




    render() {
        return (
            <div id="pchart">

            </div>
        )
    }
}
export default PredictionChart;