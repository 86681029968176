import axios from 'axios';
import { ANALYTICS_URL, CLIENT_ID, ACCESS_TOKEN, ABC_LOADING, GET_SALES_ABC_CATDATA, 
        GET_SALES_AND_PROFIT_ABC_SUMMARY, GET_ABCA_ACAT_TABLE, CLEAR_CURRENT_ABC } from './types';
import { logoutUser } from '../actions/authActions';

/*--- Abc Sales CatData ---*/
export const getSalesAbcCatData = () => dispatch => {
    dispatch(getAbcDataLoading());
    axios.get(`${ANALYTICS_URL}/retail/GetSalesAbcCatData/${CLIENT_ID()}`, ACCESS_TOKEN())
        .then(res => {
            dispatch({
                type: GET_SALES_ABC_CATDATA,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_SALES_ABC_CATDATA,
                payload: {}
            })
            if(err.response && err.response.status === 401){
                dispatch(logoutUser());
            }
        })
}

/*--- Abc Sales and Profit ---*/
export const getSalesAndProfitSummary = () => dispatch => {
    dispatch(getAbcDataLoading());
    axios.get(`${ANALYTICS_URL}/retail/getSalesAndProfitAbcSummary/${CLIENT_ID()}`, ACCESS_TOKEN())
        .then(res => {
            dispatch({
                type: GET_SALES_AND_PROFIT_ABC_SUMMARY,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_SALES_AND_PROFIT_ABC_SUMMARY,
                payload: {}
            })
            if(err.response && err.response.status === 401){
                dispatch(logoutUser());
            }
        })
}

/*--- Abc Cat Table ---*/
export const getCatTable = () => dispatch => {
    dispatch(getAbcDataLoading());
    axios.get(`${ANALYTICS_URL}/retail/GetAbcaACatTable/${CLIENT_ID()}`, ACCESS_TOKEN())
        .then(res => {
            dispatch({
                type: GET_ABCA_ACAT_TABLE,
                payload : res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ABCA_ACAT_TABLE,
                payload: {}
            })
            if(err.response && err.response.status === 401){
                dispatch(logoutUser());
            }
        })
}

/*--- Abc Data Loading ---*/
export const getAbcDataLoading = () => {
    return {
        type: ABC_LOADING
    }
}

/*--- Clear Abc ---*/
export const clearCurrentAbc = () => {
    return {
        type: CLEAR_CURRENT_ABC
    }
}