import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

class CumInflowOutflowBalance extends Component {

    componentDidMount() {
        this.cumInflowOutflowBalance(this.props.cumInflowOutflow)
    }

    cumInflowOutflowBalance = data => {
        var margin = {top:20, bottom:50, right:10, left:30},
            height = 350 - margin.top - margin.bottom,
            width = 600 - margin.right - margin.left;

        var svg = d3.select("#cumInflowOutflowBalance").append("svg")
                    .attr("width", width + margin.right + margin.left)
                    .attr("height", height + margin.top + margin.bottom)

        var g = svg.append("g")
                    .attr("transform", "translate("+ margin.left +", "+ margin.top+")")

        var x = d3.scaleBand()
                .range([0, width])

        var y = d3.scaleLinear()
                .range([height, 0])

        var color=d3.scaleOrdinal()
            .range(["green","red","skyblue"])

        var date_parse = d3.timeParse("%b-%Y")
        var date_format = d3.timeFormat("%b-%y")

        var xaxis = d3.axisBottom(x)
                    .tickFormat(date_format)

        var yaxis = d3.axisLeft(y)
                        .tickFormat(d3.format(".2s"));

        const render = data => {
            data.forEach((d)=>{
                d.mm_yyyy = date_parse(d.mm_yyyy)
            })

            x.domain(data.map(d=> d.mm_yyyy))

            var yMax=d3.max([d3.max(data, function(d){return d.inflow_cumsum}), d3.max(data, function(d){return d.outflow_cumsum})])
            var yMin=d3.min([d3.min(data, function(d){return d.inflow_cumsum}), d3.min(data, function(d){return d.outflow_cumsum}), d3.min(data, function(d){return d.cash_balance})])
            y.domain([yMin, yMax])

            var xaxisG = g.append("g")
                .attr("transform", "translate(0, "+height+")")
                .call(xaxis.tickValues(x.domain().filter(function(d,i){ return !(i%2)})))

            xaxisG.selectAll(".domain").remove()


            var yaxisG = g.append("g")
                .attr("transform", "translate(0, 0)")
                .call(yaxis.tickSize(-width))

            yaxisG.selectAll(".domain").remove()

            g.append("path")
                .data([data])
                .attr("fill", "none")
                .attr('class', 'cash_line')
                .attr("stroke", "black")
                .attr("stroke-width", 2.5)
                .attr("d", d3.line()
                                .x(function(d) { return x(d.mm_yyyy) })
                                .y(function(d) { return y(d.inflow_cumsum )})
                                .curve(d3.curveCardinal.tension(0)))
                .on("mouseover", function(d) {
                    d3.selectAll('.cash_line')
                                    .style('opacity', 0.2);
                    d3.selectAll('.cir1 , .cir3')
                                    .style('opacity', 0.2);

                    d3.select(this)
                        .style('opacity', 0.9)
                        .style("stroke-width", "4px")
                        .style("cursor", "pointer");
                })
                .on("mouseout", function(d) {
                        d3.selectAll(".cash_line")
                                        .style('opacity', 0.9);
                        d3.selectAll('.cir1 , .cir3')
                                        .style('opacity', 0.9);
                        d3.select(this)
                            .style("stroke-width", "3px")
                            .style("cursor", "none");
                    });



            g.append("path")
                .data([data])
                .attr("fill", "none")
                .attr("stroke", "red")
                .attr('class', 'cash_line')
                .attr("stroke-width", 2.5)
                .attr("d", d3.line()
                .x(function(d) { return x(d.mm_yyyy) })
                .y(function(d) { return y(d.outflow_cumsum )})
                .curve(d3.curveCardinal.tension(0)))
                .on("mouseover", function(d) {
                    d3.selectAll('.cash_line')
                                    .style('opacity', 0.2);
                    d3.selectAll('.cir2 , .cir3')
                                    .style('opacity', 0.2);

                    d3.select(this)
                        .style('opacity', 0.9)
                        .style("stroke-width", "4px")
                        .style("cursor", "pointer");
                })
                    .on("mouseout", function(d) {
                        d3.selectAll(".cash_line")
                                        .style('opacity', 0.9);
                        d3.selectAll('.cir2 , .cir3')
                                        .style('opacity', 0.9);
                        d3.select(this)
                            .style("stroke-width", "3px")
                            .style("cursor", "none");
                    });




            g.append("path")
                .data([data])
                .attr("fill", "none")
                .attr("stroke", "skyblue")
                .attr('class', 'cash_line')
                .attr("stroke-width", 2.5)
                .attr("d", d3.line()
                .x(function(d) {return x(d.mm_yyyy) })
                .y(function(d) { return y(d.cash_balance )})
                .curve(d3.curveCardinal.tension(0)))
                .on("mouseover", function(d) {
                    d3.selectAll('.cash_line')
                                    .style('opacity', 0.2);
                    d3.selectAll('.cir2 , .cir1')
                                    .style('opacity', 0.2);
                    d3.select(this)
                        .style('opacity', 0.9)
                        .style("stroke-width", "4px")
                        .style("cursor", "pointer");
                })
                    .on("mouseout", function(d) {
                        d3.selectAll(".cash_line")
                                        .style('opacity', 0.9);
                        d3.selectAll('.cir2 , .cir1')
                                        .style('opacity', 0.9);
                        d3.select(this)
                            .style("stroke-width", "3px")
                            .style("cursor", "none");
                    });

            
            g.selectAll(".circle")
                        .data(data)
                        .enter()
                        .append("circle")
                        .attr('class', 'cir1')
                        .attr("r", 4)
                        .attr("cx", function(d){ return x(d.mm_yyyy)})
                        .attr("cy", function(d){ return y(d.outflow_cumsum)})
                        .attr("fill","red")
                        .attr("stroke","white")
                        .attr("stroke-width",1)

            g.selectAll(".circle")
                        .data(data)
                        .enter()
                        .append("circle")
                        .attr('class', 'cir2')
                        .attr("r", 4)
                        .attr("cx", function(d){ return x(d.mm_yyyy)})
                        .attr("cy", function(d){ return y(d.inflow_cumsum)})
                        .attr("fill","green")
                        .attr("stroke","white")
                        .attr("stroke-width",1)

            g.selectAll(".circle")
                        .data(data)
                        .enter()
                        .append("circle")
                        .attr('class', 'cir3')
                        .attr("r", 4)
                        .attr("cx", function(d){ return x(d.mm_yyyy)})
                        .attr("cy", function(d){ return y(d.cash_balance)})
                        .attr("fill","skyblue")
                        .attr("stroke","white")
                        .attr("stroke-width",1)

            var legendedata = ["Cash Inflow", "Cash Outflow", "Cash Balance"];
             
                var legend = g.selectAll(".legend")
                        .data(legendedata)
                        .enter().append("g")
                        .attr("class", "legend")
                        .attr("transform", function(d, i) { return "translate(" + (width/50) + "," + (height+(margin.bottom/2)+10)+")"; });

                legend.append("rect")
                    .attr("x", function(d,i){ return width/5+(i*120)-20 })
                    .attr("y", -8)
                    .attr("width", 12)
                    .attr("height", 15)
                    .style("fill",function(d) { return color(d); });

                legend.append("text")
                    .attr("x", function(d,i){ return width/5+(i*120) })
                    .attr("y", 4)
                    .text(function(d){return d})            
        }
        render(data)
        // console.log(data)
    }

    componentWillUnmount(){
        d3.select("svg").remove();
    }

    render() {
        return (
            <div className="cumInflowOutflowBalance" id="cumInflowOutflowBalance"></div>
        );
    }
}

CumInflowOutflowBalance.propTypes = {
    cumInflowOutflow: PropTypes.array.isRequired
};

export default CumInflowOutflowBalance;