import React from 'react';
import { Link } from 'react-router-dom';

const ClientRegORFileUpload = (props) => {
    return (
        <div className="d-flex justify-content-center">
            <div className="cRD">
                <div className="cRD-title"></div>
                <div className="cRD-submenu">
                    To View Your 360 <Link to={props.to} className="cRD-link">Click Here</Link>
                </div>
            </div>
        </div>
    );
};

export default ClientRegORFileUpload;