import axios from 'axios';
import { ANALYTICS_URL, ACCESS_TOKEN, CLIENT_ID, INSIGHTS360_LOADING, GET_ERRORS, GET_MONTHLY_SALES_YEAR_WISE_DATA,
        GET_WEEKLY_SALES_PROFIT, GET_BASKET_KPI_DETAILS, GET_MONTHLY_SALES_DATA, GET_TOP10_ITEMS_BASED_ON_SALES,
        CLEAR_CURRENT_INSIGHTS360 } from './types';
import { logoutUser } from '../actions/authActions';

/*--- Basket_Kpi_Details ---*/
export const basketKpiDetails = () => dispatch => {
    dispatch(getInsights360Loding());
    axios.get(`${ANALYTICS_URL}/retail/getBasketKpiDetails/${CLIENT_ID()}`, ACCESS_TOKEN())
        .then(res => {
            dispatch({
                type: GET_BASKET_KPI_DETAILS,
                payload: res.data
            })
        })
        .catch(err =>{
            dispatch({
                type: GET_BASKET_KPI_DETAILS,
                payload: {}
            })
            if(err.response && err.response.status === 401){
                dispatch(logoutUser());
            }
        })
}

/*--- Get Monthly Sales Data ---*/
export const monthlySalesData = () => dispatch => {
    dispatch(getInsights360Loding());
    axios.get(`${ANALYTICS_URL}/retail/GetMonthlySalesData/${CLIENT_ID()}`, ACCESS_TOKEN())
        .then(res => {
            dispatch({
                type: GET_MONTHLY_SALES_DATA,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_MONTHLY_SALES_DATA,
                payload: {}
            })
            if(err.response && err.response.status === 401){
                dispatch(logoutUser());
            }
        })
}
 
/*--- Monthly Sales Year wise Data ---*/
export const monthlySalesYearwiseData = () => dispatch => {
    dispatch(getInsights360Loding());
    axios.get(`${ANALYTICS_URL}/retail/GetMonthlySalesYearwiseData/${CLIENT_ID()}`, ACCESS_TOKEN())
        .then(res => {
            dispatch({
                type: GET_MONTHLY_SALES_YEAR_WISE_DATA,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_MONTHLY_SALES_YEAR_WISE_DATA,
                payload: {}
            })
            if(err.response && err.response.status === 401){
                dispatch(logoutUser());
            }
        })
}

/*--- Top 10 Items Based On Sales ---*/
export const top10ItemsBasedOnSales = () => dispatch => {
    dispatch(getInsights360Loding());
    axios.get(`${ANALYTICS_URL}/retail/Top10ItemsBasedOnSales/${CLIENT_ID()}`, ACCESS_TOKEN())
        .then(res => {
            dispatch({
                type: GET_TOP10_ITEMS_BASED_ON_SALES,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_TOP10_ITEMS_BASED_ON_SALES,
                payload: {}
            })
            if(err.response && err.response.status === 401){
                dispatch(logoutUser());
            }
        })
}

/*--- Weekly Sales Profit ---*/
export const weeklySalesProfit = () => dispatch => {
    dispatch(getInsights360Loding());
    axios.get(`${ANALYTICS_URL}/retail/getWeeklySalesProfit/${CLIENT_ID()}`, ACCESS_TOKEN())
        .then(res =>{
            dispatch({
                type: GET_WEEKLY_SALES_PROFIT,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_WEEKLY_SALES_PROFIT,
                payload: {}
            })
            if(err.response && err.response.status === 401){
                dispatch(logoutUser());
            }
        })
}

/*--- Loading 360 Data ---*/
export const getInsights360Loding = () => {
    return {
        type: INSIGHTS360_LOADING
    }
}

/*--- Clear Insights360 ---*/
export const clearCurrentInsights360 = () => {
    return {
        type: CLEAR_CURRENT_INSIGHTS360
    }
}