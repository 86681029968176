import React,{Component } from 'react'
import BubbleChart from './BubbleChart';
import SidebarMarketList from './SidebarMarketList';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RequestLoadingData from '../../Loading/RequestLoadingData';
import { logoutUser } from '../../../redux/actions/authActions';
import { getMarketBasket, postMarketBasket } from '../../../redux/actions/marketBasketActions';

class MarketChart extends Component{

    state = {
        marketDropdown: null,
        marketBubbleChart: [],
        gotResult: false,
        requestItem: true,
        itemKey: 1,
        itemName: ''
    }

    componentDidMount(){
        const itemValue = {
            item : this.state.itemKey
        }
        this.props.getMarketBasket();
        this.props.postMarketBasket(itemValue);
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.marketBasket){
            const { loading, getMarketBasket, postMarketBasket } = nextProps.marketBasket;
            console.log(getMarketBasket)
            if(loading === false && getMarketBasket !== null && postMarketBasket !== null){
                this.setState({
                    marketDropdown: getMarketBasket,
                    itemName: getMarketBasket[this.state.itemKey],
                    marketBubbleChart: [...postMarketBasket],
                    gotResult: true,
                    requestItem: true,
                })
            }
        }
    }

    change = (value) => {
        this.setState({
            requestItem: false,
            itemKey: value
        })
        const itemValue = {
            item : value
        }
        this.props.postMarketBasket(itemValue);
    }

    render(){
        if(this.state.gotResult){
            return(
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card mba-card">
                                <div className="row">
                                    <div className="col-sm-3">
                                        <SidebarMarketList marketDropdown={this.state.marketDropdown} change={this.change} />
                                    </div>
                                    {
                                        this.state.requestItem ?
                                            <div className="col-sm-9">
                                                <div className="card-graph">
                                                    <BubbleChart name={this.state.itemName} marketBubbleChart={this.state.marketBubbleChart} />
                                                </div>
                                            </div>
                                        :
                                            <RequestLoadingData />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return(
                <RequestLoadingData />
            );
        }
    }
}

MarketChart.propTypes = {
    getMarketBasket: PropTypes.func.isRequired, 
    postMarketBasket: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    marketBasket: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    marketBasket: state.marketBasket
})

export default connect(mapStateToProps, { getMarketBasket, postMarketBasket, logoutUser })(MarketChart);