import React, { Component } from 'react';
import GridLoader from 'react-spinners/GridLoader';

export default function(ComposedClass){
    class Loading extends Component {

        state = {
            loading: true
        }

        componentDidMount(){
            setTimeout(() => {
                this.setState({ loading: false });
            }, 1000);
        }

        render() {
            if(this.state.loading){
                return (
                    <div className='page-loading'>
                        <GridLoader
                            sizeUnit={"px"}
                            size={15}
                            color={'#36D7B7'}
                            loading={this.state.loading}
                        />
                    </div>
                )
            }
            return (
                <ComposedClass {...this.props} />
            );
        }
    }

    return Loading;
}