import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

class MonthlyInflowOutflow extends Component {

    componentDidMount() {
        // console.log(this.props.monthlyInflowOutflow)
        this.monthlyInflowOutflow(this.props.monthlyInflowOutflow)
    }

    monthlyInflowOutflow = data => {
        //var width=700,height=400;

     var margin = {top: 20, right: 20, bottom: 100, left: 0},
        width = 1300 - margin.left - margin.right,
        height = 400 - margin.top - margin.bottom;

        var svg = d3.select('#monthlyInflowOutflow')
        .append("svg").attr("width",width+margin.left+margin.right).attr("height",height+margin.top+margin.bottom),
      
        g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");
       
        var x0 = d3.scaleBand()
            .rangeRound([0, width])
           // .paddingInner(0);

        var x1 = d3.scaleBand()
            .padding(0.4);

        var y = d3.scaleLinear()
            .rangeRound([height, 0]);

        var z = d3.scaleOrdinal()
            .range(["#0077FF", "#33BCC3 "]);

        var parse = d3.timeParse("%b-%Y");
        var format = d3.timeFormat("%b-%Y")

        const render = data => {
            // if (error) throw error;

            var columns = d3.keys(data[0])
            data.forEach(function(d){
            d.mm_yyyy = format(parse(d.mm_yyyy))
            })

            var keys = columns.slice(0,2);

            x0.domain(data.map(function(d) { return d.mm_yyyy; }));
            x1.domain(keys).rangeRound([0, x0.bandwidth()]);
            y.domain([0, d3.max(data, function(d) { return d3.max(keys, function(key) { return d[key]; }); })]).nice();


            var barG=  g.append("g")
                .selectAll("g")
                .attr("class","main_group")
                .data(data)
                .enter().append("g")
                .attr("transform", function(d) { return "translate(" + x0(d.mm_yyyy) + ",0)"; })


            barG.selectAll(".bars-container-back")
                .data(function(d) { return keys.map(function(key) { return {key: key, value: d[key]}; }); })
                .enter()
                .append("rect")
                .attr("class", "bars-container-back")
                .attr("x", function(d) { return x1(d.key) - 4; })
                .attr("y", function(d) { return y(d.value) - 4; })
                .attr("width", x1.bandwidth() + 8)
                .attr("height", function(d) { return (height ) - y(d.value) + 3; })
                .attr("fill", "white")
                .attr("stroke-width", "0.5px")
                .attr("stroke", "transparent")
                // .attr("stroke-dasharray", "6,4")
                .attr("shape-rendering", "crispEdges")
                    .transition()
                    .delay(500)
                    .duration(150)
                    .attr("stroke", "#727075")


            barG.selectAll(".bars")
                .data(function(d) { return keys.map(function(key) { return {key: key, value: d[key]}; }); })
                .enter()
            .append("rect")
                .attr("class", "bars")
                .attr("x", function(d) {return x1(d.key); })
                .attr("width", x1.bandwidth())
                .attr("fill", function(d) { return z(d.key); })
                .attr("y", (height))
                    .transition()
                    .delay(function (d,i){ return i * 250;}) // this is to do left then right bars
                    .duration(250)
                    .attr("y", function(d) { return y(d.value); })
                    .attr('height', function( d ) { return ((height))  - y( d.value );});


                var bartext =  d3.format("0.2s")
                    
            barG.selectAll(".text")
                .data(function(d) { return keys.map(function(key) { return {key: key, value: d[key]}; }); })
                .enter()
            .append("text")
                .attr("class", "bar_label")
                .attr("x", function(d) { return x1(d.key)-4; })
                .attr("y", function(d) { return y(d.value)-10; })
                .style("font-size", "10px")
                .text(function(d){ return bartext(d.value)})
            
            g.append("g")
                .attr("class", "axis")
                .attr("transform", "translate(0," + height+ ")")
                .call(d3.axisBottom(x0));

            var legendedata=["Inflow","Outflow"]

            var legend = g.selectAll(".legend")
                .data(legendedata)
                .enter().append("g")
                .attr("class", "legend")
                .attr("transform", function(d, i) { return "translate(" + (width/4) + "," + (height+(margin.bottom/2)+10)+")"; });

            legend.append("rect")
                .attr("x", function(d,i){ return width/4+(i*100) })
                .attr("y", -25)
                .attr("width", 18)
                .attr("height", 18)
                .style("fill",function(d) { return z(d); });

            legend.append("text")
                .attr("x", function(d,i){ return width/4+(i*100)-4 })
                .attr("y", -15)
                .attr("dy", ".35em")
                .style("text-anchor", "end")
                .text(function(d) { return d.replace(/_/g, " "); });
        
        }
        render(data);
    }

    componentWillUnmount(){
        d3.select("svg").remove();
    }

    render() {
        return (
            <div className="monthlyInflowOutflow" id="monthlyInflowOutflow"></div>
        );
    }
}

MonthlyInflowOutflow.propTypes = {
    monthlyInflowOutflow: PropTypes.array.isRequired
};

export default MonthlyInflowOutflow;