import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as d3 from 'd3';
import{ monthlySalesYearwiseData } from '../../../redux/actions/insights360Actions';

class GroupedBarChart extends Component {

    componentDidMount(){
        this.groupBarChart(this.props.groupedBarChart)
    }

    groupBarChart = (data) => {

       var margin = {top: 30, right: 10, bottom: 50, left: 40},
        width = 600 - margin.left - margin.right,
        height = 380 - margin.top - margin.bottom;

        var svg = d3.select("#groupBarChart")
            .append("svg")
            .attr("width", width+ margin.left + margin.right)
            .attr("height", height+ margin.top + margin.bottom)
            // .attr("class","groupedBarCard")
           
        var  g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        // svg.append("text")
        //     .attr("x",(width/2-150))
        //     .attr("y",30)
        //     .attr("class","ctitleGroupBar")
        //     .text("Sales yearly Comparision")

        var x0 = d3.scaleBand()
            .rangeRound([0, width])
            .paddingInner(0.1);

        var x1 = d3.scaleBand()
            .padding(0.05);

        var y = d3.scaleLinear()
            .rangeRound([height, 0]);

        var z = d3.scaleOrdinal()
            // .range(["#d0743c", "#ff8c00"]);
            .range(["#d0743c", "#ff8c00"]);

        const render = data => {
            // if (error) throw error;

            // console.log(data)

            

            var columns = d3.keys(data[0])

            // var columns = d3.keys(data[0])
            var pos;
            var i;
            for(i=0; i<columns.length; i++){
              if(columns[i] === "month"){
                    pos=i;
                    break;  
              }
            }
            for(i=pos; i<columns.length; i++){
              columns[i] = columns[i+1];
            }
          
            var keys=[];
          
            for(i=0; i<columns.length-1; i++){
              keys[i]=columns[i]
            } 
            // var keys = columns.slice(0, 2);

            // var keys = columns.slice(0, 2);
            // console.log(keys)

            x0.domain(data.map(function(d) { return d.month; }));
            x1.domain(keys).rangeRound([0, x0.bandwidth()]);
            y.domain([0, d3.max(data, function(d) { return d3.max(keys, function(key) { return d[key]; }); })]).nice();

            g.append("g")
                .selectAll("g")
                .data(data)
                .enter().append("g")
                .attr("transform", function(d) { return "translate(" + x0(d.month) + ",0)"; })
                .selectAll("rect")
                .data(function(d) { return keys.map(function(key) { return {key: key, value: d[key]}; }); })
                .enter().append("rect")
                // .attr("rx", 12)
                // .attr("ry", 12)
                .attr("x", function(d) { return x1(d.key); })
                .attr("y", function(d) { return y(d.value); })
                .attr("width", (x1.bandwidth()-4))
                .attr("height", function(d) { return height - y(d.value); })
                .attr("fill", function(d) { return z(d.key); });

            g.append("g")
                .attr("class", "axis")
                .attr("transform", "translate(0," + height + ")")
                .call(d3.axisBottom(x0));

            g.append("g")
                .attr("class", "axis")
                .call(d3.axisLeft(y).ticks(null, "s"))
                .append("text")
                .attr("x", 2)
                .attr("y", y(y.ticks().pop()) + 0.5)
                .attr("dy", "0.32em")
                .attr("fill", "#000")
                .attr("font-weight", "bold")
                .attr("text-anchor", "start")
                .text("sales");

            // var legend = g.append("g")
            //     .attr("font-family", "sans-serif")
            //     .attr("font-size", 10)
            //     .attr("text-anchor", "end")
            //   .selectAll("g")
            //   .data(keys.slice().reverse())
            //   .enter().append("g")
            //     .attr("transform", function(d, i) { return "translate(0," + i * 20 + ")"; });

            // console.log(keys.slice().reverse());

            // data.forEach(function(d){
            //   d["2018 sales"] = d["2018_sales"]
            // })

            var legend = g.selectAll(".legend")
                .data(keys)
                .enter().append("g")
                .attr("class", "legend")
                .attr("transform", function(d, i) { return "translate(0," + i * 20 + ")"; });

            legend.append("rect")
                .attr("x", width - 18)
                .attr("y", -28)
                .attr("width", 18)
                .attr("height", 18)
                .style("fill",function(d) { return z(d); });

            legend.append("text")
                .attr("x", width - 24)
                .attr("y", -20)
                .attr("dy", ".35em")
                .style("text-anchor", "end")
                .text(function(d) { return d.replace(/_/g, " "); });

                // var newStr = keys[0].replace(/_/g, " ")
                // console.log(newStr)
        }
        render(data)
    }

    componentWillUnmount(){
        d3.select("svg").remove();
    }

    render() {
        return (
            <div id="groupBarChart"></div>
        );
    }
}

GroupedBarChart.propTypes = {
    monthlySalesYearwiseData: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    insights360: PropTypes.object.isRequired,
    groupedBarChart: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    insights360: state.insights360
})

export default connect(mapStateToProps, { monthlySalesYearwiseData })(GroupedBarChart);