export const ANALYTICS_URL = process.env.REACT_APP_ACCESS_URL;

export const CHATBOT_URL = process.env.REACT_APP_CHATBOT;

/*--- TOKEN ACCESSING ---*/
export const ACCESS_TOKEN = () => {
    const token = JSON.parse(localStorage.getItem('AnalyticalAppToken'));
    return {
        headers: {
            'Authorization': token.access_token
        }
    }
}

export const AUTH_TOKEN = () => {
    const token = JSON.parse(localStorage.getItem('AnalyticalAppToken'));
    return {
        headers: {
            'Authorization': token.auth_token
        }
    }
}

export const TOKEN_ACCESS = () => {
    const token = JSON.parse(localStorage.getItem('AnalyticalAppToken'));
    return token.access_token
}

export const TOKEN_AUTH = () => {
    const token = JSON.parse(localStorage.getItem('AnalyticalAppToken'));
    return token.auth_token
}

/*--- Client Id ---*/
export const CLIENT_ID = () => {
    const token = JSON.parse(localStorage.getItem('AnalyticalAppToken'));
    return token.client_id 
}

/*--- ERRORS ---*/
export const GET_ERRORS = 'GET_ERRORS';

/*--- AUTH ---*/
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

/*--- DOMAIN & MODULE SELECTION ---*/
export const DOMAIN_MODULES_LOADING = 'DOMAIN_MODULES_LOADING';
export const DOMAIN_LIST = 'DOMAIN_LIST';

/*========================================== Retail =================================================*/
/*--- INSIGHT360 ---*/
export const INSIGHTS360_LOADING = 'INSIGHTS360_LOADING';
export const GET_BASKET_KPI_DETAILS = 'GET_BASKET_KPI_DETAILS';
export const GET_MONTHLY_SALES_DATA = 'GET_MONTHLY_SALES_DATA';
export const GET_MONTHLY_SALES_YEAR_WISE_DATA = 'GET_MONTHLY_SALES_YEAR_WISE_DATA';
export const GET_TOP10_ITEMS_BASED_ON_SALES = 'GET_TOP10_ITEMS_BASED_ON_SALES';
export const GET_WEEKLY_SALES_PROFIT = 'GET_WEEKLY_SALES_PROFIT';
export const CLEAR_CURRENT_INSIGHTS360 = 'CLEAR_CURRENT_INSIGHTS360';

/*--- TIMESERIES ---*/
export const TIMESERIES_LOADING = 'TIMESERIES_LOADING';
export const TIMESERIES_DETAILS = 'TIMESERIES_DETAILS';
export const CLEAR_CURRENT_TIMESERIES = 'CLEAR_CURRENT_TIMESERIES';

/*--- ABC ANALYSIS ---*/
export const ABC_LOADING = 'ABC_LOADING';
export const GET_SALES_ABC_CATDATA = 'GET_SALES_ABC_CATDATA';
export const GET_SALES_AND_PROFIT_ABC_SUMMARY = 'GET_SALES_AND_PROFIT_ABC_SUMMARY';
export const GET_ABCA_ACAT_TABLE = 'GET_ABCA_ACAT_TABLE';
export const CLEAR_CURRENT_ABC = 'CLEAR_CURRENT_ABC';

/*--- ITEM ANALYSIS ---*/
export const ITEM_ANALYSIS_LOADING = 'ITEM_ANALYSIS_LOADING';
export const GET_TOP_20ITEMS_BASED_ON_SALES =  'GET_TOP_20ITEMS_BASED_ON_SALES';
export const GET_ITEMWISE_KPI =  'GET_ITEMWISE_KPI';
export const GET_MONTHLY_SALES_OF_ITEM =  'GET_MONTHLY_SALES_OF_ITEM';
export const GET_MONTHLY_PROFIT_OF_ITEM = 'GET_MONTHLY_PROFIT_OF_ITEM';
export const CLEAR_CURRENT_ITEM_ANALYSIS = 'CLEAR_CURRENT_ITEM_ANALYSIS';

/*--- TIME SERIES PREDICTION ---*/
export const TIME_SERIES_PREDICTION_LOADING = 'TIME_SERIES_PREDICTION_LOADING';
export const GET_HIST_AND_PRED_DATA =  'GET_HIST_AND_PRED_DATA';
export const GET_NEXT_SEVENDAYS_PRED_DATA = 'GET_NEXT_SEVENDAYS_PRED_DATA';
export const GET_CURRENT_NEXT_MONTH_PRED_DATA = 'GET_CURRENT_NEXT_MONTH_PRED_DATA';
export const CLEAR_CURRENT_TIME_SERIES_PREDICTION = 'CLEAR_CURRENT_TIME_SERIES_PREDICTION';

/*--- MARKET BASKET ANALYSIS ---*/
export const MARKET_BASKET_LOADING = 'MARKET_BASKET_LOADING';
export const GET_MARKET_BASKET_DATA = 'GET_MARKET_BASKET_DATA';
export const POST_MARKET_BASKET_DATA = 'POST_MARKET_BASKET_DATA';
export const CLEAR_CURRENT_MARKET_BASKET = 'CLEAR_CURRENT_MARKET_BASKET';

/*======================================== Retail End ===============================================*/

/*===================================== Finance Analysis ============================================*/
/*--- CASHFLOW360 ---*/
export const CASHFLOW360_LOADING = 'CASHFLOW360_LOADING';
export const GET_REVENUE_EXPENSE_KPI = 'GET_REVENUE_EXPENSE_KPI';
export const GET_MONTHLY_INFLOW_OUTFLOW = 'GET_MONTHLY_INFLOW_OUTFLOW';
export const GET_CUM_INFLOW_OUTFLOW_BALANCE = 'GET_CUM_INFLOW_OUTFLOW_BALANCE';
export const GET_MONTHLY_CASH_BALANCE = 'GET_MONTHLY_CASH_BALANCE';
export const CLEAR_CURRENT_CASHFLOW360 = 'CLEAR_CURRENT_CASHFLOW360';

/*--- EXPENSE ---*/
export const EXPENSE_LOADING = 'EXPENSE_LOADING';
export const GET_DIFFERENT_HEADWISE_OUTFLOW = 'GET_DIFFERENT_HEADWISE_OUTFLOW';
export const GET_MONTHLY_TOPK_EXPENSE = 'GET_MONTHLY_TOPK_EXPENSE';
export const CLEAR_CURRENT_EXPENSE = 'CLEAR_CURRENT_EXPENSE';

/*--- REVENUE ---*/
export const REVENUE_LOADING = 'REVENUE_LOADING';
export const GET_CLIENTWISE_INFLOW = 'GET_CLIENTWISE_INFLOW';
export const GET_MONTHLY_TOPK_REVENUE = 'GET_MONTHLY_TOPK_REVENUE';
export const CLEAR_CURRENT_REVENUE = 'CLEAR_CURRENT_REVENUE';

/*--- CASHFLOW FORECAST ---*/
export const CASHFLOW_FORECAST_LOADING = 'CASHFLOW_FORECAST_LOADING';
export const GET_MONTHLY_CASH_BALANCE_FORECAST = 'GET_MONTHLY_CASH_BALANCE_FORECAST';
export const GET_WEEKLY_HIST_PRED_DATA = 'GET_WEEKLY_HIST_PRED_DATA';
export const CLEAR_CURRENT_CASHFLOW_FORECAST = 'CLEAR_CURRENT_CASHFLOW_FORECAST';

/*--- CUSTOMER ANALYSIS ---*/
export const CUSTOMER_ANALYSIS_LOADING = 'CUSTOMER_ANALYSIS_LOADING';
export const GET_CUSTOMER_ANALYSIS = 'GET_CUSTOMER_ANALYSIS';
export const POST_CUSTOMER_ANALYSIS = 'POST_CUSTOMER_ANALYSIS';
export const CLEAR_CURRENT_CUSTOMER_ANALYSIS = 'CLEAR_CURRENT_CUSTOMER_ANALYSIS';

/*--- CASHFLOW TIMESERIES ---*/
export const CASHFLOW_TIMESERIES_LOADING = 'CASHFLOW_TIMESERIES_LOADING';
export const CASHFLOW_TIMESERIES_DETAILS = 'CASHFLOW_TIMESERIES_DETAILS';
export const CLEAR_CURRENT_CASHFLOW_TIMESERIES = 'CLEAR_CURRENT_CASHFLOW_TIMESERIES';

/*=================================== Finance Analysis End  =========================================*/

/*--- FILE UPLOAD ---*/
export const FILE_UPLOADING_LOADING = 'FILE_UPLOADING_LOADING';
export const GET_FILE_UPLOAD_DETAILS = 'GET_FILE_UPLOAD_DETAILS';
export const UPLOADING_PROGRESS_PERCENTAGE = 'UPLOADING_PROGRESS_PERCENTAGE';
export const FILE_UPLOADING_RESULT = 'FILE_UPLOADING_RESULT';
export const FILE_PROCESSING_RESULT = 'FILE_PROCESSING_RESULT';
export const FILE_PARTIAL_LOCK = 'FILE_PARTIAL_LOCK';
export const CLEAR_CURRENT_FILE_UPLOADING = 'CLEAR_CURRENT_FILE_UPLOADING';

/*--- DATA REPOSITORY ---*/
export const DATA_REPOSITORY_LOADING = 'DATA_REPOSITORY_LOADING';
export const DATA_REPOSITORY = 'DATA_REPOSITORY';
export const CLEAR_CURRENT_DATA_REPOSITORY = 'CLEAR_CURRENT_DATA_REPOSITORY';

/*--- CHATBOT ---*/
export const CHATBOT_LOADING = 'CHATBOT_LOADING';
export const CHATBOT = 'CHATBOT';
export const CLEAR_CURRENT_CHATBOT = 'CLEAR_CURRENT_CHATBOT';