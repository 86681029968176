import { REVENUE_LOADING, GET_CLIENTWISE_INFLOW, GET_MONTHLY_TOPK_REVENUE, CLEAR_CURRENT_REVENUE } from '../actions/types';

const initialState = {
    loading: false,
    clientwiseInflow: null,
    monthlyTopkRevenue: null
}

export default function(state = initialState, action) {
    switch(action.type) {
        case REVENUE_LOADING:
            return {
                ...state,
                loading: true
            }
        case GET_CLIENTWISE_INFLOW:
            return {
                ...state,
                clientwiseInflow: action.payload,
                loading: false
            }
        case GET_MONTHLY_TOPK_REVENUE:
            return {
                ...state,
                monthlyTopkRevenue: action.payload,
                loading: false
            }
        case CLEAR_CURRENT_REVENUE:
            return {
                ...state,
                loading: false,
                clientwiseInflow: null,
                monthlyTopkRevenue: null
            }
        default:
            return state;
    }
}