import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Layout, Menu, Icon } from 'antd';
const { Sider } = Layout;

class SidebarMarketList extends Component {

    state = {
        collapsed: true,
        marketDropdown: null,
        marketDropdownIcons: ['login', 'rollback', 'enter', 'radius-upright', 'plus', 'exclamation', 'retweet', 'swap', 'logout', 'stop', 'fall', 'rise', 'stock', 'sliders', 'cloud', 'customer-service', 'dashboard', 'fire', 'star', 'tags'],
        marketDropdownKeys: [],
        marketDropdownValue: []
    };

    componentDidMount(){
        const dropDown = this.props.marketDropdown
        const key = Object.keys(dropDown);
        const value = Object.values(dropDown);
        this.setState({
            marketDropdown: dropDown,
            marketDropdownKeys: [...key],
            marketDropdownValue: [...value]
        })
    }

    toggle = () => {
        this.setState({
          collapsed: !this.state.collapsed,
        });
    };

    sidenav = () => (
        this.state.marketDropdownKeys.map((a, i) => (
            <Menu.Item key={this.state.marketDropdownKeys[i]}>
                <Icon type={this.state.marketDropdownIcons[i]} />
                <span>{this.state.marketDropdownValue[i]}</span>
            </Menu.Item>
        ))
    )

    change = (e) => {
        this.props.change(e.key)
    }

    render() {
        return (
            <Sider 
                className={this.state.collapsed ? 'mdb-sidebar' : 'mdb-sidebar mdb-sidebar-scroll'}
                trigger={null} 
                collapsible 
                collapsed={this.state.collapsed}
            >
                <div className="mdb-sidebar-Toggle">
                    <Icon
                        className={this.state.collapsed ? 'trigger menu-unfold' : 'trigger menu-fold'}
                        type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                        onClick={this.toggle}
                    />
                </div>
                <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} onClick={this.change} >
                    {this.sidenav()}
                </Menu>
            </Sider>
        );
    }
}

SidebarMarketList.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
})

export default connect(mapStateToProps)(SidebarMarketList);