import axios from 'axios';
import { notification } from 'antd';
import { AUTH_TOKEN, TOKEN_AUTH, GET_ERRORS } from './types';
import { setCurrentUser } from './authActions';

/*--- Client Registration ---*/
export const clientRegistration = (clientDetails, history) => dispatch => {
    axios.post(`rest/register/addclient`, clientDetails, AUTH_TOKEN())
        .then(res => {
            notification.success({
                message: 'Client Registration Successfull',
                description: res.data.message
            });
            dispatch(setCurrentUser(TOKEN_AUTH()))
            setTimeout(history.push('/fileUpload'), 3000);
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            })
        })
}