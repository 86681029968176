import { TIMESERIES_LOADING, TIMESERIES_DETAILS, CLEAR_CURRENT_TIMESERIES } from '../actions/types';

const initialState = {
    loading: false,
    timeSeriesDetails: null
}

export default function(state = initialState, action) {
    switch(action.type) {
        case TIMESERIES_LOADING:
            return {
                ...state,
                loading: true,
            }
        case TIMESERIES_DETAILS:
            return {
                ...state,
                timeSeriesDetails : action.payload,
                loading: false
            }
        case CLEAR_CURRENT_TIMESERIES:
            return {
                ...state,
                timeSeriesDetails: null
            }
        default:
            return state;
    }
}