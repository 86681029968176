import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MDBIcon } from 'mdbreact';
import Zoom from 'react-reveal/Zoom';
import CountUp from 'react-countup';

class KpiItemAnalysis extends Component {

    state = {
        cardTitleName: [],
        icons : ['shopping-basket', 'cart-plus', 'hand-holding-usd'],
        cardTitleNumberEnd : [],
        delay: [0, 100, 200]
    }

    componentDidMount(){
        const data = this.props.itemwiseKpi
        // console.log(data)
        const output = Object.assign(data[2],data[0],data[1]);
        // console.log(output)
        const key = Object.keys(output);
        // console.log(key)
        const value = Object.values(output);
        this.setState({
            cardTitleName: [...key],
            cardTitleNumberEnd: [...value]
        })
    }

    itemAnalysisKpi = () => (
        this.state.cardTitleName.map((a,i) => (
            <Zoom key={i} delay={this.state.delay[i]}>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="card itemAnalysis-Kpicard">
                        <div className="row">
                            <div className="col">
                                <MDBIcon icon={this.state.icons[i]} size="lg" />
                            </div>
                            <div className="col itemAnalysis-kpicard-name">
                                {this.state.cardTitleName[i].split('_').join(' ')}
                            </div>
                            <div className="col-auto">
                                {
                                    i === 1 || i === 2 ?
                                    <CountUp 
                                        start={0}
                                        end={this.state.cardTitleNumberEnd[i]}
                                />
                                    :
                                        <CountUp 
                                            start={0}
                                            end={this.state.cardTitleNumberEnd[i]}
                                        />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Zoom>
        ))
    )

    render() {
        return (
            <div className="row itemAnalysis-Kpi">
                {this.itemAnalysisKpi()}
            </div>
        );
    }
}

KpiItemAnalysis.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    itemwiseKpi: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
})

export default connect(mapStateToProps)(KpiItemAnalysis);