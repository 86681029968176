import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import $ from 'jquery';
import * as d3 from 'd3';

class BubbleChart extends Component {

    componentDidMount() {
        console.log(this.props.marketBubbleChart, this.props.name)
        this.bubbleChart(this.props.marketBubbleChart, this.props.name)
    }

    bubbleChart = (data, name) => {
        var width = $("#bubble").width();
        var height = $("#bubble").height();

        var g = d3.select("#bubble")
            .append("svg")
            .attr("preserveAspectRatio", "xMidYMid slice")
            .attr("viewBox", "0 0 "+ width + " " +height)
            // .attr("height", height)
            // .attr("width", width)
            // .style("background", "#f0f5f5")

        var svg = g.append("g")
            .attr("transform", "translate(-100,30)")


        var div = d3.select("body").append("div")
            .attr("class", "tooltip")
            .style("opacity", 0);

        const render = (data, name) => {
            data.forEach(function(d) {
                d.Amount = +d.qty;
                d.Party_Name = d.item;
            });
        
            var color = d3.scaleOrdinal()
                    .domain(data.map(function(d){ return d.Party_Name;}))
                    .range(['#fbb4ae','#b3cde3','#ccebc5','#decbe4','#fed9a6',
                    '#ffe9a8','#b9bfe3','#fddaec','#cccccc','#66757F','#ff6bb2','#ccff66','#ff66ff','#6ef230',
                    '#999966','#ff997a','#00ff91','#ff85e2','#85fff3','#ffef85','#ccff91','#a7ab7e','#90aba9',
                    '#ed72eb','#ff8080','#663300','#ffff99','#cc8033']);
        
        
            var radiusScale = d3.scaleSqrt().domain(d3.extent(data, function(d) { return d.Amount; }))
                                            .range([10, 150])
        
            const data1 = d3.extent(data, function(d) { return d.Amount; });
            //console.log(data1)
            
            var simulation = d3.forceSimulation()
                                .force("x", d3.forceX(width/2).strength(0.05))
                                .force("y", d3.forceY(height/2).strength(0.05))
                                .force("collide", d3.forceCollide(function(d) {
                                        return radiusScale(d.Amount)+1;
                                            }))
        
            var number = d3.format(".2s")
        
            var circles = svg.selectAll("circle")
                            .data(data)
                            .enter()
                            .append("circle")
                            .attr("class", "circle")
                            .attr("r", function(d){return radiusScale(d.Amount)})
                            .attr("fill", function(d){return color(d.Party_Name)})
                            .on("mouseover", function(d, i) {
                                d3.select(this).attr("r", radiusScale(d3.max(data1))+20);
                                svg.append("text")
                                    .attr("id", "ctext" + i)
                                    .attr("x",  function() {return d.x})
                                    .attr("y",  function() {return d.y})
                                    .text(function(){return d.Party_Name+" : "+number(d.Amount)})
                                    .attr("fill",  "black")
                                    .style("text-anchor", "middle");
                                            
                                // div.transition()		
                                //     .duration(200)		
                                //     .style("opacity", .9);		
                                // div	.html(d.Party_Name + "<br/>"  + d.Amount)	
                                //     .style("left", (d3.event.pageX) + "px")		
                                //     .style("top", (d3.event.pageY - 28) + "px");	
                                })					
                            .on("mouseout", function(d, i) {	
                                d3.select(this).attr("r", function(){return radiusScale(d.Amount)})
                                d3.select("#ctext" + i).remove();	
                                div.transition()		
                                    .duration(500)		
                                    .style("opacity", 0);	
                            });
        
                    svg.append('text')
                            .attr("class", "title")
                            .attr('y', 0)
                            .attr('x', width/2)
                            .text('Market Basket Analysis' + ' - (' + name + ')')
        
            
        
            simulation.nodes(data)
                    .on('tick', ticked)
        
            function ticked(){
                circles
                .attr("cx", function(d){return d.x})
                .attr("cy", function(d){return d.y})
            }
        
        // var g1 = svg.append("g")
        //             .attr("transform", +"translate(500, )")
            var legend = svg.selectAll(".legend")
            .data(data).enter()
            .append("g")
            .attr("class","legend")
            .attr("transform", "translate(" + (width/2+0.5*height) + "," + (height/2-0.2*width) + ")");
               
           
             legend.append("rect")
               .attr("x", 70) 
               .attr("y", function(d, i) { return 20 * i; })
               .attr("width", 15)
               .attr("height", 15)
                  .style("fill", function(d) { return color(d.item)});
             
          
              legend.append("text")
                .attr("x", 100) 
                .attr("text-anchor", "start")
                .attr("dy", "1em") 
                .attr("class","ltext")
                .attr("y", function(d, i) { return 20 * i; })
                .text(function(d) {return d.item;})
               .attr("font-size", "12px"); 
            
                
              legend.append("text")
               .attr("x",80) 
               .attr("dy", "-.2em")
               .attr("y",-10)
              .style("font-family","monospace")
               .text(" Item Names")
                .style("font-size", "15px")
                .style("fill","purple"); 
          
        }
        render(data, name)
    }

    render() {
        return (
            <div id="bubble"></div>
        )
    }
}

BubbleChart.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    marketBubbleChart: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
})

export default connect(mapStateToProps)(BubbleChart)