import React from 'react';
import FileDetails from './FileDetails';
import { connect } from 'react-redux';
import FileSizeConvertion from '../../Reusable_Components/FileSizeConvertion';

function Repos(props){
    const { faq, index, toggleFAQ, active } = props;
    const { user } = props.auth;
    return (
        <div key={index} className="card">
            <div className={active ? "card-header active" : "card-header"} id={`heading${index}`}>
                <h2 className="mb-0">
                    <button className={active ? "btn btn-link" : "btn btn-link collapsed"} type="button" data-toggle="collapse" data-target={`#collapse${index}`} aria-expanded={faq.active ? "true" : "false"} aria-controls={`collapse${index}`} onClick={() => toggleFAQ(index)}>
                        Data : <i className="fas fa-arrow-circle-down"></i>
                    </button>
                </h2>
            </div>

            <div id={`collapse${index}`} className={active ? "collapse show" : "collapse"} aria-labelledby={`heading${index}`} data-parent="#accordionExample">
                <div className="card-body">
                    <div className="file-Details">
                        <h3 className="file_heading">File Uploaded Details</h3>
                        <div className="row">
                            {
                                faq.map((res, i) => (
                                    <div className="col-md-6" key={res.id}>
                                        <FileDetails 
                                            name={res.category}
                                            itemId={res.id}
                                            itemName={res.name}
                                            size={FileSizeConvertion(res.size)}
                                            uploadStatus={res.name ? true : false}
                                            processStatus={res.isProcessed}
                                            processStatusPartialLock={user.filesInfo && user.filesInfo.partialLock}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                        {/* <div className="file_Id">
                            Id : {faq.id}
                        </div> */}
                        {/* <div className="row">
                            <div className="col-md-6">
                                <FileDetails 
                                    name="Item"
                                    itemId={faq.item.id}
                                    itemName={faq.item.name}
                                    size={FileSizeConvertion(faq.item.size)}
                                    uploadStatus={faq.item.name ? true : false}
                                    processStatus={faq.item.isProcessed}
                                    processStatusPartialLock={user.filesInfo && user.filesInfo.partialLock}
                                />
                            </div>
                            <div className="col-md-6">
                                <FileDetails 
                                    name="Sales"
                                    itemId={faq.sales.id}
                                    itemName={faq.sales.name}
                                    size={FileSizeConvertion(faq.sales.size)}
                                    uploadStatus={faq.sales.name ? true : false}
                                    processStatus={faq.sales.isProcessed}
                                    processStatusPartialLock={user.filesInfo && user.filesInfo.partialLock}
                                />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps)(Repos);