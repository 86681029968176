import { DATA_REPOSITORY_LOADING, DATA_REPOSITORY, CLEAR_CURRENT_DATA_REPOSITORY } from '../actions/types';

const initialState = {
    loading: false,
    repository: null
}

export default function(state = initialState, action) {
    switch(action.type) {
        case DATA_REPOSITORY_LOADING:
            return {
                ...state,
                loading: true
            }
        case DATA_REPOSITORY:
            return {
                ...state,
                repository: action.payload,
                loading: false
            }
        case CLEAR_CURRENT_DATA_REPOSITORY:
            return {
                ...state,
                loading: false,
                repository: null
            }
        default:
            return state;
    }
}