import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Register from './Register';
import Logo from '../../Resources/images/Logo/4GgKQH.png';
import CircularBarChart from '../../Resources/images/Welcome/CircularBarChart.png';
import RadialBarChart from '../../Resources/images/Welcome/RadialBarChart.png';
import Donut from '../../Resources/images/Welcome/Donut.png';
import GroupBarChart from '../../Resources/images/Welcome/GroupBarChart.png';
import BarChart from '../../Resources/images/Welcome/BarChart.png';
import StackedBarChart from '../../Resources/images/Welcome/StackedBarChart.png';
import Scatter from '../../Resources/images/Welcome/Scatter.png';
import LineAreaChart from '../../Resources/images/Welcome/LineAreaChart.png';
import LollipopChart from '../../Resources/images/Welcome/LollipopChart.png';
import marketChart from '../../Resources/images/Welcome/marketChart.png';
import PieChart from '../../Resources/images/Welcome/PieChart.png';
import AreaStackedChart from '../../Resources/images/Welcome/AreaStackedChart.png';

class Top extends Component {

    state = {
        tilesClass: ['topShowCase2', 'topShowCase3', 'topShowCase4', 'topShowCase5', 'topShowCase6', 'topShowCase7', 'topShowCase8', 'topShowCase9', 'topShowCase10', 'topShowCase11'],
        tilesImages: [GroupBarChart, Donut, Scatter, marketChart, PieChart, StackedBarChart, BarChart, LollipopChart, AreaStackedChart, LineAreaChart],
        tilesTitle: ['Group BarChart', 'Donut Chart', 'Scatter Chart', 'Bubble Chart', 'Pie Chart', 'Stacked BarChart', 'Bar Chart', 'Lollipop Chart', 'Area Chart', 'Line AreaChart'],
        imagediscription: ['Comparitive analysis', 'Top 10 products', 'Time series analysis', 'cluster analysis', 'Abc Analysis', 'Split analysis', 'Sales analysis', 'Sales analysis', 'Comparative analysis', 'Predictive analysis']
    }

    tiles = (
        this.state.tilesClass.map((a,i) => (
            <div className={`${this.state.tilesClass[i]} hovereffect`} key={i}>
                <img src={this.state.tilesImages[i]} alt={this.state.tilesTitle[i]} />
                <div className="overlay">
                    <p className="text-capitalize">{this.state.tilesTitle[i]}</p>
                    <h2></h2>
                    <p className="text-capitalize">{this.state.imagediscription[i]}</p>
                </div>
            </div>
        ))
    )

    render() {
        return (
            <div className="container-fluid homepage intro tp-blue">
                <div className="w-100 sd-blue curve">
                    <div className="container">
                        <nav className="nav-info d-flex justify-content-between">
                            <div className="text-left">
                                <Link className="navbar-brand home-logo" to="#">
                                <img src={Logo} alt="" />
                                </Link>
                            </div>
                            <div className="d-inline-block text-right">
                                <Link to="/login" className="btn aap-btn aap-primary-btn" id="login"> Login </Link>
                                <Link to="/register" className="btn aap-btn aap-primary-btn"> Signup </Link>
                            </div>
                        </nav>
                        <h2 className="header-title text-center top-heading">
                            <span className="d-block animated fadeInLeft">
                                Standardized Role Based
                            </span>
                            <span className="d-block animated fadeInRight">
                                Analytics Platform
                            </span>
                        </h2>
                        <h6 className="description top-list">
                            <ul className="ml-5 animated bounce">
                                <li>What gets measured, Gets Done.</li>
                                <li>What do you measure?</li>
                                <li>This platform gives you business insights and Forecasts your measures.</li>
                            </ul>
                        </h6>
                        <Register />
                    </div>
                    <div className="d-flex justify-content-center showCase">
                        <div className="d-inline-block position-absolute">
                            <div className="topShowCase" title="CircularBarChart">
                                <img src={CircularBarChart} alt="CircularBarChart" />
                            </div>
                            <div className="topShowCase1" title="RadialBarChart">
                                <img src={RadialBarChart} alt="RadialBarChart" />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between position-absolute">
                            {this.tiles}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Top;