import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import KpiItemAnalysis from './KpiItemAnalysis';
import RequestLoadingData from '../../Loading/RequestLoadingData';
import { logoutUser } from '../../../redux/actions/authActions';
import TreeChart from './TreeChart';
import SalesChart from './SalesChart';
import ProfitChart from './ProfitChart';
import { getTop20ItemsBasedOnSales, getItemwiseKpi, getMontlySalesOfItem, getMontlyProfitOfItem } from '../../../redux/actions/itemAnalysisActions';

class ItemAnalysis extends Component {

    state = {
        treechart : [],
        itemwiseKpi : [],
        monthlySales : [],
        monthlyProfit : [],
        interactive: true,
        gotResult: false,
        itemName : '',
        itemId: 1
    }

    componentDidMount(){
        const itemData = {
            item : this.state.itemId
        }
        this.props.getTop20ItemsBasedOnSales()
        this.props.getItemwiseKpi(itemData)
        this.props.getMontlySalesOfItem(itemData) 
        this.props.getMontlyProfitOfItem(itemData)
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.itemAnalysis){
            const { loading, top20ItemsBasedOnSales, getItemwiseKpi, getMontlySalesOfItem, getMontlyProfitOfItem } = nextProps.itemAnalysis;
            if(loading === false && top20ItemsBasedOnSales !== null && getItemwiseKpi !== null && getMontlySalesOfItem !== null && getMontlyProfitOfItem !== null){
                const name = this.titleName(top20ItemsBasedOnSales, this.state.itemId)
                this.setState({
                    treechart : [...top20ItemsBasedOnSales],
                    itemName : name.Item_name,
                    itemwiseKpi : [...getItemwiseKpi],
                    monthlySales : [...getMontlySalesOfItem],
                    monthlyProfit : [...getMontlyProfitOfItem],
                    gotResult : true,
                    interactive: true
                })
            }
        }
    }

    titleName = (treechart, item_id) => {
        const nameReturn = treechart.find((data, index) => {
            return data.item_id === item_id
        })
        return nameReturn
    }

    barupdate = (value) => {
        const itemName = value.Item_name
        // console.log(itemName)
        // console.log(value.item_id)
        this.setState({
            itemName : itemName,
            itemId: value.item_id,
            interactive : false
        })
        const itemData = {
            item : value.item_id
        }
        this.props.getItemwiseKpi(itemData)
        this.props.getMontlySalesOfItem(itemData) 
        this.props.getMontlyProfitOfItem(itemData)
    }

    render() {

        if(this.state.gotResult) {
            return (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7">
                            <div className="card itemAnalysis-card-treechart">
                                <div className="treechart">
                                    <TreeChart treechart={this.state.treechart} barupdate={this.barupdate} />
                                </div>
                            </div>
                        </div>
                        {
                            this.state.interactive ?
                                <div className="col-xs-6 col-sm-6 col-md-5 col-lg-5">
                                    <div className="text-center">
                                        <h5>Details of {this.state.itemName}</h5>
                                    </div>
                                    <KpiItemAnalysis itemwiseKpi={this.state.itemwiseKpi}/>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card itemAnalysis-card">
                                                <SalesChart monthlySales={this.state.monthlySales} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="card itemAnalysis-card">
                                                <ProfitChart monthlyProfit={this.state.monthlyProfit} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :
                                <RequestLoadingData />
                        }
                    </div>
                </div>
            );
        } else {
            return(
                <RequestLoadingData />
            )
        }
    }
}

ItemAnalysis.propTypes = {
    getTop20ItemsBasedOnSales : PropTypes.func.isRequired,
    getItemwiseKpi : PropTypes.func.isRequired,
    getMontlySalesOfItem : PropTypes.func.isRequired,
    getMontlyProfitOfItem : PropTypes.func.isRequired,
    logoutUser : PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    itemAnalysis: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    itemAnalysis: state.itemAnalysis
})

export default connect(mapStateToProps, { getTop20ItemsBasedOnSales, getItemwiseKpi, getMontlySalesOfItem, getMontlyProfitOfItem, logoutUser })(ItemAnalysis);