export const ItemFile = [
    {
        "column" : [{
            "id": "first",
            "displayName": "Item_code"
        },{
            "id": "second",
            "displayName": "Item_name"
        },{
            "id": "third",
            "displayName": "Category"
        },{
            "id": "fourth",
            "displayName": "Sub_category"
        }]
    },
    {
        "datas" : [{
            "first": "98106104",
            "second": "PIZZA BURGER",
            "third": "105",
            "fourth": ""
        },{
            "first": "98106105",
            "second": "VEGGIE BURGER",
            "third": "105",
            "fourth": ""
        }]
    }
]

export const SalesFile = [
    {
        "column" : [{
            "id": "first",
            "displayName": "Sales_id"
        },{
            "id": "second",
            "displayName": "Datetimestamp"
        },{
            "id": "third",
            "displayName": "Item_code"
        },{
            "id": "fourth",
            "displayName": "Item_name"
        },{
            "id": "fifth",
            "displayName": "Sale_quantity"
        },{
            "id": "sixth",
            "displayName": "Unitcost_price"
        },{
            "id": "seventh",
            "displayName": "Unitsale_price"
        },{
            "id": "eighth",
            "displayName": "Total_sales"
        }]
    },
    {
        "datas" : [{
            "first": "1612131012",
            "second": "2018-01-13 12:46:35",
            "third": "ICECOFFEE",
            "fourth": "ICE COFFEE",
            "fifth": "1.0",
            "sixth": "0.0",
            "seventh": "1.86",
            "eighth": "1.86"
        },{
            "first": "1612131013",
            "second": "2018-01-13 13:02:36",
            "third": "719410140113",
            "fourth": "5HR ENRG C/U PINK LEMONADE",
            "fifth": "1.0",
            "sixth": "0.0",
            "seventh": "2.99",
            "eighth": "2.99"
        }]
    }
]

export const CashflowAnalysis = [
    {
        "column" : [{
            "id": "first",
            "displayName": "Date"
        },{
            "id": "second",
            "displayName": "Account_Entry"
        },{
            "id": "third",
            "displayName": "Particulars"
        },{
            "id": "fourth",
            "displayName": "Vch Type"
        },{
            "id": "fifth",
            "displayName": "Vch No."
        },{
            "id": "sixth",
            "displayName": "Debit"
        },{
            "id": "seventh",
            "displayName": "Credit"
        }]
    },
    {
        "datas" : [{
            "first": "2017-04-01",
            "second": "Dr",
            "third": "Unsecured Kotak Loan",
            "fourth": "Payment",
            "fifth": "1",
            "sixth": "0",
            "seventh": "75613",
        },{
            "first": "2017-05-11",
            "second": "Cr",
            "third": "RPS Consulting Private Limited",
            "fourth": "Receipt",
            "fifth": "2",
            "sixth": "105000",
            "seventh": "0",
        }]
    }
]