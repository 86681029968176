import { TIME_SERIES_PREDICTION_LOADING, GET_HIST_AND_PRED_DATA, GET_NEXT_SEVENDAYS_PRED_DATA, 
    GET_CURRENT_NEXT_MONTH_PRED_DATA, CLEAR_CURRENT_TIME_SERIES_PREDICTION } from '../actions/types';

const initialState = {
    loading: false,
    getHistAndPredData: null,
    getNextSevedaysPredData: null,
    getCurrentNextMonthPredData: null
}

export default function(state = initialState, action) {
    switch(action.type) {
        case TIME_SERIES_PREDICTION_LOADING:
            return {
                ...state,
                loading: true
            }
        case GET_HIST_AND_PRED_DATA:
            return {
                ...state,
                getHistAndPredData: action.payload,
                loading: false
            }
        case GET_NEXT_SEVENDAYS_PRED_DATA:
            return {
                ...state,
                getNextSevedaysPredData: action.payload,
                loading: false
            }
        case GET_CURRENT_NEXT_MONTH_PRED_DATA:
            return {
                ...state,
                getCurrentNextMonthPredData : action.payload,
                loading: false
            }
        case CLEAR_CURRENT_TIME_SERIES_PREDICTION:
            return {
                ...state,
                getHistAndPredData: null,
                getNextSevedaysPredData: null,
                getCurrentNextMonthPredData: null
            }
        default:
            return state;
    }
}