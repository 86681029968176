import React from 'react';
import CsvDownloader from '../../Reusable_Components/CsvDownloader';

export default function UploadingFilesType({ data, name }) {
    return (
        <div>
            <div className="d-inline-block p-2 bd-highlight uploadingFile-Heading">Uploading Files Type : </div>
            {
                data.map((a,i) => (
                    <div className="d-inline-block p-2 bd-highlight" key={i}>
                        <CsvDownloader result={a} name={name[i]}/>
                    </div>
                ))
            }
        </div>
    )
}
