import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

class HeadwiseOutflow extends Component {

    componentDidMount() {
        this.headwiseOutflow(this.props.differentHeadwiseOutflow)
    }

    headwiseOutflow = data => {

        var  margin = {top: 20, right: 35, bottom: 20, left: 220},
        width = 1250- margin.left - margin.right,
        height = 400- margin.top - margin.bottom;

        var svg = d3.select("#headwiseOutflow").append("svg")
                    .attr("width", width + margin.left + margin.right)
                    .attr("height", height +margin.top + margin.bottom);
  
                    var g = svg.append("g")
                    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
            
        var x = d3.scaleLinear().range([0, width]);
        var y = d3.scaleBand().range([height, 0]);

       
        const render = data => {
            
                // format the data
                data.forEach(function(d) {
                    d.value =100*d["%-outflow"];
                    
                    console.log(data)
                });
        
            data.sort(function(a, b) { return a.value - b.value; });
        
            x.domain([0, d3.max(data, function(d) { return d.value; })]);
            y.domain(data.map(function(d) { return d.particulars; })).padding(0.1);

            g.append("g")
                .attr("class", "y axis")
                .call(d3.axisLeft(y));

            g.selectAll(".bar")
                .data(data)
                .enter().append("rect")
                .attr("class", "bar")
                .attr("x", 0)
                .attr("height", y.bandwidth())
                .attr("y", function(d) { return y(d.particulars); })
                .attr("width", function(d) { return x(d.value); });

               // var num = pi.toFixed(2); 
                g.selectAll(".text")
                .data(data)
                .enter()
                .append("text")
                .attr("class","label")
                .attr("y",function(d){return y(d.particulars)+y.bandwidth()/2+4})
                .attr("x",function(d){return x(d.value)+3})
                .text(function(d){ console.log(d.value); return [(d.value).toFixed(2)]+"%"})
                .attr("fill","black")

        }
        render(data);
    }

    componentWillUnmount(){
        d3.select("svg").remove();
    }

    render() {
        return (
            <div className="headwiseOutflow"  id="headwiseOutflow"></div>
        );
    }
}

HeadwiseOutflow.propTypes = {
    differentHeadwiseOutflow: PropTypes.array.isRequired
};

export default HeadwiseOutflow;