import React,{Component  } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as d3 from 'd3'
import $ from 'jquery'

class TreeChart extends Component{

    componentDidMount(){
        // console.log(this.props.treechart)
        this.treeChart(this.props.treechart)
    }

    handlechange = e => {
        const data = e.data;
        this.props.barupdate(data);
    }

    treeChart = (data) =>{

        var cwidth = $("#treechart").width();   
        var cheight = $("#treechart").height();
        // console.log("height:"+cheight + "width:"+cwidth)
        var margin = {top: 20, right: 15, bottom: 20, left: 0},
        width = cwidth - margin.left - margin.right,
        height = cheight - margin.top - margin.bottom;

      //  var tdata;

        var canvas1 = d3.select("#treechart").append("svg")
                    .attr("width", cwidth)
                    .attr("height",cheight);

        var canvas = canvas1.append("g")
                    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        var div = d3.select("body").append("div")	
                    .attr("class", "tooltip")				
                    .style("opacity", 0);
        // var data = {
        //     children:[{name: "sales"}]
        // }

       

        

        // console.log(root.leaves())

        const render = (da) =>{
            var data = {
                children:[{name: "sales",
                children: da}]
            }
            var root = d3.hierarchy(data).sum(function(d){ return d.totalsales}) // Here the size of each leave is given in the 'value' field in input data
            // console.log("root:"+root)
            d3.treemap()
                .size([width, height])
                .padding(2)(root)
            var color = d3.scaleOrdinal()
                        .range(d3.schemeCategory20);
            canvas.selectAll("rect")
            .data(root.leaves())
            .enter()
            .append("rect")
              .attr('x', function (d) { return d.x0; })
              .attr('y', function (d) { return d.y0; })
              .attr('width', function (d) { return d.x1 - d.x0; })
              .attr('height', function (d) { return d.y1 - d.y0; })
              .style("stroke", "black")
              .style("fill", function(d){return color(d.data.Item_name)})   
             .on("click", this.handlechange)
          
    
                .on('mouseover', function(d, n , i){
                          //  handleMouseOver(d, n, i);
                            div.transition()        
                                .duration(200)      
                                .style("opacity", .9);      
                            div.html("Item Name: " + d.data.Item_name + "<br/><br/>" + "Total Sales: " + d.data.totalsales   )   
                            //console.log( d.Item_name)
                                .style("left", (d3.event.pageX) + "px")     
                                .style("top", (d3.event.pageY - 28) + "px"); 
                        })
                        .on("mouseout", function(d, n, i){
                            //handleMouseOut(d, n, i);
                            div.transition()        
                                .duration(500)      
                                .style("opacity", 0); 
                        });
        
          // and to add the text labels
          canvas.selectAll("text")
            .data(root.leaves())
            .enter()
            .append("text")
              .attr("x", function(d){ return d.x0+5})    // +10 to adjust position (more right)
              .attr("y", function(d){ return d.y0+20})    // +20 to adjust position (lower)
              .text(function(d){ return d.data.Item_name })
              .attr("font-size", "10px")
              .attr("fill", "white")
    
            //   function up(d, i){
            //     // barupdate(d.data.Item_name)
            //     // barupdate1(d.data.Item_name)
            //     console.log(d.data)
    
            //   }
        }
        render(data)
    }

    componentWillUnmount(){
        d3.select("svg").remove();
    }

    render(){
        return(
            <div id="treechart"></div>
        )
    }
}

TreeChart.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    treechart: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
})

export default connect(mapStateToProps)(TreeChart)