import { ITEM_ANALYSIS_LOADING, GET_TOP_20ITEMS_BASED_ON_SALES, GET_ITEMWISE_KPI, GET_MONTHLY_SALES_OF_ITEM, 
    GET_MONTHLY_PROFIT_OF_ITEM, CLEAR_CURRENT_ITEM_ANALYSIS } from '../actions/types';

const initialState = {
    loading: false,
    top20ItemsBasedOnSales: null,
    getItemwiseKpi: null,
    getMontlySalesOfItem: null,
    getMontlyProfitOfItem: null
}

export default function(state = initialState, action) {
    switch(action.type) {
        case ITEM_ANALYSIS_LOADING:
            return {
                ...state,
                loading: true
            }
        case GET_TOP_20ITEMS_BASED_ON_SALES:
            return {
                ...state,
                top20ItemsBasedOnSales: action.payload,
                loading: false
            }
        case GET_ITEMWISE_KPI:
            return {
                ...state,
                getItemwiseKpi: Object.values(action.payload)[0],
                loading: false
            }
        case GET_MONTHLY_SALES_OF_ITEM:
            return {
                ...state,
                getMontlySalesOfItem : Object.values(action.payload)[0],
                loading: false
            }
        case GET_MONTHLY_PROFIT_OF_ITEM:
            return {
                ...state,
                getMontlyProfitOfItem : Object.values(action.payload)[0],
                loading: false
            }
        case CLEAR_CURRENT_ITEM_ANALYSIS:
            return {
                ...state,
                top20ItemsBasedOnSales: null,
                getItemwiseKpi: null,
                getMontlySalesOfItem: null,
                getMontlyProfitOfItem: null
            }
        default:
            return state;
    }
}