import axios from 'axios';
import { ANALYTICS_URL, ACCESS_TOKEN, CLIENT_ID, EXPENSE_LOADING, GET_DIFFERENT_HEADWISE_OUTFLOW, GET_MONTHLY_TOPK_EXPENSE, CLEAR_CURRENT_EXPENSE } from './types';
import { logoutUser } from './authActions';

/*--- Get Different Headwise Outflow ---*/
export const getDifferentHeadwiseOutflow = () => dispatch => {
    dispatch(getExpenseLoding());
    axios.get(`${ANALYTICS_URL}/cash_flow/deffent_headwise_outflow/${CLIENT_ID()}`, ACCESS_TOKEN())
        .then(res => {
            dispatch({
                type: GET_DIFFERENT_HEADWISE_OUTFLOW,
                payload: res.data
            })
        })
        .catch(err =>{
            dispatch({
                type: GET_DIFFERENT_HEADWISE_OUTFLOW,
                payload: {}
            })
            if(err.response && err.response.status === 401){
                dispatch(logoutUser());
            }
        })
}

/*--- Get Monthly Topk Expense ---*/
export const getMonthlyTopkExpense = () => dispatch => {
    dispatch(getExpenseLoding());
    axios.get(`${ANALYTICS_URL}/cash_flow/monthly_topk_expense/${CLIENT_ID()}`, ACCESS_TOKEN())
        .then(res => {
            dispatch({
                type: GET_MONTHLY_TOPK_EXPENSE,
                payload: res.data
            })
        })
        .catch(err =>{
            dispatch({
                type: GET_MONTHLY_TOPK_EXPENSE,
                payload: {}
            })
            if(err.response && err.response.status === 401){
                dispatch(logoutUser());
            }
        })
}

/*--- Loading Expense Data ---*/
export const getExpenseLoding = () => {
    return {
        type: EXPENSE_LOADING
    }
}

/*--- Clear Expense Data ---*/
export const clearCurrentExpense = () => {
    return {
        type: CLEAR_CURRENT_EXPENSE
    }
}