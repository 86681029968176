import { MARKET_BASKET_LOADING, GET_MARKET_BASKET_DATA, POST_MARKET_BASKET_DATA, CLEAR_CURRENT_MARKET_BASKET } from '../actions/types';

const initialState = {
    loading: false,
    getMarketBasket: null,
    postMarketBasket: null
}

export default function(state = initialState, action) {
    switch(action.type) {
        case MARKET_BASKET_LOADING:
            return {
                ...state,
                loading: true
            }
        case GET_MARKET_BASKET_DATA:
            return {
                ...state,
                getMarketBasket: action.payload,
                loading: false
            }
        case POST_MARKET_BASKET_DATA:
            return {
                ...state,
                postMarketBasket: action.payload,
                loading: false
            }
        case CLEAR_CURRENT_MARKET_BASKET:
            return {
                ...state,
                getMarketBasket: null,
                postMarketBasket: null
            }
        default:
            return state;
    }
}