import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RequestLoadingData from '../../Loading/RequestLoadingData';
import { getMonthlyCashBalanceForecast, getWeeklyHistPredData } from '../../../redux/actions/cashflowForecastActions';
import isEmpty from '../../../validation/is-empty';
import MonthlyCashBalanceForecast from './MonthlyCashBalanceForecast';
import WeeklyHistPredData from './WeeklyHistPredData';

class Forecast extends Component {

    state = {
        loading: false
    }

    componentDidMount() {
        this.props.getMonthlyCashBalanceForecast()
        this.props.getWeeklyHistPredData()
    }

    componentWillReceiveProps(nextProps){
        const { monthlyCashBalanceForecast, weeklyHistPredData } = nextProps.cashflowForecast;
        if(monthlyCashBalanceForecast !== null && weeklyHistPredData !== null) {
            this.setState({
                loading: true
            })
        }
    }
    
    render() {
        const { loading, monthlyCashBalanceForecast, weeklyHistPredData } = this.props.cashflowForecast;

        let cashflowForecast;
        if(monthlyCashBalanceForecast !== null && weeklyHistPredData !== null && this.state.loading) {
            if(isEmpty(monthlyCashBalanceForecast) == true || isEmpty(weeklyHistPredData) === true) {
                cashflowForecast = (
                    <div>
                        <p className="lead text-muted">Sorry</p>
                        <p>Something went wrong please try again after some time</p>
                    </div>
                )
            } else {
                console.log(monthlyCashBalanceForecast)
                cashflowForecast = (
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card cashflowForecast">
                                <div className="d-flex justify-content-center card-graph-Content">
                                    Monthly Cash Balance Forecast
                                </div>
                                <div className="card-graph">
                                    <MonthlyCashBalanceForecast monthlyCashBalanceForecast={monthlyCashBalanceForecast} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="card cashflowForecast">
                                <div className="d-flex justify-content-center card-graph-Content">
                                    Weekly Cash In Hand
                                </div>
                                <div className="card-graph">
                                    <WeeklyHistPredData weeklyHistPredData={weeklyHistPredData} />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        } else {
            cashflowForecast = <RequestLoadingData />
        }

        return (
            <div className="container-fluid">
                {cashflowForecast}
            </div>
        );
    }
}

Forecast.propTypes = {
    getMonthlyCashBalanceForecast: PropTypes.func.isRequired,
    getWeeklyHistPredData: PropTypes.func.isRequired,
    cashflowForecast: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    cashflowForecast: state.cashflowForecast
})

export default connect(mapStateToProps, { getMonthlyCashBalanceForecast, getWeeklyHistPredData })(Forecast);