import axios from 'axios';
import { ANALYTICS_URL, ACCESS_TOKEN, CLIENT_ID, REVENUE_LOADING, GET_CLIENTWISE_INFLOW, GET_MONTHLY_TOPK_REVENUE, CLEAR_CURRENT_REVENUE } from './types';
import { logoutUser } from './authActions';

/*--- Get Clientwise Inflow ---*/
export const getClientwiseInflow = () => dispatch => {
    dispatch(getRevenueLoding());
    axios.get(`${ANALYTICS_URL}/cash_flow/clientwise_inflow/${CLIENT_ID()}`, ACCESS_TOKEN())
        .then(res => {
            dispatch({
                type: GET_CLIENTWISE_INFLOW,
                payload: res.data
            })
        })
        .catch(err =>{
            dispatch({
                type: GET_CLIENTWISE_INFLOW,
                payload: {}
            })
            if(err.response && err.response.status === 401){
                dispatch(logoutUser());
            }
        })
}

/*--- Get Monthly Topk Revenue ---*/
export const getMonthlyTopkRevenue = () => dispatch => {
    dispatch(getRevenueLoding());
    axios.get(`${ANALYTICS_URL}/cash_flow/monthly_topk_revenue/${CLIENT_ID()}`, ACCESS_TOKEN())
        .then(res => {
            dispatch({
                type: GET_MONTHLY_TOPK_REVENUE,
                payload: res.data
            })
        })
        .catch(err =>{
            dispatch({
                type: GET_MONTHLY_TOPK_REVENUE,
                payload: {}
            })
            if(err.response && err.response.status === 401){
                dispatch(logoutUser());
            }
        })
}

/*--- Loading Revenue Data ---*/
export const getRevenueLoding = () => {
    return {
        type: REVENUE_LOADING
    }
}

/*--- Clear Revenue Data ---*/
export const clearCurrentRevenue = () => {
    return {
        type: CLEAR_CURRENT_REVENUE
    }
}