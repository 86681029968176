import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Zoom from 'react-reveal/Zoom';
import CountUp from 'react-countup';
import { logoutUser } from '../../../redux/actions/authActions';
import { basketKpiDetails } from '../../../redux/actions/insights360Actions';
import isEmpty from '../../../validation/is-empty';
import { notification } from 'antd';

class Kpi360 extends Component {

    state = {
        loading: false,
        cardTitleName: [],
        icons: ['fas fa-chart-line', 'fas fa-cart-plus', 'fas fa-anchor', 'far fa-gem'],
        cardTitleNumberStart: [0, 0, 0, 0],
        cardTitleNumberEnd: [],
        delay: [0, 100, 200, 300]
    }

    componentDidMount(){
        this.props.basketKpiDetails();
    }

    componentWillReceiveProps(nextProps){
        const { loading, basketKpi } = nextProps.insights360;
        if(basketKpi !== null) {
            if(isEmpty(basketKpi) === false) {
                // console.log(basketKpi)
                const result = Object.values(basketKpi)[0];
                // console.log(result)
                const key = Object.keys(result);
                // console.log(key)
                const value = Object.values(result);
                this.setState({
                    loading: true,
                    cardTitleName: [...key],
                    cardTitleNumberEnd: [...value]
                })
            } else {
                console.log('Data Absent')
            }
        }
        
        if(isEmpty(nextProps.errors) === false) {
            const { status } = nextProps.errors;
            const { statusText } = nextProps.errors;
            const { msg } = nextProps.errors.data;
            if(status === 401){
                notification.error({
                    message: statusText,
                    description: 'Session Timeout'
                });
                this.props.logoutUser();
            }
        }
    }

    Kpi360 = () => (
        this.state.cardTitleName.map((a, i) => (
            <Zoom key={i} delay={this.state.delay[i]}>
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                    <div className="card">
                        <div className="card-header">
                            <i className={this.state.icons[i]}></i>
                        </div>
                        <div className="card-body">
                            <p className="card-title">
                                {
                                    this.state.cardTitleName[i].split('_').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ') === 'Num Basket'?
                                        'Number of Basket'
                                    : this.state.cardTitleName[i].split('_').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ') === 'Avg Item Per Basket'?
                                        'Average Item Per Basket'
                                    : this.state.cardTitleName[i].split('_').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ') === 'Avg Profit Per Basket'?
                                        'Average Profit Per Basket'
                                    : this.state.cardTitleName[i].split('_').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ')
                                }
                            </p>
                            {
                                i===0 || i===3 ?
                                    <h3 className="card-title-number">
                                        ₹<CountUp 
                                            start={0} 
                                            end={this.state.cardTitleNumberEnd[i]}
                                        />
                                    </h3>
                                :
                                    <h3 className="card-title-number">
                                        <CountUp 
                                            start={0} 
                                            end={this.state.cardTitleNumberEnd[i]}
                                        />
                                    </h3>
                            }
                        </div>
                    </div>
                </div>
            </Zoom>
        ))
    )

    render() {
        return (
            <div className="container-fluid">
                <div className="row kpi-360">
                    {
                        this.state.loading ?
                            this.Kpi360()
                        :
                            null
                    }
                </div>
            </div>
        );
    }
}

Kpi360.propTypes = {
    basketKpiDetails: PropTypes.func.isRequired,
    logoutUser : PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    insights360: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    insights360: state.insights360
})

export default connect( mapStateToProps, { basketKpiDetails, logoutUser })(Kpi360);
