import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import RequestLoadingData from "../../../Loading/RequestLoadingData";
import {
  getClientwiseInflow,
  getMonthlyTopkRevenue,
} from "../../../../redux/actions/revenueActions";
import isEmpty from "../../../../validation/is-empty";
import ClientwiseInflow from "./ClientwiseInflow";
import MonthlyTopk from "../../../Reusable_Components/Cashflow_Expense_&_Revenue/MonthlyTopk";
import PieChart from "../../../Reusable_Components/Cashflow_Expense_&_Revenue/PieChart";
import * as d3 from "d3";

class Revenue extends Component {
  state = {
    monthlyTopkRevenue: [],
    date: "",
    piechart: null,
    chart: false,
  };

  componentDidMount() {
    this.props.getClientwiseInflow();
    this.props.getMonthlyTopkRevenue();
  }

  componentWillReceiveProps(nextProps) {
    const { monthlyTopkRevenue } = nextProps.revenue;
    if (monthlyTopkRevenue !== null) {
      console.log(monthlyTopkRevenue);
      this.setState(
        {
          monthlyTopkRevenue: monthlyTopkRevenue.data,
        },
        () => {
          this.initialStage();
        }
      );
    }
  }

  // initialStage = () => {
  //     const data = this.state.monthlyTopkRevenue;
  //     // console.log(data)
  //     var topSum=[];
  //     data.forEach(function(d,i){
  //         // console.log(Object.keys(d))
  //        var date= Object.keys(d)
  //     //    console.log( data[i][date])
  //        var sum=0;
  //        data[i][date].forEach(function(d){

  //             sum+=d.revenue

  //        })
  //        topSum.push({date:date[0],total:sum})
  //     })
  //     const date = topSum[0].date;
  //     var da = topSum[0];
  //     var parse = d3.timeParse("%b-%Y")
  //     da.date = parse(da.date)

  //     this.setState({
  //         date,
  //         piechart: da,
  //         chart: true
  //     })
  // }
  initialStage = () => {
    const data = this.state.monthlyTopkRevenue;

    // Check if data is defined and is an array with at least one element
    if (Array.isArray(data) && data.length > 0) {
      var topSum = [];

      data.forEach(function (d, i) {
        var date = Object.keys(d);
        var sum = 0;

        // Check if data[i][date] is defined
        if (data[i] && data[i][date]) {
          data[i][date].forEach(function (d) {
            sum += d.revenue; // Assuming 'revenue' is a valid property
          });
        } else {
          console.warn(
            `Data at index ${i} is undefined or missing for date: ${date}`
          );
        }

        topSum.push({ date: date[0], total: sum });
      });

      // Check if topSum is not empty
      if (topSum.length > 0) {
        const date = topSum[0].date;
        var da = topSum[0];
        var parse = d3.timeParse("%b-%Y");
        da.date = parse(da.date);

        this.setState({
          date,
          piechart: da,
          chart: true,
        });
      } else {
        console.warn("No valid data in topSum.");
      }
    } else {
      console.warn("monthlyTopkRevenue is either not an array or is empty.");
    }
  };

  update = (data) => {
    const d = data.date;
    const date = new Date(d);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    this.setState({
      date: month + " - " + year,
      chart: false,
      initial: false,
      piechart: data,
    });
    setTimeout(
      this.setState({
        chart: true,
      }),
      2000
    );
  };

  render() {
    const { loading, clientwiseInflow, monthlyTopkRevenue } =
      this.props.revenue;

    let revenue;
    if (clientwiseInflow !== null && monthlyTopkRevenue !== null) {
      if (
        isEmpty(clientwiseInflow) === true &&
        isEmpty(monthlyTopkRevenue) === true
      ) {
        revenue = (
          <div>
            <p className="lead text-muted">Sorry</p>
            <p>Something went wrong please try again after some time</p>
          </div>
        );
      } else {
        revenue = (
          <div className="row">
            <div className="col-md-12">
              <div className="card revenue">
                <div className="d-flex justify-content-center card-graph-Content">
                  Top 20 Revenue {`( ${clientwiseInflow.year} )`}
                </div>
                <div className="card-graph">
                  <ClientwiseInflow clientwiseInflow={clientwiseInflow.data} />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="card revenue">
                    <div className="text-center">
                      <div className="d-flex justify-content-center card-graph-Content">
                        Revenue Generated By Top 5 Clients Each Month
                      </div>
                      <div className="card-graph">
                        <MonthlyTopk
                          monthlyTopk={monthlyTopkRevenue}
                          update={this.update}
                          type="revenue"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  {this.state.chart ? (
                    <div className="card revenue">
                      <div className="text-center">
                        <div className="d-flex justify-content-center card-graph-Content">
                          Contribution Of Each Client {`( ${this.state.date} )`}
                        </div>
                        <div className="card-graph">
                          <PieChart
                            monthlyTopk={monthlyTopkRevenue}
                            update={this.state.piechart}
                            type="revenue"
                            path="client"
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else {
      revenue = <RequestLoadingData />;
    }
    return <div className="container-fluid">{revenue}</div>;
  }
}

Revenue.propTypes = {
  getClientwiseInflow: PropTypes.func.isRequired,
  getMonthlyTopkRevenue: PropTypes.func.isRequired,
  revenue: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  revenue: state.revenue,
});

export default connect(mapStateToProps, {
  getClientwiseInflow,
  getMonthlyTopkRevenue,
})(Revenue);
