import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as d3 from 'd3';
import { weeklySalesProfit } from '../../../redux/actions/insights360Actions';

class ScatterChart extends Component {

    componentDidMount() {
        this.scatterChart(this.props.scatterChart)
    }

    scatterChart = (data) => {
        // set the dimensions and margins of the graph
        var margin = {top: 32, right: 20, bottom: 55, left: 50},
            width = 600 - margin.left - margin.right,
            height = 360 - margin.top - margin.bottom;

        // append the svg object to the body of the page
        var svg = d3.select("#scatterChart")
        .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            // .style("background", "#DCDCDC")
        .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");


        // svg.append("text")
        //     .attr("x", width/2)
        //     .attr("y", -40)
        //     .text("Week Wise Performance")
        //     .attr("fill", "black")
        //     .attr("text-anchor", "middle")
        //     .attr("class", "bubble_title")


        var div = d3.select("body").append("div")   
                .attr("class", "tooltip")               
                .style("opacity", 0);




        var color = d3.scaleOrdinal()
                    .range(d3.schemeCategory20);
    
        const render = data => {

            //var sales = d3.extent()
           // var test = d3.extent(data.map(function(d){ return d.profit}))
            // console.log(test)
            var len = data.length

            // Add X axis
            var x = d3.scaleLinear()
                .domain([1, d3.max(data, function(d){ return d.week})])
                .range([ 0, width ]);

            svg.append("g")
                .attr("transform", "translate(0," + height + ")")
                .call(d3.axisBottom(x).ticks(len));

            // Add X axis label:
            svg.append("text")
                .attr("text-anchor", "end")
                .attr("x", width)
                .attr("y", height+50 )
                .text("Weeks");

            // Add Y axis
            var y = d3.scaleLinear()
                .domain([0, d3.max(data, function(d){ return d.sales})])
                .range([ height, 0]);

            svg.append("g")
                .call(d3.axisLeft(y));

            // Add Y axis label:
            svg.append("text")
                .attr("text-anchor", "end")
                .attr("x", -20)
                .attr("y", -2 )
                .text("Revenue")
                .attr("text-anchor", "start")

            // Add a scale for bubble size
            var z = d3.scaleSqrt()
                .domain(d3.extent(data.map(function(d){ return d.profit})))
                .range([2, 30]);

            // var tip = d3.tip()
            //             .html(d => {
            //                 return `<div>${d.profit}</div>`
            //             })

            // svg.call(tip)

                svg.append('g')
                    .selectAll("dot")
                    .data(data)
                    .enter()
                    .append("circle")
                   // .attr("class", function(d) { return "bubbles " + d.continent })
                    .attr("cx", function (d) { return x(d.week); } )
                    .attr("cy", function (d) { return y(d.sales); } )
                    .attr("r", function (d) { return z(d.profit); } )
                    .style("fill", function(d) { return color(d.week)})
                    .style("opacity", "0.5")
                    .style("stroke", "black")
                    .on('mouseover', function(d, n , i){
                        handleMouseOver(d, n, i);
                        div.transition()        
                            .duration(200)      
                            .style("opacity", .9);      
                        div.html("Week: " + d.week + "<br/><br/>"
                                         + "Total Sales: " + d.sales + "<br/><br/>"
                                         + "Total Profit: " + d.profit +  "<br/><br/>"
                                         + "Quantities Sold: " + d.Sale_quantity   )   
                            .style("left", (d3.event.pageX) + "px")     
                            .style("top", (d3.event.pageY - 28) + "px"); 
                    })
                    .on("mouseout", function(d, n, i){
                        handleMouseOut(d, n, i);
                        div.transition()        
                            .duration(500)      
                            .style("opacity", 0); 
                    });
   
            function handleMouseOver(d, i, n){
                d3.select(n[i]).raise()
                    .transition().duration(300)
                    .attr("opacity", "0")
                    

            }

            function handleMouseOut(d, i, n){
                d3.select(n[i])
                    .transition().duration(300)
                    .attr("opacity", "0.5")

            }
        }
        render(data)
    }

    componentWillUnmount(){
        d3.select("svg").remove();
    }

    render() {
        return (
            <div id="scatterChart"></div>
        );
    }
}

ScatterChart.propTypes = {
    weeklySalesProfit: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    insights360: PropTypes.object.isRequired,
    scatterChart: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    insights360: state.insights360
})

export default connect(mapStateToProps, { weeklySalesProfit })(ScatterChart);