import React from 'react'

export default function UploadedFiles({ name, file }) {
    return (
        <div>
            <div className="d-inline-block p-2 bd-highlight uploaded-file">Uploaded Files : </div>
            {
                name.map((a,i) => (
                    <div className="d-inline-block p-2 bd-highlight" key={i}>
                        {a} 
                        <span>
                            {
                                file[i] ?
                                    <i className="far fa-check-circle ml-1 text-success"></i>
                                :
                                    <i className="far fa-times-circle ml-1 text-danger"></i>
                            }
                        </span>
                    </div>
                ))
            }
        </div>
    )
}
