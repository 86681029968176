import React, { Component } from 'react';
import CountUp from 'react-countup';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import AnimationProgressBar from '../../../Reusable_Components/Circular_Animation_Progress/AnimationProgressBar';

class KPICashflowTimeSeries extends Component {

    state = {
        data : null,
        currentSales2018 : null,
        uptoDateSales2019 : null,
        currentSales2019 : null,
        lackingBy : null,
        leadingBy : null,
        uptoDateRoundedNumber : null
    }

    componentDidMount(){
        console.log(this.props.timeSeries)
        this.setState({
            data: this.props.timeSeries
        })
        this.kpiDataCalculation(this.props.timeSeries)
    }

    kpiDataCalculation = (d) => {
        const data = [d];
        var pdata = [];
       
        var key = Object.keys(data[0])
                    
        // console.log("key",key)

        for(var i=0;i<key.length;i++){
            var da = data[0][key[i]]["quarterly"]
            
            var s = d3.nest()
                .rollup(function(v) { return { total: d3.sum(v, function(d) { return d["revenue"]})}})
                .entries(da);
            

            pdata.push({"name": key[i], "value": s.total})
            // console.log(pdata[0])
            // console.log(pdata[1])
            this.setState({
                currentSales2018 : pdata[0],
                currentSales2019 : pdata[1]
            })
        }
        // console.log(pdata)

        var cur_year, pre_year;

        if(pdata[0].name<pdata[1].name){
            cur_year = pdata[1].name
            pre_year = pdata[0].name
            // console.log("current year: ", cur_year )
        }
        else{
            cur_year = pdata[0].name
            pre_year = pdata[1].name
            // console.log("current year: ", cur_year )
        }

        // console.log(data[0][pre_year])

        var parse1=d3.timeParse("%b")
        // var format=d3.timeFormat("%b")


        data[0][pre_year]["monthly"].forEach(function(d){
                
                d.month = parse1(d.month)
            
            })
            // console.log(data[0][pre_year]["monthly"])
            
        function sortByDateAscending(a, b) {
            // Dates will be cast to numbers automagically:
            return a.month - b.month;
        }
        data[0][pre_year]["monthly"]= data[0][pre_year]["monthly"].sort(sortByDateAscending);

        
        // console.log(data[0][pre_year]["monthly"])
        
        var num_month;
        num_month = data[0][cur_year]["monthly"].length
        // console.log(num_month)
        var sum = 0;

        for(let i=0;i<num_month;i++){
        
            sum  = sum + data[0][pre_year]["monthly"][i].monthly_revenue
        }
        // console.log("sum: ",sum)
        this.setState({
            uptoDateSales2019 : sum
        })

        var current_sale;
        var previous_sale;

        pdata.forEach(function(d){
            if(d.name === cur_year)
            {
                current_sale = d.value;
            }
            else if(d.name===pre_year)
            {
                previous_sale=d.value;
            }
        })

        // console.log("cur_sale:", current_sale)
        // console.log("pre_sale:", previous_sale)

        if(sum > current_sale){
            var per = ((sum-current_sale)/current_sale)*100
            var rounded_number = per.toFixed(2)
            // console.log(cur_year+" is lacking by "+ rounded_number + " %")
            this.setState({
                lackingBy : rounded_number
            })
        } else {
        var per1 = ((current_sale-sum)/current_sale)*100
        var rounded_number1= per1.toFixed(2)
            // console.log(cur_year+" is leading by "+ rounded_number1 + " %")
            this.setState({
                leadingBy : rounded_number1
            })
        }

        var previous_per=((sum/previous_sale)*100)
        var rounded_number2= previous_per.toFixed(2)
        // console.log(previous_per+" is leading by "+ rounded_number2 + " %")
        this.setState({
            uptoDateRoundedNumber: rounded_number2
        })
    }
    
    render() {
        let salesAvg = null;
        if(this.state.lackingBy !== null){
            salesAvg = this.state.lackingBy
            // console.log(this.state.lackingBy)
        } else if(this.state.leadingBy !== null) {
            salesAvg = this.state.leadingBy
            // console.log(this.state.leadingBy)
        }
        
        return (
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                <div className="row timeSeries-Kpicard">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="card timeSeries-Kpi">
                            <div className="card-header">
                                <i className="fas fa-hand-holding-usd"></i>
                            </div>
                            <div className="card-body">
                                <p className="card-title">Previous Year Sales</p>
                                <h3 className="card-title-number">
                                    <CountUp 
                                        start={0}
                                        end={this.state.currentSales2018 && this.state.currentSales2018.value} 
                                    />
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="card timeSeries-Kpi">
                            <div className="card-header">
                                
                            </div>
                            <div className="card-body">
                                <p className="card-title">Previous Year Sales (YTD)</p>
                                <h3 className="card-title-number">
                                    <CountUp
                                        start={0} 
                                        end={this.state.uptoDateSales2019}
                                    />
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row timeSerieslatest-KpiCard">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="card timeSerieslatest-Kpi">
                            <div className="card-header cvp_percentage">
                                <AnimationProgressBar 
                                    className="progress-SalesAvg-Circle" 
                                    percentage={salesAvg} 
                                    style={{
                                        pathColor: `rgba(62, 152, 199, ${(Math.round(salesAvg * 100) / 100) / 100})`,
                                        textColor: 'rgba(62, 152, 199)',
                                    }}
                                />
                            </div>
                            <div className="card-body">
                                <p className="card-title uptoDate">Current Year vs Previous Year</p>
                                {
                                    this.state.lackingBy !== null ?
                                        <div className="card-title-arrow">Current Year
                                            <i className="fas fa-arrow-circle-down"></i>
                                        </div>
                                    : this.state.leadingBy !== null ?
                                        <div className="card-title-arrow">Current Year
                                            <i className="fas fa-arrow-circle-up"></i>
                                        </div>
                                    :
                                        null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="card timeSerieslatest-Kpi">
                            <div className="card-header">
                                <i className="fas fa-hand-holding-usd"></i>
                            </div>
                            <div className="card-body">
                                <p className="card-title">Current Year Sales (YTD)</p>
                                <h3 className="card-title-number">
                                    <CountUp
                                        start={0}
                                        end={this.state.currentSales2018 && this.state.currentSales2019.value}
                                    />
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

KPICashflowTimeSeries.propTypes = {
    timeSeries : PropTypes.object.isRequired
}

export default KPICashflowTimeSeries;